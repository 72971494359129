.deactivate-card-bg{
    background-color: #F6F6F6 !important;
}
.activate-card-bg{
    background-color: #fff;
}

.project_card{
    border: 1px solid #CDD6DC;
    padding: 0.4rem;
    padding-top: 0.4rem !important;
    .card-div1{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .card-checkbox{
            // margin: 0 auto !important;
            margin-top: 0 !important;
        }
        .dots-wrapper{
            padding-top: 0.1rem;
        }
        .ant-checkbox-wrapper{
            display: flex;
            flex-direction: row;
            // align-items: center !important;
            justify-content: center !important;
            margin-top: 0rem !important;
            padding-top: 0rem !important;
            line-height: 0.5rem !important;
            
        }
        .ant-checkbox-inner{
            width: 15px !important;
            height: 15px !important;
            background: #ffffff !important;      
            border: 1px solid #A1ACBD !important;
            border-radius: 2px !important;
          }
          .ant-checkbox-input{
            margin: 0 auto !important;
        }
        .ant-checkbox{
            margin-bottom: 0.3rem;
        
        }
        .ant-checkbox-inner::after{
            left: 10% !important;
          }
          .ant-checkbox-checked{
              .ant-checkbox-inner{
                background:#008174 !important;
              }
              
          }
    }
    .card-div2{
        display: flex;
        flex-direction: row;
        padding-left: 1.5rem;
        margin-top: 0.5rem;
        .card1-chart-wrapper{
            width: 3.4rem;
            height: 3.4rem;
            border-radius: 50%;
            border: 1px solid rgb(143, 143, 143);
            .label-wrapper{
                position: absolute;
                margin-top:11px;
                margin-left: 12px;
                span{
                font-size:0.6rem !important;
                font-family: Calibri Regular;
                font-weight: bold !important;
                }
            }
            .chart-wrapper{
                width: 3.6rem;
                position: absolute;
                margin-top:-7px;
                margin-left:-2px;
            }
        }
        .card-project-detail{
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
            .project-name{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 22px/18px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
               font: normal normal bold 22px/18px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
            .project-id-wrapper{
                display: flex;
                flex-direction: row;
                margin-top: 0.5rem;
                .project-id{
                    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-calibri);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    color: var(--unnamed-color-000000);
                    text-align: left;
                    font: normal normal normal 16px/19px Calibri Regular;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                }
                .project-documentid-wrapper{
                    margin-left: 0.6rem;
                    .project-documentid{
                        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-calibri);
                        letter-spacing: var(--unnamed-character-spacing-0);
                        color: var(--unnamed-color-000000);
                        text-align: left;
                        font: normal normal normal 16px/19px Calibri Regular;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                    }
                }
                
            }
            a{
                text-decoration: none;
            }
        }
    }
    .card-div3{
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
        .live-status-dot{
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }

    }

    .setting-dropdown{
        button{
            padding: 0 !important;
            border: none;
        }
    }
    .activate-dot{
        background-color: #02ee02;
        border-radius: 50%;
        padding: 0px 7px;
        margin-right: 8px;
        margin-bottom: -2px;
    }
    .deactivate-dot{
        background-color: #808080;
        border-radius: 50%;
        padding: 0px 7px;
        margin-right: 8px;
        margin-bottom: -2px;
    }
}