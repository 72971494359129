.drawer-survey-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem;
    height: 100%;
    .dropdown-wrapper{
        width: 100%;
    }
    .drawer-survey-chart-wrapper{
        width: 100%;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;
        height: 100%;
    }
    .survey-rater-title{
        width: 100%;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/18px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
       // font: normal normal bold 16px/18px Calibri;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}