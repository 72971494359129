.user-filter-bar{
    .usertab_filter_button{
        display: none;
    }
    // .advane_filter_btn{
    //   border: 1px solid var(--unnamed-color-008174);
    //   background: #FFFFFF 0% 0% no-repeat padding-box;
    //   border: 1px solid #008174;
    //   border-radius: 4px;
    //   opacity: 1;  
    //   padding: 5px 10px;
    //   height: 40px !important;
    // }
    .advane_filter_btn{
      border: 1px solid var(--unnamed-color-008174);
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #008174;
      border-radius: 4px;
      opacity: 1;  
      padding: 10px 10px;
    }
    .ant-picker{
      border: 1px solid #d2d2d2 !important;
    }
    #grid_btn{
      border: none !important;
      background-color: #F6F9FB!important;
    }
    #grid_btn:focus{
      background-color: #DBF1EE !important;
    }
    #list_btn{
      border: none!important;
      background-color: #F6F9FB!important;
    }
    #list_btn:focus{
      background-color: #DBF1EE !important;
    }
}
@media only screen and (min-width: 320px) and (max-width: 767px)  { 
    
    .user-filter-bar{
        // padding: 0 1em;
        .ant-drawer-content-wrapper{
          width: 100% !important;
          height: 297px !important;
        }
        .desk-advance-filter, .desk-more-btn, .desk-reset-filter, .desk-date, .desk-mode, .desk-company{
            display: none !important;
            }
        .usertab_filter_button{
            display: block !important;
            padding-left: 0 !important;
        }
        .border-right-dotted{
            border: none !important;
          }
          #filter_btn{
            background-color: #008174 !important;
            color: #fff !important;
            padding: 5px 9px;
          }
          .ant-drawer .ant-drawer-content .ant-drawer-content-wrapper{
            width: 100%;
            height: 41%;
            margin-top: 18%;
            justify-content: space-between;
          }
          .ant-drawer-body{
            padding: 14px 20px;
            margin: 0 5px ;
            justify-content: space-evenly;
          }
          .date-input {
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          #dash-border{
            margin: 17px 0;
            padding: 0 8px;
            border-bottom: 2px dashed #696867;
            width: 100%;
          }
          .apply_btn{
            background-color: #008174 !important;
            color: #fff !important;
            padding: 5px 17px;
            border-radius: 4px;
            height: 40px !important;
            width: 96px !important;
          }
          .floating-label-mode{
            top: -22% !important;
            z-index: 1 !important;
            // width: 21% !important;
            left: 7% !important;
            padding-left: 2% !important;
          }
          .floating-label-date{
            left:7% !important;
          }
          .floating-label-company{
            width: 36% !important;
            top: -23%  !important;
            z-index: 1 !important;
            left: 7% !important;
            padding-left: 3% !important;
          }
          .floating-label-search{
            display: none !important;
          }  
          #filter_btn{
            height: 37px;
            padding: 5px 12px;
            border: none;
            border-radius: 4px;
            color: #fff;
            background-color: #008174;
          }
          .advane_filter_btn{
            border: 1px solid var(--unnamed-color-008174);
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #008174;
            border-radius: 4px;
            opacity: 1;  
            padding: 5px 10px;
          }
    }
}
  