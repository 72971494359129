.user-details{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    // width: 450px;
    
    .form-container{
       padding: 1.5rem;
    //    padding-top: 5px;
    }
    .user-details-row1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .profile-label{
            span{
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/30.81px Roboto;
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
               // font: normal normal bold 13px/31px Roboto;
                letter-spacing: 0px;
                color: #000000;
                text-transform: capitalize;
                opacity: 1;   
            }
        }
        .profile-desc{
            background-color:rgb(206, 206, 206);
            border-radius: 5px;
            padding:0.7rem;
            display:flex;
            flex-direction: row;
            justify-content: center;
            max-width: 50%;
            line-height: 1rem;
            span{
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/11px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
                //font: normal normal normal 10px/11px Calibri;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;   
            }
        }
        .profile-photo{
            position: relative;
            .profile-circle{
                width: 6rem;
                border : 1px solid black;
                border-radius : 50%;
                padding : 0.2rem;
                 img{
                    border-radius: 50% ;
                    width: 100%;
                 }
            }
            .input-form{
                background: white;
                border-radius: 50%;
                position: absolute;
                width: 2.2rem !important;
                height: 2.2rem !important;
                border: 1px solid black;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 52px;
                margin-top: -35px;
                .cam-circle{
                    width: 16px;
                    
                    img{
                        width: 100%;
                    }
                }
                .profile-input{
                    display: none;
                }
            }
        }
    }
    
.form-row{
    display: flex;
    flex-direction: row;    
}
.form-group{
    margin-top: 0.7rem;
}
.form-label{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    //font: normal normal bold 14px/17px Calibri;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.3rem !important;
    
}
.form-control{
    font-family: Calibri Regular;
    font-size: 1rem;
    color: rgb(172, 172, 172);
}
.form-select{
    font-family: Calibri Regular;
    font-size: 1rem;
    color: rgb(172, 172, 172);
}
.asterik{
    font-family: Calibri Regular;
    font-weight: 600;
    font-size: 0.8rem;
    color: red;  
}
}

