$primary-color : #008174;
.cursor-pointer{
  cursor: pointer;
}
.cursor-move{
  cursor: move;
}
.w-9{
  width: 9%;
}
.w-15{
  width: 15%;
}
.w-fit-content{
  width: fit-content;
}
.ant-cascader-menus{
  .ant-cascader-menu{
    display: block;
  }
}
// .project-detail-header{
  .templateModal{
    .templateName{
      border:  1px solid #CDD6DC;
      border-radius: 4px;
      background-color: #ffffff;
    }
    .templateName{
      padding: 4px 8px;
    }
    .cancelButton{
      border-radius: 4px;
      font-size: 14px;
    }
    .saveButton{
      border-radius: 4px;
    }
  }
// }
.company-name-wrapper{
  .card-div3 {
    position: absolute;
    bottom: 8%;
    right: 0;
    .live-status-dot {
      width: 10px;
      height: 10px;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
    }
  }
}
  // survey modal for mobile  
.survey-header-modal{
  .ant-modal-content{
    .ant-modal-body{
      .select_config{
        .ant-select-selector{
         height: 40px ;
        } 
      }
      .Divider{
        border-bottom: 1px solid #00000029;
      }
      .ant-select-selector{
        border-color: #707070 !important;
        border-radius: 4px;
      }
      .cancelBtn{
        border-color: #696867;
        border-radius: 3px;
        color: black;
      }
      .applyBtn{
        background-color: #008174;
        border-radius: 3px;
        border: none;
        color: #fff;
      }
    }
  }
}
.project-container{
  background: transparent !important;
  border: none !important;
  .mobile-header-name{
    display: none !important;
  }
  .servey-container{
    .survey-mobile-header{
      display: none;
    }
    .survey-desktop-header{
      display: block;
      .select_config{
        .ant-select-selector{
         height: 40px;
        } 
      }
    }
    .setting_img{
      height: 43px !important;
    }
    .more_img{
      border: 1px solid #696867;
      height: 43px;
      padding-left: -6px;
      padding-left: 4px;
      padding-right: 4px;
      width: 29px;
      border-radius: 3px;
    }
    .ql-desktop{
      display: block !important;
    }
    .ql-mobile{
      display: none !important;
    }
    .question{
      .form-check-label{
        width: 98%;
      }
    }
  }
  .ant-table-cell {
    background: transparent;
    padding: 5px 16px;
    &::before{
      display: none;
    }
    a {
      text-decoration: none;
    }
  }
  
  .ant-table{
    height: 100%;
    // overflow-x: scroll;
  }

  .ant-table-thead{
    th{
      border: none !important;
      color: #A1ACBD;
    }
  }

  .ant-table.ant-table-bordered > .ant-table-container{
    border: none;
  }

  // .btn{
    // padding: .375rem .75rem !important;
    // border: 1px solid #CCCCCC;
  // }

  .ant-btn{
    height: auto;
  }

  .goto-btn{
    color: white;
    border-radius: 4px;
  }

  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #1890ff !important;
  }
  .ant-checkbox {
    top: 0.3em;
  }

  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
    background: #EDECEC !important;
    border: none !important;
  }
  .ant-input {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    height: 12px !important;
    width: 100%;
    background-color: transparent !important;
    border-radius: 4px;
    padding-left: 15px !important;
    min-height: 2.5rem !important;
  }
    padding: 0 4em;
    padding-top: 25px;

    .comman-button{
      height: 40px;
      border: none;
      border-radius: 0.25rem;
      float: left;
      button{
        height: inherit;
      }
    }
    .projectMoreBtn{
      img{
        height: 20px !important;
        width: 20px !important;
      }
    }

    .btn-theme{
      background-color: #008174 !important;
      color: #fff;
      box-shadow: none;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border: 1px solid #838383;
      border-radius: 4px;
      color: #000000;
    }
    .ant-select-arrow{
      color: #000000;
    }
    .ant-picker-input{
      width: 100% !important;
    }

    .dropmenu-bg-primary{
      background-color: #008174;
      button{
        height: inherit;
        color: white !important;
      }
    }
    .anticon{
        vertical-align: 0;
    }

    #dropdown-basic{
        width: 100%;
        border: none !important;
        color: #ffffff !important;
    }

    
    .text-right-custom{
        text-align: right;
        text-align: -webkit-right;
    }

    .headerarea{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .header-area-left{
        display: flex;
        flex-direction: row;
        align-items: center;
        // width: 55%;
        gap: 10px;
        .header-title-wrapper{
          display: flex;
          flex-direction: column;
          // width: 45%;
          .text-uppercase{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-29) var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: left;
            font-family: Calibri Regular;
            font-size: 1.5rem;
            font-weight: 600;
            color: #000;
            letter-spacing: 0px;
            text-transform: uppercase;
          }
          .report-header-subtitle {
            display: flex;
            a{
              text-decoration: none !important;
            }
          }
          .header-links{
            .company-profile-header-link{
              // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
              letter-spacing: var(--unnamed-character-spacing-0);
              text-align: left;
              font: normal normal normal 14px/17px Calibri Regular;
              letter-spacing: 0px;
              color: #0066FF;
              text-decoration: none !important;
              font-size: 1rem !important;
            }
          }
        }
        .company-name-wrapper{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          // width: 32%;
          padding-left: 1rem;
          padding-right: 1rem;
          // border-left: 1px solid #dadada;
          border-right: 1px solid #dadada;
          .company-logo{
            height: 50px;
            width: 50px;
            border-radius: 50px;
            object-fit: contain;
        }
          .company-name{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/18px var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: left;
           font: normal normal bold 16px/18px Calibri Regular;
            letter-spacing: 0px;
            color: #000000;
          }
        }
        .company-emp-wrapper{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          // width: 11%;
          padding-left: 1rem;
          padding-right: 1rem;
          border-right: 1px solid #dadada;
          .emp-img{
            width: 1.1rem;
          }
          .emp-num{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: left;
            font: normal normal normal 16px/19px Calibri Regular;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
        .company-id-wrapper{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          // width: 18%;
          padding-left: 1rem;
          padding-right: 1rem;
          border-right: 1px solid #dadada;
          .company-id{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: left;
           font: normal normal normal 16px/19px Calibri Regular;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
        .company-user-wrapper{
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          // width: 22%;
          padding-left: 1rem;
          padding-right: 1rem;
          // border-left: 1px solid #dadada;
          .user-img{
            height: 35px;
            width: 35px;
            border-radius: 50px;
        }
        .company-user-name{
            // margin-top: 0.4em;
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: left;
           font: normal normal normal 16px/19px Calibri Regular;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
        }
        }
      }
      .header-area-right{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // width: 14%;
        .rater-allproject-dropdown{
          background-color: transparent !important;
          // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-000000);
          text-align: left;
          font: normal normal bold 14px/17px Calibri Regular;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          text-transform: none;
          margin-right: 0.7rem;
          .company-arrow_down{
            width: 0.6rem !important;
            margin-left: 0.7rem;
          }
        }
        
        .header-btn{
          background: var(--unnamed-color-008174) 0% 0% no-repeat padding-box;
          background: #008174 0% 0% no-repeat padding-box;
          border-radius: 3px;
          .header-btn-text{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            text-align: left;
            font: normal normal bold 14px/17px Calibri Regular;
            letter-spacing: 0px;
            color: #FFFFFF;
          }
        }
      }
    }
    

    .bg-gray-color{
        background-color: #F0F3F5;
        .ant-switch-checked {
          background-color: #63ABA4;
        }
        .ant-switch-handle::before {
          background-color: #008174;
        }
    }

    .project-form{
        .date-picker-and-design{
            border: 1px solid #838383;
            border-radius: 5px;
            padding: 0.375rem 0.75rem;

            .anticon-calendar{
                color: #000000;
            }
        }
        // .ant-switch{
        //     height: 8px;
        //     .ant-switch-handle{
        //         top: -5px;
        //     }
        // }
    }


.user-filter-bar{

  .font-smaller{
    font-size: smaller;
    position: absolute;
    background: white;
  }

  .border-right-dotted{
      border-right: 2px dashed #A5B0C0;
  } 

  .floating-label-search{
    top: -25%;
    z-index: 1;
    // width: 17%;
    left: 3%;
    // padding-left: 3%;

    width: auto;
    padding-left: 6px;
    padding-right: 6px;
  }

  .floating-label-company{
    top: -25%;
    z-index: 1;
    // width: 24%;
    left: 8%;
    // padding-left: 1%;

    width: auto;
    padding-left: 6px;
    padding-right: 6px;
  }

  .floating-label-mode{
    top: -25%;
    z-index: 1;
    // width: 17%;
    left: 7%;
    // padding-left: 2%;

    width: auto;
    padding-left: 6px;
    padding-right: 6px;
  }

  .floating-label-date{
      top: -25%;
      z-index: 1;
      // width: 21%;
      left: 8%;
      // padding-left: 1%;

      width: auto;
      padding-left: 6px;
      padding-right: 6px;
  }

  .margin-left-2per{
      margin-left: 2%;
  }

  ul{
      display: block;
  }


  .ant-btn-icon-only{
    height: 40px !important;
    width: 40px !important;
    color: #A5B0C0;
  }
  .page-label,.ant-select-selector,.ant-picker{
    height: 40px !important;
    border-radius: 4px;
  }

  .ant-btn:hover, .ant-btn:focus {
    color: $primary-color;
    border-color: $primary-color;
    background: #DBF1EE;
  }
  .ant-input,.ant-select{
    line-height: 2;
  }
  .ant-select-selector{
    padding: 6px 11px !important;
    border: 1px solid #d2d2d2 !important;  
  }
  .goto-field{
    width: 22%;
  }
  input[type='text'], input[type='email'], input[type='password'] {
      border: 1px solid #d2d2d2 !important;
      outline: none;
  }
}
  
.user_card{
  border-radius: 7px;
    .gravatar{
      height: 25px;
      width: 25px;
      border-radius: 50px;
    }
    .live-status-circle{
      margin-top: 2%;
      height: 13px;
      width: 13px;
      border-radius: 50px;
      background-color: chartreuse;
    }
    .live-deactive-circle{
      margin-top: 2%;
      height: 13px;
      width: 13px;
      border-radius: 50px;
      background-color: #808080;
    }
}

.framework-container{
  .focus-area-icon{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-left: 10%;
    border: 1px solid #707070;
    padding: 6px;
  }
  .vertical-middle{
    display: flex;
    align-items: center;
  }
  .focus-area-data{
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-stretch;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
  }
  .focus-area-data-shadow{
    box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 2px 0px, rgba(0, 0, 0, 0.10) 0px 0px 1px 0px;
  }
  .rater-section{
    .dropdown-toggle::after {
      display: none;
    }
  }

  .bg-white-back button{
    background-color: #ffffff !important;
    padding: 2px 5px;
  }

  .ellipse-menu .ellipse-img {
    width: 1.5rem !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid #707070 !important;
    border-radius: 3px;
    opacity: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
  .raters-table{
    display: table;
    td{
      vertical-align: middle;
    }
  }
}

.rater-user-img{
  height: 25px;
  width: 25px;
  border-radius: 50px;
}
.rater-model-panel .ant-collapse-content-box{
  height: 300px;
  overflow: auto;
}

.rater-model-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: transparent;
  border: 1px solid rgb(187, 187, 187) !important;
  border-radius: 7px !important;
}

.data-analysis-container{
  .data-analysis-filter-container{
    #dash-border{
      margin: 17px 0;
      padding: 0 50%;
      border-bottom: 2px dashed #696867;
      width: 100% !important;
    }
    .data_filter_btn{
      display: none;
    }
    .floating-label-search{
      position: absolute;
      top: -25%;
      z-index: 1;
      width: 17%;
      left: 8%;
      padding-left: 1%;
      background-color: #ffffff
    }
  
    .floating-label-company{
      position: absolute;
      background-color: #ffffff;
      top: -25%;
      z-index: 1;
      width: 33%;
      left: 9%;
      padding-left: 2%;
    }
  
    .floating-label-mode{
      position: absolute;
      background-color: #ffffff;
      top: -23%;
      z-index: 1;
      width: 29%;
      left: 12%;
      padding-left: 1%;
    }
  
    .floating-label-date{
      position: absolute;
      background-color: #ffffff;
      top: -26%;
      z-index: 1;
      width: 38%;
      left: 11%;
      padding-left: 1%;
    }
    .ant-pagination{
      display: block;
    }
    .ant-btn-icon-only{
      height: 40px !important;
      width: 40px !important;
      color: #A5B0C0;
    }
    .page-label,.ant-select-selector,.ant-picker{
      height: 40px !important;
      border-radius: 4px ;
    }
  
    .ant-btn:hover, .ant-btn:focus {
      color: $primary-color;
      border-color: $primary-color;
      background: #DBF1EE;
    }
    .ant-input,.ant-select{
      line-height: 2;
    }
    .ant-select-selector{
      padding: 6px 11px !important;
    }
    .goto-field{
      width: 22%;
    }
    input[type='text'], input[type='email'], input[type='password'] {
        border: 1px solid #d9d9d9;
        outline: none;
        height: 40px;
    }
    .border-right-dotted{
      border-right: 2px dashed #A5B0C0;
    } 
  }

  .response-count{
    color: rgba(18, 18, 214, 0.637);
    cursor: pointer;
  }
  .anticon-more{
    font-size: 2rem;
    color: #A1ACBD;
  }
  th{
    font-weight: bold !important;
    color: #B5BECB !important;
  }
}

// .distribution-container{
//   .floating-label-search{
//     position: absolute;
//     top: -25%;
//     z-index: 1;
//     width: 14%;
//     left: 4%;
//     padding-left: 1%;
//     background-color: #ffffff;
//   }

//   .floating-label-mode{
//     position: absolute;
//     background-color: #ffffff;
//     top: -25%;
//     z-index: 1;
//     width: 22%;
//     left: 8%;
//     padding-left: 1%;
//   }

//   .floating-label-date{
//     position: absolute;
//     background-color: #ffffff;
//     top: -25%;
//     z-index: 1;
//     width: 28%;
//     left: 8%;
//     padding-left: 1%;
//   }
//   .ant-btn-icon-only{
//     height: 33px !important;
//     width: 40px !important;
//     color: #A5B0C0;
//   }
//   .goto-field{
//     width: 22%;
//   }
//   .more-outlined{
//     .dropdown-toggle::after {
//       display: none;
//     }
//     .anticon-more{
//       font-size: 2rem;
//       color: #A5AFC0;
//     }
//     button{
//       padding: 0;
//       border: none !important;
//     }
//   }
//   .distribution-filter-container{
//     input[type='text'], .ant-select-selector {
//       // border: 1px solid #d9d9d9;
//       outline: none;
//       height: 40px;
//     }
//     .ant-select-selector{
//       padding-top: 2%;
//       border: 1px solid #d9d9d9;
//     }
//     .ant-picker,button{
//       height: 40px !important;
//       border-radius: 4px;
//     }

//   }

// }

}

@media (max-width:480px)  { 
  .project-container{
    padding-right: 1em;
    padding-left: 1em;
    padding-top: 25px;
  }

  .project-form{
    margin-top: 5%;
  }
  .headerarea{
    text-align: center;
  }
  .button-collections{
    justify-content: center !important;
  }
  
  .border-right-dyns::after,.border-right-dyn{
    border-right: none !important;
    margin-left: 0 !important;
  }
  .ant-col.ms-3{
    margin-left: 0 !important;
  }
 }
 
 
 @media (max-width:961px)  {
  .project-form{
    margin-top: 5%;
  }
 }

.servey-container{
  .move-portion{
    width: 2px;
    height: 18px;
    margin: 1px;
    background: #b8b8b8 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    align-self: center;
  }
  .choices-counter, .scale-counter{
    button{
      height: 25px;
      padding: 0px 7px;
    }
    .ant-input{
      width: 15% !important;
      margin: 0 2%;
    }
  }
  .ant-btn{
    border-color: #d9d9d9 !important;
  }
  .w-3{
    width: 3%;
  }
  .block-containers{
    .block{
      background-color: #FBFBFB;
      border: 1px solid #F5F5F5;
      border-radius: 15px;
     .block-title{
        background: #949494;
        padding: 7px;
        padding-right: 4%;
        border-radius: 7px;
        text-transform: capitalize;
     }
     .comman-button{
       button::after{
           display: none;
       }
       button{
         padding: 0;
         border: none !important;
       }
       button:focus{
         box-shadow: none;
       }
     }
     .block-button{
      align-items: baseline;
      justify-content: end;
     }
     .dropdown-menu{
      min-width: 8rem;
      font-size: 0.9rem;
     }
     .accordion-toggle{
      border: none;
      background: transparent;
      cursor: pointer;
     }

     .input-elements{
      .questions-container{
          .question{
            border: 1px solid #e5e5e5;
            background-color: #FFFFFF;
            border-radius: 5px;
            position: relative;
            .action-icon{
              font-size: 15px;
              position: absolute;
              right: 13px;
              top: 0;
              height: 100%;
              .anticon-close-circle{
                color: #FF0000;
                background-color: #FFFFFF;
              }
              .action-dropdown {
                height: auto;
                border: 1px solid #b9b9b9;
                border-radius: 67.25rem;
                background-color: #FFFFFF;
                position: absolute;
                top: 0;
                .btn-primary{
                  border: none !important;
                }
                .btn{
                  padding: 1px 5px !important;
                }
                .dropdown-toggle::after{
                  display: none;
                }
              }
              .action-remove{
                height: 26px;
                width: 26px;
                border: 1px solid #b9b9b9;
                border-radius: 67.25rem;
                background-color: #FFFFFF;
                text-align: center;
                color: #FF0000;
                position: absolute;
                bottom: 0;
              }
            }
            .matrix-table{
              input[type='text']{
                text-align: center;
              }
              .form-check .form-check-input{
                float: none;
              }
            }
            .question-bg-gray{
              background-color: #F8F8F8;
              .non-editable-label{
                background-color: #F8F8F8;
              }
            }
            .form-check-input{
              margin-top: 0.8em;
            }

          }
          .question-label{
            background: #589681;
            color: #FFFFFF;
            padding: 7px;
            padding-right: 2%;
            border-radius: 0px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            text-transform: uppercase;
            width: 14%;
            .question-prefix{
              padding: 1px 10px;
              background: #FFFFFF;
              margin-right: 12%;
              border-color: #dbdbdb;
              box-shadow: 0 0 0 2px #cbcbcb61;
              border-radius: 2px;
            }
          }

          .key-cofus-area, .constrcuct{
            // background: #FCB737;
            color: #FFFFFF;
            padding: 7px 1%;
            border-radius: 0px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            text-transform: capitalize;
            width: auto;
            text-align: center;
          }
          .editable-label{
            padding: 4px 11px;
            line-height: 1.5715;
            background-color: #F8F8F8;
            background-image: none;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
          }
          .non-editable-label{
            padding: 4px 11px;
            background-color: #FFFFFF;
            background-image: none;
            color: #000000;
            border: none;
            border-radius: 2px;
            cursor: pointer;
          }
          .ant-input-disabled{
              color: #000000;
              background-color: #F8F8F8;
              box-shadow: none;
              cursor: pointer;
              opacity: 1;
          }
          .non-editable-label::placeholder {
            color: #000000;
            opacity: 1; /* Firefox */
          }
          
          .non-editable-label:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #000000;
          }
          
          .non-editable-label::-ms-input-placeholder { /* Microsoft Edge */
          color: #000000;
          }
  
      }
      .add-question-button{
      // width: 15%;
      // padding: 7px;
      // background-color: #ffffff;
      text-align: end;
      }
      .move-portion{
        width: 2px;
        height: 18px;
        margin: 1px;
        background: #F1F1F1 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
        align-self: center;
      }
      .width-fill-full{
        width: -webkit-stretch;
      }
      .mw-99{
        max-width: 99%;
      }
    }
   }

   .selected-block{
      border: 1px solid #63ABA4;
    }

  }

  .ant-drawer-mask{
    background-color: transparent;
  }
  .servey-preview{
    border-radius: 15px;
  }
  .ant-drawer-wrapper-body{
    padding: 2%;
  }

}

.site-drawer-render-in-current-wrapper {
  position: relative;
  // height: 100%;
  margin-bottom: 1rem;
  // overflow-y: scroll;
  // padding: 48px;
  // overflow: hidden;
  // text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}
.info_button{
  display: none;
}
.header-add-btn, .header-more-btn, .header-add-btns{
 display: none !important;
}
.mobile-hr{
  display: none !important;
}

//mobile style
@media only screen and (min-width: 320px) and (max-width: 767px)  {

  .distribution-filter-container{
    .floating-label-search, .floating-label-mode{
      display: none;
    } 

  }
  .project-container{

    .custom-divider{
      display: none;
    }
    .mobile-header-name{
      display: block !important;
      color: #000000 !important;
      // width: 45%;
      font-size: 20pt !important;
      margin-right: 4px;
    }
    .report-header-subtitle{
      margin-bottom: 2%;
    }
  }

  .company-filter-bar{
    .ant-drawer-content-wrapper{
      width: 100% !important;
      height: 297px !important;
    }
  }
  
  .data_filter_btn{
    display: block !important;
    #data_filter_btn{
      background-color: #008174 ;
      color: #fff;
      border: none;
      border-radius: 3px;
      padding: 8px 20px;
    }
  }
  .apply_btn{
    background-color: #008174 !important;
    color: #fff !important;
    padding: 5px 17px;
    border-radius: 4px;
    height: 40px !important;
    width: 96px !important;
  }
  
  .mobile-filterbar-drawer{
    .ant-drawer-content-wrapper{
      width: 100% !important;
      height: 292px !important;
    }
    .floating-label-company{
      width: 39% !important;
    }
    .floating-label-date, .floating-label-mode{
      display: block !important;
    }
    .floating-label-date{
      width: 32% !important;
    }
  }
  
  #search_input{
    border-color: #D2D2D2 !important;
  }
    .desk-data{
        display: none !important;
    }
    .mobile-hr{
      display: block;
    }
    #info_button {
      display: block;
      padding: 0 9px !important;
      border-color: #696867 !important;
      color: #001737 !important;
      // margin-left: 10% !important;
      // margin-top: -30px !important;
      margin-bottom: 5px !important;
      border-radius: 4px;
      font-weight: bold;
      // width: 25% !important;
    }
    .header-area-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
      flex-basis: 100%;
    }
    .info_button{
      display: block;
    }
    .header-title-wrapper{
      text-align: left !important;
      flex-basis: 100%;
    }
    .header-name{
      // width: 67% !important;
      display: none;
    }

    .mobile-dropdowns{
      justify-content: space-between;
    }
    .header-add-btns{
      background-color: #ffffff;
      color:black;
      border: 1px solid #696867;
      border-radius: 3px !important;
      display: block !important;
    }
    .header-add-btn{
      // width: 26% !important;
      // margin-left: auto;
      display: block !important;
      background-color: #008174 !important;
      border-radius: 3px !important;
      border:none !important;
      color: #fff !important;
    }
    .header-more-btn{
      display: block !important;
      background-color: #ffffff !important;
      border: 1px solid #D2D2D2 !important;
      border-radius: 4px !important;
    }
    // .btn{
      // border:none;
    // }
    .dropdown-arrow{
      width: 17px !important;
      margin-left: 4% !important;
    }
    .report-header-subtitle{
      width: 100% !important;
      justify-content: flex-start !important;
    }
    .mobile-heading{
      display: flex !important;
      // justify-content: space-between !important;
      flex-basis: 100% ;
    }
    // .floating-label-search, .floating-label-mode, .floating-label-date{
      // display: none !important;
    // }
    .search_input, .goto-field{
      border: 1px solid #D2D2D2 !important;
    }

    .servey-container{
      .block{
        .input-elements{
          .questions-container{
            .ql-desktop{
              display: none !important;
            }
            .ql-mobile{
              display: block !important;
            }
          }
        }
      }
      .survey-desktop-header{
        display: none !important;
      }
      .survey-mobile-header{
        display: block !important ;
        .mobile_survey_btn{
          background-color: #008174;
          border-radius: 3px;
          color: #fff;
        }
      }
      
      .block-containers{
        .p-2{
          padding: 0 !important;
        }
        .block-no-0{
          .input-elements{
            .p-3{
              padding: 0 ;
            }
          }
        }
        
        .block{
          .input-elements{
            .p-3{
              padding: 0 !important;
            }
            .questions-container{
              .question-label, .key-cofus-area, .constrcuct{
                width: -webkit-stretch;
              }
              .question{
                .form-check-label{
                  width: 98%;
                }
                .action-icon{
                  .action-dropdown{
                    top: -12px;
                  }
                  .action-remove{
                    bottom: -10px;
                  }
                } 
              }
              
              .ant-checkbox-wrapper{
                font-size: 12px;
              }
              .page-break-text{
                width: 10%;
              }
              .key-cofus-area{
                padding: 7px 3%;
              }
            }
          }
        }
      }
    }//servey container style over
    
  }




// ipad style
@media (min-width: 768px) and (max-width: 1023px)  { 
  .project-container{
    padding: 0 2em !important;
    .headerarea{
      margin-top: 6rem;
      
      .header-area-left{
        .desk-data{
          display: none !important;
        }
        .custom-divider{
          display: none;
        }
        .header-title-wrapper{
          .mobile-heading{
            display: flex;
          }
        }
      }
    }
  }
  .servey-container{
    .survey-desktop-header{
      display: none !important;
    }
    .survey-mobile-header{
      display: block !important;
      .mobile_survey_btn{
        background-color: #008174;
        border-radius: 3px;
        color: #fff;
      }
    }
    .block-containers{
      .p-2{
        padding: 0 !important;
      }
      .block-no-0{
        .input-elements{
          .p-3{
            padding: 0 ;
          }
        }
      }
      
      .block{
        .input-elements{
          .questions-container{
            .ql-desktop{
              display: block !important;
            }
            .ql-mobile{
              display: none !important;
            }
          
            .question{
              .form-check-label{
                width: 98%;
              }
              .action-icon{
                .action-dropdown{
                  top: -12px;
                }
                .action-remove{
                  bottom: -10px;
                }
              } 
            }
            .question-label{
              width: 23%;
            }
            .ant-checkbox-wrapper{
              font-size: 12px;
            }
            .page-break-text{
              width: 13%;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1364px)  { 
  .project-container{
    padding: 0 2em;
  }

  .servey-container{
    // .survey-desktop-header{
    //   display: none !important;
    // }
    // .survey-mobile-header{
    //   display: block !important;
    //   .mobile_survey_btn{
    //     background-color: #008174;
    //     border-radius: 3px;
    //     color: #fff;
    //   }
    // }
    .block-containers{
      .block{
        .input-elements{
          .questions-container{
            .ql-desktop{
              display: block !important;
            }
            .ql-mobile{
              display: none !important;
            }
            .question{
              .form-check-label{
                width: 98%;
              }
              .action-icon{
                .action-dropdown{
                  top: -14px;
                }
                .action-remove{
                  bottom: -7px;
                }
              } 
            }
            .question-label{
              width: 17%;
            }
            .ant-checkbox-wrapper{
              font-size: 12px;
            }
            .page-break-text{
              width: 10%;
            }
          }
        }
      }
    }
  }

  .servey-container{
    .block-containers{
      .p-2{
        padding: 0 !important;
      }
      .block-no-0{
        .input-elements{
          .p-3{
            padding: 0 ;
          }
        }
      }
      
      .block{
        .input-elements{
          .questions-container{
            .question{
              .form-check-label{
                width: 98%;
              }
              .action-icon{
                .action-dropdown{
                  top: -12px;
                }
                .action-remove{
                  bottom: -10px;
                }
              } 
            }
            .question-label{
              width: 17%;
            }
            .ant-checkbox-wrapper{
              font-size: 12px;
              margin-top: 8px;
            }
            .page-break-text{
              width: 10%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1365px) and (max-width: 1919px){

  .project-container{
    
    .user-filter-bar{
      .floating-label-search{
        top: -25%;
        z-index: 1;
        width: 20%;
        left: 7%;
        padding-left: 1%;
      }
    
      .floating-label-company{
          top: -25%;
          z-index: 1;
          width:32%;
          left: 11%;
          padding-left: 1%;
      }
    
      .floating-label-mode{
          top: -25%;
          z-index: 1;
          width: 21%;
          left: 10%;
          padding-left: 1%;
      }
    
      .floating-label-date{
          top: -25%;
          z-index: 1;
          width: 31%;
          left: 12%;
          padding-left: 1%;
      }
    }
  }
}
