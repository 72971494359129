.added-raters {
    padding: 0 1em 0 1em;
    width: 100%;
    .dropdown-toggle::after{
        content: " " !important;
        display: none !important;
    }
    .raters-inner-2{
        display: flex;
        flex-direction: row;
        // justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        // .m-col-2{
        //     width:12% !important;
        // }
        // .m-col-1{
        //     width:15% !important;
        // }
        .m-text-head{
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/14px var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: left;
            //font: normal normal bold 12px/14px Calibri;
            letter-spacing: 0px;
            color: #000000;
        }
      } 
  }


.raters-list-middle-wrapper{
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
  height: 70vh;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsl(0, 0%, 88%);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #008174;
  border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(#008174,0.5);
}