.user-detail-charts-in-popup .dot1 {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.user-detail-charts-in-popup .dot2 {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.user-detail-charts-in-popup .mid-text {
  font-weight: 900;
  font-size: 2rem;
}

.user-detail-charts-in-popup .pie {
  position: relative;
}

.user-detail-charts-in-popup .percent-r,
.user-detail-charts-in-popup .percent-e {
  /* position: absolute;
  top: 40%;
  left: 40% !important;
  font-size: 2rem;
  font-weight: 900; */
  position: absolute;
  text-align: center;
  left: 0 !important;
  right: 0;
  top: 41%;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.user-detail-charts-in-popup #dropdown-basic-button {
  width: 100%;
}

.user-detail-charts-in-popup .floating-label {
  position: absolute;
  background-color: #ffffff;
  top: -40%;
  z-index: 1;
  width: 32%;
  left: 5%;
  padding-left: 1%;
}

.user-detail-charts-in-popup .floating-label-rater {
  position: absolute;
  background-color: #ffffff;
  top: -40%;
  z-index: 1;
  /* width: 22%; */
  left: 5%;
  padding-left: 1%;
}

.user-detail-charts-in-popup .ant-select-selector {
  border-radius: 6px !important;
  color: black;
}
.user-detail-charts-in-popup .ant-select-arrow {
  color: black;
}
