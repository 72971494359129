.dot1 {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.dot2 {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.mid-text {
  font-weight: 900;
  font-size: 2rem;
}

.pie {
  position: relative;
}

.percent-r,
.percent-e {
  position: absolute;
  left: 0;
  right: 0;
  top: 45%;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  /* top: 45%;
  left: 44% !important; */
  font-size: 2rem;
  font-weight: 900;
}
