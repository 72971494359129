.header{
  .dropdown-item:focus{
    background-color: #008174;
    color: #fff !important;
  }
  .dropdown-item:active{
    background-color: #008174 !important;
    color: #fff !important;
  }
  
}
.ant-picker-cell-range-hover::before{
  color: #fff;
  background: #fff !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #008174;
}
.header-left {
    display:flex;
    flex-direction: column;
    .header-title{
        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-29) var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
        font: normal normal bold 24px/29px Calibri Regular;
        letter-spacing: 0px;
        color: #000000;
    }
    .header-subtitle{
        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-22) var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-696867);
        text-align: left;
        font: normal normal normal 18px/22px Calibri Regular;
        letter-spacing: 0px;
        color: #696867;
        opacity: 1;
    }
  }
  .header-Container {
    width: 100%;
    // margin: 0 auto !important;
    // display:flex;
    // flex-direction: row;
    // justify-content: space-between;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
  
  .header__hr{
    margin: 0 4rem;
  }
  .header {
    width: 93%;
    justify-content: space-between;
    margin: 1rem 4em; 
    // flex-wrap: wrap;
    .btn-primary {
      background-color: transparent !important;
      color: #001737;
      border: 1px solid #696867 !important;
    }
    .date-picker {
      border: 1px solid #696867 !important;
      padding: 0;
    }
    .create_new_btn{
      background-color:#008174 !important;
      border-radius: 3px;
      color: #FFFFFF;
    }
    .ant-picker{
      height: 38px;
      border: 1px solid #696867 !important;
      border-radius: 3px;
      color: #001737;
    }
    .dropmenu {
      background-color: #008174 !important;
      color: white !important;
    }
    
    .hr {
      border-bottom: 1px solid #dfdfdf;
      // margin-left: 4em;
      // margin-right: 4em;
    }
    .dropdown-toggle::after{
        content: " " !important;
        display: none !important;
    }
  }
  .arrow_down{
      width: 0.8rem !important;
      margin-left: 1rem;
  }
  .header-right{
    .dropmenu {
      background-color: #008174 !important;
      // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
      letter-spacing: var(--unnamed-character-spacing-0);
      text-align: left;
     font: normal normal bold 14px/17px Calibri Regular;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      // padding: 0.22rem !important;
      // padding-left: 0.7rem !important;
      // padding-right: 0.7rem !important;
    }
    .allDayMenu{
      // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
      letter-spacing: var(--unnamed-character-spacing-0);
      text-align: left;
      font: normal normal bold 14px/17px Calibri Regular;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      padding: 0.45rem !important;
      padding-left: 0.6rem !important;
      padding-right: 0.6rem !important;
    }
    .dropdown-menu{
        transform: translate(-10px,27px) !important;
      }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .header{
      margin: 1em 1em;
      .ant-picker, .allDayMenu{
        margin-top: 1rem !important;
      }
    }
    .header__hr{
      margin: 0 1rem !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 569px)  { 
    .header{
      width: 82%;
      margin: 1em 0.4em;
    }
  }
  @media only screen and (min-width: 360px) and (max-width: 812px)  { 
    .header{
      margin: 1em;
      width: 88%;
    }
  }
  @media only screen and (min-width: 425px) and (max-width: 562px)  { 
    .header{
      width: 93%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1023px)  { 
    .header{
      margin: 1em 1em;
    }
    .header__hr{
      margin: 0 2rem !important;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1365px)  { 
    .header{
      margin: 1em 2em;
    }
    .header__hr{
      margin: 0 1.5rem !important;
    }
  }