$primary-color : #008174;
.min-h-100vh{
    min-height: 100vh;
}
.report-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .report-header-section-1 {
      display: flex;
      flex-direction: column;
    }
    .report-header-title-text{
        font-size: 1.5rem;
        font-weight: 600;
        font-family: Calibri Regular;
        color: black;
    }
    .report-header-section-2 {
      display: flex;
      flex-direction: row;
      padding-top: 25px;
      select {
        width: 150px;
        margin: 0px 5px;
        height: 34px;
        color: #fff;
        background-color: $primary-color;
      }
    }
    .report-header-subtitle a{
        text-decoration: none;
    }
    .radio-button-group{
        border: 1px solid #969696;
        border-radius: 30px;
        width: 10%;
        text-align: center;
        height: 1%;
        padding: 3px 3px;
        .ant-radio-button-wrapper{
            border: none;
            outline: none;
            height: 30px;
            .ant-radio-button-checked{
                background: $primary-color;
                outline: none;
            }
            .ant-radio-button{
                border-radius: 30px;
            }
        }
        .ant-radio-button-wrapper:focus-within {
            box-shadow: none;
        }
    }
    
    
    .ant-radio-button-wrapper-checked,.ant-radio-button-wrapper-checked:hover{
        color: #fff;
    }
  }

.report-container{
    padding: 0em 4em;
    .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
        padding: 0;
    }
    .ant-table-thead > tr > th{
        padding: 8px 16px;
        color: #A1ACBD;
    }
    .ant-table-tbody > tr > td{
        padding: 8px 16px;
    }
    .more_img{
        width: 25px !important;
    }
}

.report-filter-container{
  padding: 0 0em;
//   hide filter button 
      .report_filter_button{
        display: none;
      }
    .font-smaller{
      font-size: smaller;
      position: absolute;
      background: white;
    }

    .border-right-dotted{
        border-right: 2px dashed #A5B0C0;
    } 
    .floating-label-search{
        top: -30%;
        z-index: 1;
        // width: 12%;
        left: 3%;
        // padding-left: 1%;
        width: auto;
        padding-left: 6px;
        padding-right: 6px;
    }

    .floating-label-company{
        top: -30%;
        z-index: 1;
        // width: 30%;
        left: 8%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
    }

    .floating-label-mode{
        top: -30%;
        z-index: 1;
        // width: 20%;
        left: 8%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
    }

    .floating-label-date{
        top: -30%;
        z-index: 1;
        // width: 25%;
        left: 8%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
    }

    .margin-left-2per{
        margin-left: 2%;
    }

    ul{
        display: block;
    }

    .goto-btn{
        background-color: $primary-color;
    }
    .ant-input {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 1rem;
        height: 12px !important;
        width: 100%;
        background-color: transparent !important;
        border-radius: 4px;
        padding-left: 15px !important;
        min-height: 2.5rem !important;
    }

    .ant-btn-icon-only{
        height: 40px !important;
        width: 40px !important;
        color: #A5B0C0;
    }
    .page-label,.ant-select-selector,.ant-picker{
        height: 40px !important;
    }

    .ant-btn:hover, .ant-btn:focus {
        color: $primary-color;
        border-color: $primary-color;
        background: #DBF1EE;
    }
    .ant-input,.ant-select{
        line-height: 2;
    }
    .ant-select-selector{
        padding: 6px 11px !important;
        border-radius: 4px !important;
    }
    .goto-field{
        width: 22%;
    }
    input[type='text'], input[type='email'], input[type='password'] {
        border: 1px solid #d9d9d9;
        outline: none;
    }
    .ant-btn {
        height: auto;
    }   
    .ant-picker-input{
        width: 100% !important;
    }
    .ant-picker{
        width: 100%;
        border-radius: 4px !important;
    }

    .border-radius-6px{
        border-radius: 4px !important;
    }
}
.ant-table-thead > tr > th {
    background-color: #fff;
    color: #A1ACBD;
}
a{
    text-decoration: none !important;
}
.ant-form-item
{
    margin-bottom: 9px !important;
}
// style the Remainder button 
.bg-theme
{
    background-color: #008174 !important;
    color: #fff;
}
// changes in form label
.ant-form-vertical .ant-form-item-label > label{
    color: black;
    font-weight: bold;
}
// style date and time icon color
.ant-picker-suffix {
    color: black ;
}
// style the placeholder color
.ant-picker-input > input::placeholder
{
    color: black;
}
// .ant-select-selector,.ant-picker,.ant-input
// {
//     border-color: black !important;
// }
.ant-collapse-content
{
    height: 200px;
    overflow-y: auto;
}
.rater-model-panel
{
    margin-top: 2%;
}


//for mobile device
@media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .list-report{
        margin-top: 0;
        .ant-radio-group{
            padding: 2px 2px;
            display: flex;
        }
        .report-header .radio-button-group{
            width: 59%;
            display: flex;
            margin-top: 2%;
            justify-content: space-evenly;
            
        }
        .ant-radio-button-wrapper:not(:first-child)::before{
            display: none;
        }
    }
    .report-container{
        padding: 0 1em !important;
        .ant-input {
            border-radius: 4px !important;
        }
        .ant-table-tbody > tr > td{
            padding: 8px 16px;
        }
        .ant-table-thead > tr > th{
            color: #A1ACBD;
            padding: 8px 16px;
        }
    }
    .report-filter-container{
        // padding: 0 1em !important;
        padding: 0 !important;
        
        // .floating-label-company{
        //     width: 36% !important;
        //     left: 4%;
        // }
        // .floating-label-search{
        //     width: 18% !important;
        //     left: 6%;
        // }
        .report_filter_button{
            display: block !important;
        }

        #report_filter_btn{
            height: 37px;
            padding: 5px 12px;
            border: none;
            border-radius: 4px;
            color: #fff;
            background-color: #008174;
        }
        .desk-data{
            display: none;
        }
        .ant-drawer-content-wrapper{
            width: 100% !important;
            height: 49% !important;
            // height: 35% !important;
            // margin-top: 18%; 
            justify-content: space-between;
        }
        .ant-drawer-body{
            padding: 14px 20px;
            margin: 0 5px ;
            justify-content: space-evenly;
        }
        #dash-border{
            margin: 17px 0;
            padding: 0 8px;
            border-bottom: 2px dashed #696867;
            width: 100%;
        }
    }
}
@media only screen and (min-width: 411px){ 
    .list-report{
        .report-header .radio-button-group{
            width: 50%;
        }
    }
}
@media only screen and (min-width: 425px){ 
    .list-report{
        .report-header .radio-button-group{
            width: 43%;
        }
    }
}
@media only screen and (min-width: 411px) { 
    .list-report{
        .report-header .radio-button-group{
            width: 48%;
        }
    }
    .report-container{
        .ant-table-wrapper{
            overflow-x: auto !important; 
        }
    }
}
@media only screen and (min-width: 360px) { 
    .list-report{
        .report-header .radio-button-group{
            width: 50%;
        }
    }
}
// @media only screen and (min-width: 375px) and (max-width: 812px)  { 
//     .list-report{
//         .report-header .radio-button-group{
//             width: 34%;
//         }
//     }
// }
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .report-container{
        padding: 0 1em !important;
        .ant-table-wrapper{
            overflow-x: auto !important; 
        }
        .ant-table-tbody > tr > td{
            padding: 8px 16px;
        }
        .report-header .radio-button-group{
            width: 23%;
            margin-top: 2%;
        }
    }
    .report-filter-container{
        padding: 0 1em !important;
        .floating-label-mode {
            top: -27%;
            z-index: 1;
            width: 29%;
            left: 10%;
        }
    }
}   

@media only screen and (min-width: 1024px) and (max-width: 1365px){
    .list-report{
        .report-header .radio-button-group{
            width: 19%;
            margin-top: 2%;
        }
    }
}
@media only screen and (min-width: 1440px){
    // .floating-label-date { 
    //     width: 35% !important;
    // }
    .more_img{
        margin-left:  0 !important;
    }
    .list-report{
        .report-header .radio-button-group{
            width: 10%;
        }
    }
}

@media only screen and (min-width: 2560px){
    .list-report{
        .report-header .radio-button-group{
            width: 8%;
        }
    }
}
