.add-company{
    // display: flex;
    // flex-direction: column;
    // height: 100%;
    // justify-content: space-between;
    // width: 450px;
    // ant design drawer component customization 
    // .ant-form label{
    //     font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
    //     letter-spacing: var(--unnamed-character-spacing-0) !important;
    //     color: var(--unnamed-color-000000) !important;
    //     text-align: left !important;
    //     font: normal normal bold 14px/17px Calibri !important;
    //     letter-spacing: 0px !important;
    //     color: #000000 !important;
    //     opacity: 1 !important;
    // }
    // .ant-input:placeholder{
    //     font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri) !important;
    //     letter-spacing: var(--unnamed-character-spacing-0) !important;
    //     color: var(--unnamed-color-000000) !important;
    //     text-align: left !important;
    //     font: normal normal normal 14px/17px Calibri !important;
    //     letter-spacing: 0px !important;
    //     color: #000000 !important;
    //     opacity: 0.5 !important;
    // }
    // .ant-picker-input>input::placeholder {
    //     font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri) !important;
    //     letter-spacing: var(--unnamed-character-spacing-0) !important;
    //     color: var(--unnamed-color-000000) !important;
    //     text-align: left !important;
    //     font: normal normal normal 14px/17px Calibri !important;
    //     letter-spacing: 0px !important;
    //     color: #000000 !important;
    //     opacity: 0.5 !important;
    // }
    // .ant-select-arrow{
    //     color: black !important;
    // }
    .button-group .cancelButton{
        border: 1px solid var(--unnamed-color-696867) !important;
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #696867 !important;
        border-radius: 3px  !important;
        opacity: 1 !important;
    }
    .button-group .saveButton{
        background: var(--unnamed-color-008174) 0% 0% no-repeat padding-box !important;
        background: #008174 0% 0% no-repeat padding-box !important;
        border-radius: 3px !important;
        opacity: 1 !important;
    }
    //drawer style over
    
    // .ant-upload-list-picture{
    //     display: none;
    // }
    
    .form-container{
       padding: 1.5rem;
    //    padding-top: 5px;
    }
    .add-company-row1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .profile-label{
            span{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/30.81px Roboto;
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
                font: normal normal bold 13px/31px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
                text-transform: capitalize;
                opacity: 1;   
            }
        }
        .profile-desc{
            background-color:rgb(206, 206, 206);
            border-radius: 5px;
            padding:0.7rem;
            display:flex;
            flex-direction: row;
            justify-content: center;
            max-width: 50%;
            line-height: 1rem;
            span{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/11px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
                font: normal normal normal 10px/11px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;   
            }
        }
        .profile-photo{
            position: relative;
            .profile-circle{
                width: 6rem;
                height: 6rem;
                // border : 1px solid black;
                border-radius : 50%;
                padding : 0.2rem;
                  img{
                    border-radius: 50% ;
                    width: 100%;
                    height: 100%;
                  }
            }
            .input-form{
                background: white;
                border-radius: 50%;
                position: absolute;
                width: 1.8rem !important;
                height: 1.8rem !important;
                border: 1px solid #707070;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                // margin-left: 52px;
                // margin-top: -25px;
                bottom: 0;
                right: 0;
                .cam-circle{
                    margin: 0 auto;
                    img{
                        width: 100%;
                    }
                }
                .profile-input{
                    display: none;
                }
            }
        }
    }
    .h-32px{
        height: 32px;
        background-color: #FFFFFF !important;
        border: 1px solid #BFBFBF !important;
        border-radius: 4px !important;
        opacity: 1;
    }
}
.form-row{
    display: flex;
    flex-direction: row;    
}
.form-group{
    margin-top: 0.7rem;
}
.form-label{
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal bold 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.3rem !important;
    
}
.form-control{
    font-family: Calibri Regular;
    font-size: 1rem;
    color: rgb(172, 172, 172);
}
.form-select{
    font-family: Calibri Regular;
    font-size: 1rem;
    color: rgb(172, 172, 172);
}
.asterik{
    font-family: Calibri Regular;
    font-weight: 600;
    font-size: 0.8rem;
    color: red;  
}

.button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1.9rem;
    .cancelButton{
        border: 1px solid black;
        width: 20%;
        font-family: Calibri Regular;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem;
        height: 40px;
        &:hover{
            border: 1px solid black;
        }
        
    }
    .saveButton{
        margin-left: 10px;
        background-color: #008174;
        color: #fff;
        width: 20%;
        height: 40px;
        font-family: Calibri Regular;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem;
        &:hover{
            color: #fff;
            background: #008174;
        }
    }
}

.ant-drawer-content-wrapper{
    input[type='text'], input[type='email'], input[type='password'],.ant-select-selector,.ant-picker,.form-group textarea,.form-select{
        background-color: #FFFFFF  !important;
        border: 1px solid #BFBFBF !important;
        border-radius: 4px !important;
        opacity: 1;
    }
}


@media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .profile-label{
        display: none !important;
    }
    .profile-desc{
        max-width: 65% !important;
    }
}