.add-project{
    display: flex;
    flex-direction: column;
    // width: 450px;
    
    .form-container{
       padding: 20px;
    //    padding-top: 5px;
    }
    .add-company-row1{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
        .profile-label{
            span{
                font-family: Calibri Regular;
                font-size:1rem;
                font-weight: 600;
                color: black;   
            }
        }
        .profile-desc{
            background-color:rgb(206, 206, 206);
            border-radius: 5px;
            padding:11px;
            display:flex;
            flex-direction: row;
            justify-content: center;
            max-width: 50%;
            span{
                font-family: Calibri Regular;
                font-size:0.8rem;
                color: black;   
            }
        }
        .profile-photo{
            position: relative;
            .profile-circle{
                width: 80px;
                border : 1px solid black;
                border-radius : 50%;
                padding : 3px;
                 img{
                    border-radius: 50% ;
                    width: 100%;
                 }
            }
            .input-form{
                background: white;
                border-radius: 50%;
                position: absolute;
                width: 36px !important;
                height: 36px !important;
                border: 1px solid black;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 52px;
                margin-top: -35px;
                .cam-circle{
                    width: 16px;
                    
                    img{
                        width: 100%;
                    }
                }
                .profile-input{
                    display: none;
                }
            }
        }
    }
}
.form-row{
    display: flex;
    flex-direction: row;    
}
.form-group{
    margin-top: 12px;
}
.form-label{
    font-family: Calibri Regular;
    font-weight: 600;
    font-size: 0.8rem;
    color: black;
    margin-bottom: 2px !important;
    
}
.form-control{
    font-family: Calibri Regular;
    font-size: 1rem;
    color: rgb(172, 172, 172);
}
.form-select{
    font-family: Calibri Regular;
    font-size: 1rem;
    color: rgb(172, 172, 172);
    option{
        font-family: Calibri Regular;
        font-size: 1rem;
        color: rgb(172, 172, 172);
    }
}
.asterik{
    font-family: Calibri Regular;
    font-weight: 600;
    font-size: 0.8rem;
    color: red;  
}

.button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    .cancelButton{
        border: 1px solid black;
        width: 20%;
        font-family: Calibri Regular;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem;
        height: 40px;
        &:hover{
            border: 1px solid black;
        }
        
    }
    .saveButton{
        margin-left: 10px;
        background-color: #008174;
        color: #fff;
        width: 20%;
        height: 40px;
        font-family: Calibri Regular;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem;
        &:hover{
            color: #fff;
            background: #008174;
        }
    }
}
.modal-footer{
    background-color: rgba(#008174,0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem !important;

    .bulb{
       width: 30px;
       margin-left: 30px;
    }
    .tip-container{
        display: flex;
        flex-direction: column;
        width: 80%;
        .quick_tip{
            font-family: Calibri Regular;
            font-size: 0.8rem;
            font-weight: 600;
            color: black;
        }
        .quick_tip_desc{
            font-family: Calibri Regular;
            font-size: 0.8rem;
            color: black;
        }
    }
}