.login-container {
  max-width: 100%;
  height: 100vh;
  display: flex;
  // div:nth-of-type(1) {flex-grow: 1;}
  // div:nth-of-type(2) {flex-grow: 1;}

  @media only screen and (max-width:991px){
      flex-wrap: wrap;
  }

  .heading-h4{
    font-size: 28px;
  }
  .heading-h4-desc{
    font-size: 24px;
  }

  .col-1 {
    background-color: #EDECEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;

    @media only screen and (max-width:991px){
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    .col-1-section {
      display: flex;
      flex-direction: column;
      // max-width: 70%;
      // height: 300px;
      
      .col-1-section-1  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;

      }
      .col-1-section-2  {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        // .col-1-section-2-btn {
        //   display: flex;
        //   align-content: space-between;
        // }
      }
    }

  }
  .col-2 {
    display: flex;
    // flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color: #FFFFFF;
    max-width: 100%;

    @media only screen and (max-width:991px){
      flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
    }

    .col-2-section {
      height: auto;
      max-width: 510px;
      width: 100%;

      .col-2-section-1 {
        padding: 10px 0;


        .logoWrap{
          @media only screen and (max-width:991px){
            text-align: center;

            .ant-image {
              display: inline-block;
            }
          }
          img {
            max-width: 100%;
          }
        }
      }
      .col-2-section-2 {
        padding:10px 0;

        @media only screen and (max-width:991px){
          text-align: center;
        }

        .heading-h4 {
          // font-size: 1.8rem !important;


          @media only screen and (max-width:991px){
            line-height: 2rem !important;
            font-size: 1.5rem !important;
          }
          @media only screen and (max-width:767px){
            line-height: 2rem !important;
            font-size: 1.3rem !important;
          }
        }
      }
      form {
        padding: 10px 0;
        .forgot-password {
          display: flex;
          justify-content: space-between;
          // max-width: 300px;
          width: 100%;
        }
      }
    }
  } 

  #footer_data{
    position: absolute;
    bottom: 0;
    // border-top: 1px solid #C1C1C1;
    // margin-top: 22.7rem !important;
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
    padding: 8px 4px;
    margin:8px 8px ;
    width: 98%;
    #footer_description{
      // min-width: 100% !important;
      span{
        color: #C1C1C1;
      }
    }
    #footer_menus{
      display: flex;
      justify-content: space-between;
      min-width: 50% !important;
      a{
        color: #C1C1C1;
      }
    }
  }
}

@media (max-width: 800px) {
  .login-container  {
    flex-direction: column;
  }
}


.login-container {
  height: 100vh;
  min-height: 100vh;
  width: 100% !important;
  justify-content: center !important;

  & > div {
    flex: 0 0 50%;
    max-width: 100%;
    padding-top: 50px;
    // padding-bottom: 50px;
  }
  .col-1-section{
    max-width: 630px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: auto;
    justify-content: center;

    .innerSecWrap{
      flex-grow: unset;
    }

    .headLftTop{
      border-bottom: 1px solid transparent;
      flex-direction: unset;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding-top: 15px;
      padding-bottom: 25px;

      img {
        width: auto;
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
        aspect-ratio: unset;
      }

      & > p{
        text-align: center;
        line-height: 2.2rem;
        font-size: 1.7rem;
        margin: 0;

        @media only screen and (max-width:991px){
          line-height: 2rem;
          font-size: 1.5rem;
        }
        @media only screen and (max-width:767px){
          line-height: 2rem;
          font-size: 1.3rem;
        }
        & > strong{
          display: block;
        }
      }
    }
    .headLftBot{
      padding-top: 30px;
      padding-bottom: 30px;
      
      .label{
        text-align: center;
      }

      .btn{
        margin-left: 15px;
      }
      .col-1-section-2-btn{
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 50px;

        .label{
          font-size: 28px !important;
          vertical-align: middle;
        }
      }
    }
  }
}

.modalWrapper{
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  .modalText{
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 28px/35px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-696867);
    text-align: center;
   font: normal normal bold 28px/35px Calibri Regular;
    letter-spacing: 0px;
    color: #696867;
  }
  .modalButton{
    background: var(--unnamed-color-008174) 0% 0% no-repeat padding-box;
    background: #008174 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 22px #0081744D;
    border-radius: 4px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 2rem;
    &:hover{
      background: #008174 0% 0% no-repeat padding-box;
    }
    .modalBtnText{
      // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-calibri);
      letter-spacing: var(--unnamed-character-spacing-0);
      text-align: left;
      font: normal normal bold 16px/19px Calibri Regular;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-transform: uppercase;
    }
  }
  #footer_data{
    // border-top: 1px solid #008174;
    margin-top: 15.7rem !important;
    display: flex !important;
    justify-content: space-between;
    font-size: 0.8rem;
    padding:8px 8px ;
    #footer_description{
      min-width: 50% !important;
    }
    #footer_menus{
      display: flex;
      justify-content: space-between;
      min-width: 50% !important;
    }
  }
}

//for mobile device
@media (min-width: 320px) and (max-width: 768px)  { 

  .login-container{
    #footer_data{
      border-top: none !important;
      position: inherit !important;
      display: block !important;
    }
  }

}