.user-popup-form-container{
  .user-img{
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .w-9{
    width: 9%;
  }
  .w-15{
    width: 15%;
  }
  
  .ant-drawer-close{
    display: none;
  }
  
  .position-relative{
    position: relative;
  }
  .h-10vh{
    height: 10vh;
  }
  
  
  .profile-pic {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  label.filebutton {
    overflow:hidden;
    position:absolute;
    background-color:#FFFFFF;
    border: 1px solid #BFBFBF;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    color: #F5F5F5;
    bottom: 0;
    right: 0;
    cursor: pointer;
    .anticon-camera{
      color: #00FF00;
    }
  }
  
  label span input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    top: -2px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding:0;
  }
  
  .bg-EDECEC{
    background-color: #EDECEC;
  }
  .profile-pic-rule{
    border-radius: 15px;
    font-size: 10px;
  }
  
  .popup-sidebar-form-input {
    font-size: 1rem;
    height: 40px !important;
    width: 100%;
    background-color: #FFF !important;
    border-radius: 8px;
    padding: 4px 11px !important;
  }  
  .user-popup-note{
    // position: fixed;
    bottom: 0;
  }
  .table-responsive{
    height: 67vh;
  }
}
.user_detail_rater_list{
  tbody{
    min-height: 50px;
    overflow: auto;
  }
  .more-outlined{
    .dropdown-toggle::after {
      display: none;
    }
    .anticon-more{
      // font-size: 2rem;
      color: #A5AFC0;
    }
    button{
      padding: 0;
      border: none !important;
    }
  }
}
.from-company{
  .user-detail-charts-in-popup .percent-r, .user-detail-charts-in-popup .percent-e {
    position: absolute;
    // top: 43%;
    // // left: 38% !important;
    // left: 44% !important;
    // font-size: 1.5rem;
    // font-weight: 900;
    text-align: center;
    left: 0 !important;
    right: 0;
    top: 41%;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }
}