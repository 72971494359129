.navbar {
  background-color: #008174 !important;
  width: 100% !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  min-height: 80px !important;
  // top:0 !important;
  .dropdown-item:focus {
    background-color: #008174;
    color: #fff !important;
  }
  .dropdown-item:active {
    background-color: #008174 !important;
    color: #fff !important;
  }
}
.navbar-brand {
  margin-right: 0% !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.dropdown_arrow {
  align-self: center;
  width: 14px;
  height: 13px;
  margin-left: 4%;
  transform: rotate(360deg);
  // transform: rotate(360deg);
}
#myproject:focus {
  .dropdown_arrow {
    transform: rotate(180deg);
  }
}
#myproject:active {
  .dropdown_arrow {
    transform: rotate(180deg);
  }
}
.profile-dropdown:focus {
  .dropdown_arrow {
    transform: rotate(180deg);
  }
}
.profile-dropdown:active {
  .dropdown_arrow {
    transform: rotate(180deg);
  }
}

.navContainer {
  justify-content: end;
  // padding: 0 4em;
  padding: 0%;
  // align-items: baseline;
  width: 100%;
  display: inline-block;
  .dropdown-menu {
    top: 51px !important;
    left: -155px !important;
    margin-top: 3rem !important;
    // margin-left: -6.5rem !important;
    border: none;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: none !important;
    border-radius: 5px;
    opacity: 1;
    min-width: 15rem !important;
    padding: 0.9rem !important;
  }
}
.nav-item {
  display: flex !important;
  color: white !important;
  // justify-content: center !important;
  // align-items: center !important;
  //font: normal normal bold 14px/17px Calibri !important;
  text-transform: uppercase;
  // margin-top: 4px;
  .nav-link-text {
    // font: normal normal bold 14px/17px Calibri !important;
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #ffffff;
    // text-transform: uppercase;
    opacity: 1;
    margin-left: 12px !important;
    margin-top: 3px;
  }
  .noti-num-container {
    border-radius: 50%;
    height: 18px;
    width: 18px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: 13px;
    margin-top: -3px;
    .noti-num {
      color: #000;
      font-size: 0.9rem;
      font-weight: 600;
      font-family: Calibri Regular;
    }
  }
}
.navbg {
  .dropdown-toggle {
    // font: var(--unnamed-font-style-normal) normal
    //   var(--unnamed-font-weight-bold) 14px/17px
    //   var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: normal normal 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    padding: 0px !important;
    padding-left: 15px !important;
  }
}
.nav-link {
  color: white !important;
  padding: 0 3px !important ;
}
.nav-left {
  color: white !important;
  /* margin-left: 5rem; */
}

.vl {
  border-left: 1px solid rgba(white, 0.3);
  height: 50px;
  // margin-left: 2rem;
}
.nav-item-profile {
  margin-left: 1.3rem !important;
}
.nav-item-notification {
  margin-left: 1.2rem;
}
.profile {
  border-radius: 50%;
  background-color: white;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.profile span {
  // font: var(--unnamed-font-style-normal) normal
  //   var(--unnamed-font-weight-normal) 22px/27px
  //   var(--unnamed-font-family-calibri);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-008174);
  text-align: left;
  font: normal normal normal 22px/27px Calibri Regular;
  letter-spacing: 0px;
  color: #008174;
  opacity: 1;
}

.nav-link-item {
  padding: 10px 25px;
  // padding: 12px 4px;
  // padding-left: 1.2rem !important;
  // padding-right: 1.2rem !important;
  border-radius: 5px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: left;
  &:hover {
    background: #009889;
  }
  &:active {
    background: #009889;
  }
}

.nav-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.8rem;
  opacity: 1;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  .nav-dropdown-icon {
    width: 1rem;
  }
  #my_project {
    padding: 12px 4px !important;
  }
  .nav-dropdown-text {
    // font: var(--unnamed-font-style-normal) normal
    //   var(--unnamed-font-weight-bold) 14px/17px
    //   var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-696867);
    text-align: left;
    font: normal normal 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #696867;
    opacity: 1;
    margin-left: 0.8rem;
    a {
      color: #696867;
    }
  }
  .last-login-wrapper {
    display: flex;
    flex-direction: column;
    .nav-dropdown-date {
      // font: var(--unnamed-font-style-normal) normal
      //   var(--unnamed-font-weight-normal) 12px/24px
      //   var(--unnamed-font-family-calibri);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-696867);
      text-align: left;
      font: normal normal normal 12px/24px Calibri Regular;
      letter-spacing: 0px;
      color: #696867;
      opacity: 1;
      margin-left: 0.8rem;
      line-height: 0.8rem;
    }
    .nav-dropdown-text {
      // font: var(--unnamed-font-style-normal) normal
      //   var(--unnamed-font-weight-bold) 10px/24px
      //   var(--unnamed-font-family-calibri);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-696867);
      text-align: left;
      font: normal normal 10px/24px Calibri Regular;
      letter-spacing: 0px;
      color: #696867;
      opacity: 1;
      line-height: 0.8rem;
    }
  }
}

.container {
  padding: 0 4em !important;
  max-width: 100% !important;
  display: flex;
}
.navbar-collapse {
  justify-content: end;
  // top: 80px !important;
  width: 100%;
  background-color: #008174;
}
#user_name {
  align-self: center;
}
.nav-logo {
  width: auto;
}
.ant-image-img {
  width: auto !important;
}
//for mobile device
@media only screen and (min-width: 320px) and (max-width: 1023px) {
  .dropdown_arrow {
    float: right;
  }
  #user_name {
    display: none !important;
  }
  .navbar {
    // padding-bottom: 0 !important;
    min-height: 80px !important ;
  }
  .navContainer {
    padding: 0 !important;
  }
  .navbar-collapse {
    position: absolute;
    top: 80px;
  }
  .container {
    padding: 0 1em !important;
    max-width: 100% !important;
    display: flex;
  }
  .vl {
    display: none !important;
  }
  .nav-link-item {
    padding-left: 0 !important;
    border-bottom: 1px solid #fff !important;
    margin-left: 13px !important;
  }
  .nav-item .nav-link-text {
    //font: normal normal bold 14px/17px Calibri !important;
    margin-left: 16px !important;
    margin-top: 3px;
  }
  .nav-item-profile {
    .dropdown-menu {
      top: 3px !important;
      left: 87px !important;
    }
  }
  .project-dropdown {
    display: block !important;
    .dropdown-item:active {
      background-color: transparent !important;
    }
    .dropdown-item:hover {
      background-color: transparent !important;
    }
    .dropdown-menu.show {
      position: static !important;
      background-color: transparent !important;
      color: #fff !important;
      left: 0 !important;
      border: none !important;
    }
    .nav-link-item {
      padding-left: 0 !important ;
    }
    .nav-link-item:active {
      background-color: none;
    }
    .dropdown-item {
      color: #fff !important;
      padding-left: 0 !important ;
      // font: normal normal bold 14px/17px Calibri !important;
    }
    .nav-link-item {
      border-bottom: none !important;
      color: #fff !important;
    }
  }
  .project-label {
    padding-top: 2px !important;
  }
  // .dropdown-menu{
  // display: block !important;
  // }
  .nav-item .dropdown {
    // margin-top: 10px !important;
    margin-left: 5px !important;
  }
  .dropdown-menu .show {
    // width: 100% !important;
    position: static !important ;
    display: block !important;
  }
  .nav-link {
    // padding: 8px 19.2px 7px 0px !important;
    //font: normal normal bold 14px/17px Calibri !important;
    text-transform: uppercase !important;
  }
  .nav-item-notification {
    margin-left: 0rem;
  }
  #help {
    padding: 12px 4px 12px 13px !important;
  }
  .nav-link-item .my_project {
    padding: 12px 4px 12px 13px !important;
  }
  .nav-link-item {
    margin-left: 0 !important;
    padding-left: 13px !important;
    border-radius: 0 !important;
  }
  .container {
    padding: 0 !important;
  }
  .navbar-dark .navbar-toggler {
    border: 0 !important;
    padding: 0 !important;
  }
  .profile {
    // position: absolute;
    // top: 24px !important;
    // left: 283px !important;
    // border-radius: 50%;
    // background-color: white;
    width: 37px;
    height: 37px;
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
  }
  .navbar-toggler {
    font-size: 1.6rem !important;
    color: #fff !important;
    border: none !important;
  }
  // .nav-item-notification {
  //   position: absolute !important;
  //   top: 20px !important;
  //   left: 228px !important;
  // }

  .container {
    // justify-content: space-between !important;
    // flex-direction: row-reverse;
  }
  .nav-logo {
    order: 0;
    width: 52%;
    margin-left: 1%;
  }
  .nav-item-notification {
    order: 1;
  }
  .profile-dropdown {
    order: 2;
  }
  .navbar-toggler {
    order: 3;
  }
  .navbar-collapse {
    order: 4;
  }
}
// for small device
@media (max-width: 360px) {
  .nav-logo {
    width: 42% !important;
  }
  // .nav-item-notification{
  //   top: 15px !important;
  //   left: 206px !important;
  // }
  // .profile{
  //   top: 11px !important;
  //   left:265px !important;
  // }
}

// for ipad
@media (min-width: 768px) and (max-width: 1023px) {
  .nav-logo {
    width: 30%;
    margin-left: 4%;
  }
  #user_name {
    display: none !important;
  }
  .profile {
    // position: fixed;
    top: 15px !important;
    left: 660px !important;
    width: 43px;
    height: 43px;
  }
  // .nav-item-notification {
  //   position: absolute !important;
  //   top: 22px !important;
  //   left: 590px !important;
  // }

  .navbar-toggler {
    border: 0 !important;
    float: right !important;
  }
  .navbar-toggler:focus {
    outline: 0 !important;
  }
}

@media (min-width: 1024px) and (max-width: 1365px) {
  .container {
    padding: 0em !important;
    .nav-link-item {
      padding: 10px 6px;
    }
  }
  .nav-logo {
    width: 20%;
  }
  .nav-item {
    .ant-image {
      img {
        display: none;
      }
    }
  }
  .nav-item-notification {
    .ant-image {
      img {
        display: block;
        width: 25px;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 420px) {
  .logo {
    width: 61%;
  }
}
@media (min-width: 324px) and (max-width: 1024px) {
  .navbar-toggler-icon {
    margin: 1rem;
  }
  // .navbar {
  //   z-index: 1050 !important;
  // }
}
