.modal-footer{
    background-color: rgba(#008174,0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem !important;

    .bulb{
       width: 30px;
       margin-left: 30px;
    }
    .tip-container{
        display: flex;
        flex-direction: column;
        width: 80%;
        .quick_tip{
            font-family: Calibri Regular;
            font-size: 0.8rem;
            font-weight: 600;
            color: black;
        }
        .quick_tip_desc{
            font-family: Calibri Regular;
            font-size: 0.8rem;
            color: black;
        }
    }
}