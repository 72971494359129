.login-container {
  max-width: 100%;
  // height: 100vh;
  display: flex;
  // div:nth-of-type(1) {flex-grow: 1;}
  // div:nth-of-type(2) {flex-grow: 1;}

  .logoImg{
    width: 446px;
    height: 59px;
  }
  .heading-h4{
    font-size: 28px;
  }

  .col-1 {
    // background-color: #EDECEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    

    .col-1-section {
      display: flex;
      flex-direction: column;
      // max-width: 70%;
      // height: 300px;
      .col-1-section-1  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
      }
      .col-1-section-2  {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        // .col-1-section-2-btn {
        //   display: flex;
        //   align-content: space-between;
        // }
      }
    }
  }
  .col-2 {
    display: flex;
    // flex-direction: column;
    justify-content:center;
    align-items: center;
    background-color: #FFFFFF;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    .col-2-section {
      // height: auto;
      // max-width: 400px;

      .col-2-section-1 {
        padding: 10px 0;
      }
      .col-2-section-2 {
        padding:10px 0;
      }
      form {
        padding: 10px 0;
        .forgot-password {
          display: flex;
          justify-content: space-between;
          // max-width: 300px;
          width: 100%;
          margin-bottom: 1.5rem;

          & > a {
            font-weight: 600;
          }
        }
      }
    }
  } 
  #footer_data{
    position: absolute;
    bottom: 0;
    border-top: 1px solid #C1C1C1;
    // margin-top: 22.7rem !important;
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
    padding: 8px 4px;
    margin:8px 8px ;
    width: 98%;
    #footer_description{
      // min-width: 100% !important;
    }
    #footer_menus{
      display: flex;
      justify-content: space-between;
      min-width: 50% !important;
    }
  }
}

@media (max-width: 800px) {
  .login-container  {
    flex-direction: column;
  }
}
.login-container{
  .btn-custome{
    padding: 0.5rem 1rem !important;
    font-size: 1.25rem !important;
    border-radius: 0.3rem !important;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #008174;
    color: #fff;
    height: auto; 
    box-shadow: 2px 7px 10px 1px rgba(0,0,0,0.18);
    -webkit-box-shadow: 2px 7px 10px 1px rgba(0,0,0,0.18);
    -moz-box-shadow: 2px 7px 10px 1px rgba(0,0,0,0.18);
  }
  .ant-input-affix-wrapper{
    padding: 0;
  }
  .ant-input{
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    height: 64px;
    width: 100%;
    background-color: #EDECEC !important;
    border-radius: 5px;
    border-color: #EDECEC;
    padding-left: 65px !important;
    border-style: solid;
  }
  
  .ant-checkbox-inner {
    width: 1.5em;
    height: 1.5em;
    background-color: #ddd;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #1890ff !important;
  }
  .ant-checkbox {
    top: 0.3em;
  }

}
.modalWrapper{
  display:flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  .modalText{
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 28px/35px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-696867);
    text-align: center;
   font: normal normal bold 28px/35px Calibri Regular;
    letter-spacing: 0px;
    color: #696867;
  }
  .modalButton, .btn-primary{
    background: var(--unnamed-color-008174) 0% 0% no-repeat padding-box;
    background: #008174 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 7px 22px #0081744D;
    border-radius: 4px;
    border: none !important;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    // margin-top: 2rem;
    &:hover{
      background: #008174 0% 0% no-repeat padding-box;
    }
    .modalBtnText{
      // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-19) var(--unnamed-font-family-calibri);
      letter-spacing: var(--unnamed-character-spacing-0);
      text-align: left;
      font: normal normal bold 16px/19px Calibri Regular;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-transform: uppercase;
    }
  }
}