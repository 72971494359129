.canvas_div_pdf {
  h1, h2, h3{
    font-family: 'Calibri Bold';
    font-size: 20pt !important;
  }
  .highcharts-credits{
    display: none;
  }
  .w-40{
    width: 40%;
  }
  // .progress, .progress-bar {
  //   overflow: visible !important;
  //   height: 1rem !important;
  //   width: 5rem;
  //   // display: block;
  // }
  // .progress-bar{
  //   border-radius: 15px;
  // }
  // .progress{
  //   background: #e5e5e5 !important;
  // }
  .overlay-graph-detailedresult{
    width: 85px;
  }
  .page {
    // padding: 3px;
    font-family: 'Calibri';
    // background-color: #e7e6e6;
    // width: 780px;
    width: 811px;
    // width: 794px;
    margin: 0 auto;
    background-color: #fff;
    .pagecontainer {
      padding: 2px;
      // margin: 2px;
    }
  }
  .w-35px {
    width: 35px;
  }
  .page-height{
    // min-height: 345mm;
    // position: relative;
    // page-break-before: avoid;
    // page-break-inside: avoid;
    // page-break-after: always;
    // clear:both;
  }

  .reportcontainer {
    font-family: 'Calibri';
    // background-color: #ffffff;
    // padding: 3px;
    margin: 6%;
    // margin-top: 15%;
    margin-top: 0%;
    margin-bottom: 0%;
    padding-top: 15%;
    padding-bottom: 7%;
    background-color: #fff;
    // min-height: 345mm;
    // margin-left: 0;
    // margin-right: 0;
    // margin : 17px
    // box-shadow: 1px 0px 6px 7px #d5d2d2;
  }

  .mt-15{
    margin-top: 20%;
  }

  .report-background {
    // background-color: #e7e6e6;
    // width: 10px;
    // height: 20px;
  }

  .highcharts-data-label{
    letter-spacing: 1px;
  }

  .progress_oriantation {
    display: flex;
    justify-content: space-between;
    padding: 0;
    border-bottom: 2px dotted rgb(202, 202, 202);
  }

  .prograss_box {
    border-right: 2px dotted rgb(202, 202, 202);
    margin-left: 0;
    padding-right: 20px;
    // width: 35%;
  }

  .prograss_box3 {
    margin-left: 20px;
  }

  .bottomline {
    // width: 220px;
    width: auto;
    height: 6px;
  }

  .linebar {
    width: 70px;
    // height: 180px;
    height: auto;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
  }

  .linebaroveralldata {
    width: 85px;
    // height: 205px;
    height: auto;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 215px;
  }

  .linebaroveralldata span {
    // transform: rotate(180deg);
    // font-size: 20px;
    // font-weight: bold;
    // writing-mode: vertical-rl;
    // writing-mode: tb-rl;
    // // height: min-content;
    // line-height: 1;
    width: max-content;
    // // text-orientation: mixed;
    // padding: 9px;
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    font-size: 20px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    // min-width: 183px;
    min-width: 40px;
    text-align: center;
    color: #FFFFFF;
  }
  .ant-progress-outer {
    width: inherit;
  }
  .linebar span {
    transform: rotate(270deg);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    text-transform: uppercase;
  }

  .linebar_desc {
    width: calc(100% - 100px) !important;
    padding-left: 25px;
  }

  .linebar_desc p {
    // margin-bottom: -0.8rem !important;
  }

  .feedback_number {
    border-radius: 50%;
    background-color: #ffffff;
    width: 27px;
    height: 27px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #a09d9b;
    margin-left: 1px;
    margin: auto;
  }

  .feedback_number span {
    font-size: 9pt;
  }

  .feedback {
    display: flex;
    padding: 12px;
    justify-content: space-between;
  }

  .feedback_boarder {
    // display: flex;
    border-right: 1px dotted #a09d9b;
    align-items: center;
    // margin-left: 0;
    height: 90px;
  }

  .feedback_noboarder{
    border: none;
  }

  .w-20 {
    width: 20%;
  }

  .feedback_span {
    margin: 35px;
  }

  .ant-progress-bg {
    // transition: 1.5s;
    height: 10px !important;
  }

  .raters {
    font-size: 20px;
    color: black;
    font-weight: bold;
  }

  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
  }

  .respodents {
    display: flex;
    // padding-top: 50px;
    padding-top: 15px;
    margin-left: 45px;
    // margin-bottom: 45px;
    margin-bottom: 10px;
    flex-flow: wrap;
  }

  .rating {
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    height: 100%;
  }
  .rating_text {
    font-size: 12pt;
    margin-bottom: 0.7rem;
    display: block;
    word-wrap: break-word;
    width: 100%;
    height: 53%;
  }

  .imgbackground {
    background-color: #008174e3;
    width: 496px;
    height: 374px;
    left: -10px;
    top: 85px;
    z-index: 0;
    position: absolute;
    opacity: 1;
  }

  .imgdescription {
    color: #ffffff;
    margin-left: 20%;
    padding-top: 0px;
    font-size: 125pt !important;
    font-weight: 900 !important;
    margin-bottom: 0;
  }

  .underline {
    width: 450px;
    height: 6px;
    background-color: #ffffff;
  }

  .text_img {
    color: #ffffff;
    margin-left: 20%;
    padding-top: 4%;
    font-size: 20pt;
    margin-bottom: 0;
    // font-weight: 900;
    // letter-spacing: 2px;
  }

  .lines {
    margin-top: 10px;
    background-color: #119182;
    width: 823px;
    height: 10px;
    margin-left: -6px; 
  }

  .logo {
    padding: 60px 0px 0 170px;
  }

  .percent-pi {
    position: absolute;
    top: 45%;
    left: 36% !important;
    font-size: 4rem;
    font-weight: 400;
  }
  .positivity {
    margin-top: 0;
  }

  .positivitycard {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 87%;
    border-radius: 50%;
    // margin-bottom: 45px;
    margin-bottom: 20px;
  }

  .positivitycard-chart {
    margin-bottom: 0;
    width: 40%;
    height: 60%;
    background-color: #FFFFFF;
  }
  .positivitycard-chart-odd {
    margin-bottom: 0;
    width: 20%;
    height: 46%;
    background-color: #FFFFFF;
  }

  .positivity_discription {
    line-height: 2;
    align-items: center;
  }

  .positivity_discription span {
    font-weight: lighter;
    font-size: 12pt;
  }

  .cardBody h5 {
    font-size: 12pt;
    font-weight: bold;
    text-align: center;
    font-size: 1.3rem;
    // padding-left: 30%;
    letter-spacing: 3px;
  }

  .positivitycard span {
    position: absolute;
    font-weight: bold;
    font-size: 18pt;
  }

  .ratingscale {
    width: 600px;
    height: 31px;
    background-color: #e7e6e6;
    margin: 0 auto;
    // margin-left: 15%;
    // margin-top: -40px;
  }

  .ratingscale span {
    font-size: 20px;
    font-weight: 600;
  }

  .overallkey {
    width: 100%;
    height: 45px;
  }

  .overallkey p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-weight: bold;
    width: 100px;
    letter-spacing: 1px;
  }

  .overalldata {
    width: 100px;
    background-color: blue;
    height: 10px;
  }

  .overlay-graph-container .ant-progress-text {
    display: block;
    margin: 0.5rem auto;
  }

  .overlay-graph {
    min-width: 107px;
    text-align: center;
  }

  .overallkey-container {
    width: 100%;
    background-color: #ffffff;
    height: 40px;
    border-bottom: 1px dashed #000000;
    margin-top: 2px;
  }

  .overallkey-container p {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 12pt;
    font-weight: bold;
    width: 100px;
    word-wrap: break-word;
    line-height: 17px;
  }

  // .overallkeyrespodents {
  //   display: flex;
  //   // padding-top: 50px;
  //   margin: 0 -1rem;
  //   margin-left: 30%;
  //   justify-content: start;
  // }

  .overallkeyrespodents1, .overallkeyrespodents {
    // display: flex;
    // // padding-top: 50px;
    // // margin-left: 28%;
    // margin-left: 20%;
    // justify-content: space-evenly;

    display: flex;
    // margin-right: 10%;
    margin: 0 -1rem;
    // justify-content: start;
    // margin-left: 30%;
    justify-content: space-between;
    margin-left: 25%;
    // margin-right: 9%;
    margin-right: 0%;
  }
  // .overallkeyrespodents1:last-child {
  //   margin-right: 5rem !important;
  // }

  .circleoverallkey {
    width: 15px;
    height: 15px;
    background-color: #008174;
    border-radius: 50px;
    margin-left: 15px;
  }

  .circleoverallkey1 {
    width: 14px;
    height: 15px;
    border-radius: 50%;
    margin-left: 18px;
    margin-right: 3px;
    align-items: center;
    margin-top: 5px;
  }

  .overallkeyrespodents span {
    margin-left: 23px;
    margin-top: -5px;
    color: #5f6262;
    // margin-right: 50px;
  }

  .underboarder {
    border-bottom: 1px dotted #101010;
    margin-top: 1%;
    margin-bottom: 1%;
    // width: 750px;
    // width: 100%;
    // margin-left: 4%;
  }

  .headerboarder {
    width: 100%;
    height: 30px;
    background-color: #008174;
    margin-top: 1%;
  }

  .headerboarder span {
    color: #ffffff;
    font-size: 12pt;
    font-weight: bold;
    margin-left: 22px;
    letter-spacing: 2px;
  }

  .detailed {
    border-right: 2px dotted black;
  }

  .detailed-container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .detailed-container span {
    font-size: 12pt;
    font-weight: bold;
    line-height: 1px;
  }

  .overlay-graph-detailedresult {
    min-width: 85px;
    text-align: center;
    margin-left: 4px;
  }

  .borderdotted {
    // border-bottom: 2px dotted black;
    // margin-left: 29%;
    // margin-top: 6px;
    // width: 100%;

    border-bottom: 1px dotted black;
    width: 100%;
    height: 14px;
  }

  .ratingscale_highest {
    width: 100%;
    height: 25px;
    background-color: #e7e6e6;
  }

  .ratingscale_highest span {
    font-size: 12pt;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .score_container {
    width: 17%;
    font-size: 12pt;
    font-weight: bold;
  }

  .linebar_desc_scorecontainer {
    width: 100%;
  }

  .f_w_500 {
    font-weight: bold;
  }

  .f_s {
    font-size: 12pt;
  }

  .f_s_20 {
    font-size: 12pt;
  }

  .header_pdf {
    right: 0;
    left: 0;
    margin-left: -45%;
  }

  .header_lines {
    // margin: 75px 0 -2px auto;
    color: #0d7e71;
    text-align: center;
    font-size: 15pt;
    position: relative;
    width: 100%;
    font-weight: bold;
  }
  .header_lines:before {
    content: '';
    display: block;
    width: 15%;
    height: 3px;
    background: #0d7e71;
    left: 0;
    top: 50%;
    position: absolute;
  }

  .header_lines:after {
    content: '';
    display: block;
    width: 60%;
    height: 3px;
    background: #008174;
    right: 0;
    top: 50%;
    position: absolute;
    opacity: 91%;
  }

  .flex {
    display: flex;
  }

  .ai-center {
    align-items: center;
  }

  .jc-center {
    justify-content: center;
  }

  .footerpdf {
    width: 100%;
    height: 55px;
    background-color: #008174;
    opacity: 91%;
    margin-bottom: 0;
    // margin-left: -5px;

    // box-shadow: 1px 0px 6px 0px #d5d2d2;
  }

  .footernumber {
    position: absolute;
    color: #ffffff;
    right: 2%;
    top: 15%;
    // margin-top: -47px;
    font-size: 16pt;
    font-weight: bold;
  }

  .webviewer {
    height: 100vh;
  }

  .canvas {
    width: 506px !important;
    height: 506px !important;
  }

  .pieThreeWrap {
    width: 490px;
    height: 490px;
    position: relative;
    margin: 0 auto;

    .recharts-wrapper {
      padding-left: 0 !important;
    }
    .percent-pi {
      background: #f1f1f1;
      width: 215px;
      height: 215px;
      left: 51% !important;
      top: 51% !important;
      transform: translate(-50%, -50%);
      margin: 0 !important;
      border-radius: 50%;

      .brdr {
        position: absolute;
        width: 268px;
        height: 268px;
        background: rgba(0, 0, 0, 0.6);
        border: 5px solid #00000000;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.6;
      }
      .value {
        position: absolute;
        width: 214px;
        height: 214px;
        background: #fff;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .pieTags {
    justify-content: center !important;

    .pieTagsItem {
      width: 40%;
      max-width: 40%;
      flex: 0 0 40%;
      align-items: center;
      margin-top: 5px;
    }
    &.pieTagsTop {
      margin-top: -80px;
    }
    &.pieTagsBottom {
      margin-top: -36px;
    }
    .text-right {
      text-align: right !important;
    }
  }

  .piecircleborder {
    position: absolute;

    .circlePie {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: black;
      position: relative;
    }

    .v_border {
      position: absolute;
      border-left: 2px dotted #a09d9b;
      height: 109px;
      position: relative;
    }
    .h_border {
      position: absolute;
      border-bottom: 2px dotted #a09d9b;
      width: 84px;
      position: relative;
    }
  }

  .piecircleborderleft {
    position: absolute;

    .circlePie {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      position: relative;
    }

    .v_border {
      position: absolute;
      border-left: 2px dotted #a09d9b;
      height: 109px;
      position: relative;
    }
    .h_border {
      position: absolute;
      border-bottom: 2px dotted #a09d9b;
      width: 62px;
      position: relative;
    }
  }

  .middle-line-indecator{
    width: 2px !important;
    height: 25px !important;
    margin-top: -6% !important;
  }

  .middle-line-indecator-upper-side{
    width: 2px !important;
    // height: 20px !important;
    margin-top: 1% !important;
  }
  .middle-line-indecator-upper-long-side{
    height: 200px !important;
    margin-top: 2% !important;

  }

  .ant-progress-circle .ant-progress-inner {
    // margin-left: 36px;
    width: 90px !important;
    height: 90px !important;
    font-size: 24px;
  }
  .ant-progress-inner {
    background-color: #e5e5e5 !important;
  }

  .circle_positivity {
    width: 15px;
    height: 15px;
    border-radius: 50%;
  }

  

  .overlay-graph .ant-progress-line {
    margin-top: 7px;
  }

  .overlay-graph .ant-progress-bg {
    height: 14px !important;
  }

  .overlay-graph-detailedresult .ant-progress-bg {
    height: 16px !important;
  }

  .overlay-graph-detailedresult .ant-progress-show-info .ant-progress-outer {
    margin-right: calc(-2em - 8px);
    padding-right: calc(2em - 15px);
  }

  .border-none {
    border: none !important;
  }

  .prograss_box .ant-progress-inner {
    background-color: #ffffff !important;
  }

  .progress_bar .ant-progress-inner {
    background-color: #ffffff !important;
  }

  .linebar_desc .ant-progress-line {
    margin-top: 4px;
  }
  .overlay-graph .ant-progress {
    line-height: 1.1;
  }
  .ant-progress-circle .ant-progress-text{
    font-size: 0.7em;
  }
}

@media only screen and (min-width: 1920px) {
  .chart-container {
    position: absolute !important;
    height: 14vh !important;
    width: 22vw !important;
    margin-top: -440px !important;
    margin-left: 107px !important;

    .canvas {
      margin-top: 100% !important;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .pdf_on .canvas_div_pdf {
    width: 43%;
    margin: 0 auto;
    background-color: #ffffff;
  }

  .pdf_on .root {
    width: 100% !important;
    background-color: #dddbdb !important;
  }
  // .page{
  //   width: 100% !important;

  // }

  // .recharts-wrapper{
  //   margin-top: -27% !important;
  //   width: 29vh !important;
  // }
}

  // .html2pdf__page-break{ 
  //   page-break-after: column;
  // }
