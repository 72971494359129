.project {
    padding: 0 1.2em 0 0;
    width: 100%;
    .dropdown-toggle::after{
        content: " " !important;
        display: none !important;
    }
    
  }
  .Project-Container {
    width: 100%;
    text-align: left;
    // height: 81vh;
    border: 1px solid #e5e5e5;
    padding: 0.8rem;
    .allproject-dropdown{
        padding: 0.3rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
        .project-text3{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            text-align: left;
           font: normal normal normal 14px/17px Calibri Regular;
            letter-spacing: 0px;
            color: #001737;
            opacity: 1;
        }
        .arrow_down{
            width: 0.6rem !important;
            margin-left: 0.7rem;
        }
    }
    
    .project-text1{
        // // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 15px/38px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
        font: normal normal bold 15px/38px Calibri Regular;
        letter-spacing: 0px;
        color: #212529;
        opacity: 1;
        line-height: 1.3rem;
    }
    .project-text2{
        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 13px/14px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
       font: normal normal normal 13px/14px Calibri Regular;
        letter-spacing: 0px;
        color: #212529;
        opacity: 1;
        // line-height: 0.3rem;
    }
  }
 
  .active {
    background-color: #bef8d6 !important;
    border: none;
    border-radius: 5px;
    padding: 0.5em;
    color: #00bc5d;
    font-weight: bold;
  }
  .close {
    background-color: #ffcbce !important;
    border: none;
    border-radius: 5px;
    padding: 0.5em;
    color: #be2b3f;
    font-weight: bold;
  }
  
  .green {
    color: #00bc5d;
  }
  
.link_text{
  text-decoration: underline !important;
}

.m-inner-2{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  // margin-bottom: 1rem;
  overflow-y: scroll;
  height: 48vh;
  // ant table style
  .ant-table-tbody tr{
    box-shadow: none;
  }
  .ant-table table {
    border-spacing: 0 !important;
  }
  .ant-table-tbody > tr > td{
    padding: 0px 16px;
    .dropdown-menu{
      transform: translate(-37px, 25px) !important;
    }
  }
  .ant-table-tbody tr{
    height: 30px !important;
    opacity: 1;
  }
  .table-row-light {
    background-color: #ffffff;
  }
  .table-row-dark {
      background-color: #EDECEC ;
      opacity: 0.25;
  }
  .active_label{
    background: #C8F1D7 0% 0% no-repeat padding-box;    
    color: #32AC5F;
    border-radius: 4px;
    padding: 5px 5px;
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }
  .deactive_label{
    // background-color: #FFCFCF ;
    background: #FFCFCF 0% 0% no-repeat padding-box;    
    border-radius: 4px;
    padding: 5px 5px;
    width: 100%;
    text-align: center;
    font-weight: bolder;
    letter-spacing: 0px;
    color: #AC3232;
    margin-top: 8px;
    opacity: 1;
  }
  //custom table style over
  .m-col-2{
      width:12% !important;
  }
  .m-col-1{
      width:15% !important;
  }
  .m-text-head{
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-696867);
    text-align: left;
   font: normal normal bold 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #696867;
    opacity: 1;
  }
}
.list-middle-wrapper{
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
  height: 39vh;
  // max-height: 40vh;
  // align-items: center;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsl(0, 0%, 88%);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #008174;
  border-radius:10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(#008174,0.5);
}
// mobile
@media only screen and (min-width: 320px) and (max-width: 767px)  { 
  .project{
    padding: 0;
    .list-middle-wrapper{
      overflow-x: scroll;
    }
    .m-inner-2{ 
      .ant-table-tbody > tr > td .dropdown-menu {
        transform: translate(-26px, 22px) !important;
      }
    }
  }
}
// ipad 
@media only screen and (min-width: 768px) and (max-width: 1023px)  { 
  .project{
    padding: 0;
    .list-middle-wrapper{
      overflow-x: scroll;
    }
    .m-inner-2{ 
      .ant-table-tbody > tr > td .dropdown-menu {
        transform: translate(-31px, 22px) !important;
      }
      .active_label {
        background: #C8F1D7 0% 0% no-repeat padding-box;
        color: #32AC5F;
        border-radius: 4px;
        padding: 1px 3px;
        width: 100%;
        text-align: center;
      }
      .deactive_label {
        padding: 1px 3px;
        width: 100%;
        text-align: center;
      }
    }
  }
}
// ipad pro
@media only screen and (min-width: 1024px) and (max-width: 1365px)  { 

  .m-inner-2 .ant-table-tbody > tr > td {
    padding: 0px 37px;
    .active_label, .deactive_label{
      padding:5px 5px;
      width: 100%;
      text-align: center;
    }
  }
}