.outer-container {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: center;
  background-color: #edecec;
  .inner-section-left {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    height: 100%;
    .left-img {
      width: 75%;
      //    margin: 10px;
    }
    .left-text1 {
      font-size: 1.5rem;
      font-family: Calibri Regular;
      font-weight: 600;
      color: #696867;
      text-align: center;
      width: 55%;
      margin-top: 30px;
      margin-bottom: 10px;
      line-height: 1.8rem;
    }
    .left-line {
      width: 50%;
    }
    .left-text2 {
      font-size: 1rem;
      font-family: Calibri Regular;
      font-weight: 500;
      text-align: center;
      width: 40%;
      color: #696867;
      line-height: 1.2rem;
    }
  }
  .inner-section-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    background-color: #fff !important;
    .right-upper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 8%;
      .rightLogo {
        width: 80%;
        margin-bottom: 3rem;
      }
      .lower-text1 {
        font-family: Calibri Regular;
        font-size: 1.75rem;
        font-weight: 600;
        color: black;
      }
      .lower-text2 {
        font-family: Calibri Regular;
        font-size: 1.5rem;
        font-weight: 500;
        color: gray;
        line-height: 1.5rem;
      }
      .inquiry-checkbox {
        margin-top: 2rem;
        align-items: center;
        .check-text {
          //  position: absolute;
          font-family: Calibri Regular;
          font-size: 0.9rem;
          font-weight: 600;
          color: #696867;
          margin-top: -8px;
        }
      }
      .inquiry-submit {
        width: 90%;
        height: 3rem;
        background: #008174;
        color: #fff;
        font-family: Calibri Regular;
        font-weight: 500;
        font-size: 1.3rem;
        margin-top: 2rem;
        box-shadow: 0px 7px 22px #0081744d;
      }
      .switch-login-container {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        span {
          font-size: 1rem;
          font-weight: 500;
        }
        a {
          margin-left: 18px;
        }
      }
    }
    .right-lower {
      margin-bottom: 0px !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 92%;
      border-top: 1px solid #edecec;
      padding-top: 10px;
      padding-bottom: 10px;
      .footer-left {
        span {
          font-family: Calibri Regular;
          font-size: 0.7rem;
          font-weight: 500;
        }
      }
      .footer-right {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 35%;
        .right-text-1 {
          font-family: Calibri Regular;
          font-size: 0.7rem;
          font-weight: 500;
        }
        .right-text-2 {
          font-family: Calibri Regular;
          font-size: 0.7rem;
          font-weight: 500;
        }
        .right-text-3 {
          font-family: Calibri Regular;
          font-size: 0.7rem;
          font-weight: 500;
        }
      }
    }
  }
  .ant-input-affix-wrapper {
    padding: 0;
    border: none !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .ant-input {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    height: 50px !important;
    width: 100%;
    background-color: #edecec !important;
    border-radius: 8px;
    padding-left: 65px !important;
  }
  .textareamsg {
    height: 107px !important;
    width: 90%;
    margin-top: 4%;
  }
  .ant-checkbox-input {
    //   width: 40px     !important;
    //   height: 40px !important;
  }
  .ant-checkbox-inner {
    width: 30px !important;
    height: 30px !important;
    background: #edecec;
    border: none !important;
  }
  .ant-checkbox-inner::after {
    left: 32% !important;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background: #008174 !important;
    }
  }
}
.input_container {
  width: 90%;
  margin-top: 20px;
  // height: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .outer-container {
    flex-direction: column;
    .inner-section-left {
      display: none;
    }
    .inner-section-right {
      width: 100%;
      .right-upper {
        align-items: center;
      }
    }
  }
}
