.employee_form{
.survey-form{
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: space-between;
    // width: 450px;
    padding: 0.8rem;
    .upper-form{
        margin-bottom: 1rem;
        .img-container{
            display:flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 1.3rem;
            .upper-img{
                width: 4rem;
                height: 4rem;
                border-radius: 50%;
                margin: 2rem;
            }
        }
        .survey-radio-container{
            display:flex;
            flex-direction: row;
            width: 100% !important;
            .survey-radio-item{
                display:flex;
                flex-direction: column;
                background: transparent !important;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: 0px !important;
                outline: 0 !important;
                border-bottom: 0.3rem solid transparent !important;
                border-radius: 0 !important;
                &:focus{
                    border-bottom: 0.3rem solid #008174 !important;
                    .survey-radio-title{
                        color: #000;
                    }
                }
                .survey-radio-title{
                    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 15px/38px var(--unnamed-font-family-calibri);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    text-align: left;
                    font: normal normal bold 15px/38px Calibri Regular;
                    letter-spacing: 0px;
                    color: #A09D9B;
                    opacity: 1;
                    // margin: 0.5rem;
                    // margin-top: 1rem !important;
                }
            }
        }
        .survey-stats-line{
            margin: 0 !important;
            width: 96% !important;
        } 
    }
     
}.btn:focus {
        outline: none !important;
        box-shadow: none !important;
        transition: none !important;
    }
.form-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.form-row{
    display: flex;
    flex-direction: row;    
}
.form-group{
    margin-top: 0.7rem;
}
.form-label{
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal bold 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 0.3rem !important;
    
}
.form-control{
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal normal 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
}
.form-select{
    font-family: Calibri Regular;
    font-size: 1rem;
    color: rgb(172, 172, 172);
}
.asterik{
    font-family: Calibri Regular;
    font-weight: 600;
    font-size: 0.8rem;
    color: red;  
}

.button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 1.9rem;
    .cancelButton{
        border: 1px solid black;
        width: 20%;
        font-family: Calibri Regular;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem;
        height: 40px;
        background: transparent !important;
        color: #000 !important;
        &:hover{
            border: 1px solid black;
        }
        
    }
    .saveButton{
        margin-left: 10px;
        background-color: #008174;
        color: #fff;
        width: 20%;
        height: 40px;
        font-family: Calibri Regular;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.5rem;
        &:hover{
            color: #fff;
            background: #008174;
        }
    }
}
.survey-note-wrapper{
    display: flex;
    flex-direction: column;
    background: var(--unnamed-color-edecec) 0% 0% no-repeat padding-box;
    background: #EDECEC 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    padding: 0.5rem;
    margin-top: 2.5rem;
    .survey-note-title{
        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/15px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
       font: normal normal bold 14px/15px Calibri Regular;
        letter-spacing: 0px;
        color: #000000;
    }
    .survey-note-desc{
        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/15px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
       font: normal normal normal 14px/15px Calibri Regular;
        letter-spacing: 0px;
        color: #000000;
    }
}
.survey-checkbox{
    display: flex;
    flex-direction: row;
    justify-content:center;
    align-items: center;
}
.reminder-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0.5rem ;
    .survey-checkbox{
        // padding-bottom: 1rem;
    }
    .reminder-text{
        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
       font: normal normal bold 14px/17px Calibri Regular;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        // line-height: 3rem !important;
        // padding-top: 0.5rem !important;
    }
    .reminder-img{
        width: 0.8rem;
        margin: 0.3rem;
        // margin-top: 0.5rem !important;
    }
    .ant-checkbox-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center !important;
        justify-content: center !important;
        
    }
    // .ant-checkbox-inner{
    //     width: 15px !important;
    //     height: 15px !important;
    //     background: #ffffff;      
    //     border: 1px solid #A1ACBD;
    //     border-radius: 2px;
    //     opacity: 1;
    //   }
      .ant-checkbox-input{
        margin: 0 auto !important;
    }
    .ant-checkbox{
        margin-bottom: 0.3rem;
    
    }
}
.bulk-upload-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .file-upload-container{
        display: flex;
        flex-direction:column;
        align-items: center;
        background: #F3FFFE 0% 0% no-repeat padding-box;
        border: 2px dashed #C5D1E2;
        opacity: 1;
        .file-upload-img{
            width: 4rem;
            margin-top: 1rem;
        }
        .file-upload-msg{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: center;
            font: normal normal normal 14px/17px Calibri Regular;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            padding: 1rem;
        }
        .or-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .or-span{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 15px/18px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: center;
               font: normal normal normal 15px/18px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
                text-transform: uppercase;
                opacity: 1;
                padding: 1rem;
            }
            .or-line{
                width: 3rem;
                border: 1px solid #93A9C3;
                height: 0px !important;
            }
        }
        .browse-btn{
            border: 1px solid var(--unnamed-color-696867);
            background: transparent !important;
            border: 1px solid #696867;
            border-radius: 3px;
            margin: 1rem;
            opacity: 1;
            .btn-text{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
               font: normal normal bold 14px/17px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
        }
    }
    .bulk-reminder-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 0.4rem ;
        margin-top: 1rem;
        .survey-checkbox{
            // padding-bottom: 1rem;
        }
        .reminder-text{
            // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-000000);
            text-align: left;
           font: normal normal bold 14px/17px Calibri Regular;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            // line-height: 3rem !important;
            // padding-top: 0.5rem !important;
        }
        .reminder-img{
            width: 0.8rem;
            margin: 0.3rem;
            // margin-top: 0.5rem !important;
        }
        .ant-checkbox-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center !important;
            justify-content: center !important;
            
        }
        // .ant-checkbox-inner{
        //     width: 15px !important;
        //     height: 15px !important;
        //     background: #ffffff;      
        //     border: 1px solid #A1ACBD;
        //     border-radius: 2px;
        //     opacity: 1;
        //   }
          .ant-checkbox-input{
            margin: 0 auto !important;
        }
        .ant-checkbox{
            margin-bottom: 0.3rem;
        
        }
    }
}
}

@media only screen and (min-width: 320px) and (max-width: 767px)  { 

    .reminder-wrapper{
       padding-left: 20px !important;
       display: flex !important;
    }
}