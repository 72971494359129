@font-face {
  font-family: Calibri RegularBold;
  src: url('../../../assets/font/Calibri Bold/Calibri Bold.TTF');
}
// @font-face {
//   font-family: Arial, Helvetica, sans-serif;
//   src: url('../../../assets/font/Arial, Helvetica, sans-serif/Calibri Light.ttf');
// }
//   .item {
//     position: relative;
//     float: left;
// }

// .item h2 {
//     text-align:center;
//     position: absolute;
//     line-height: 125px;
//     width: 100%;
// }



@-webkit-keyframes html {
to {
  stroke-dashoffset: 80; /* 50% would be 220 (half the initial value specified above) */
}
}

@keyframes html {
to {
  stroke-dashoffset: 80;
}
}

@-webkit-keyframes css {
to {
  stroke-dashoffset: 160;
}
}

@keyframes css {
to {
  stroke-dashoffset: 160;
}
}
  // *{
  //     overflow-wrap: anywhere;
  // }
  .pdfPageHeader{
      background-color: #008174;
      // height: 50px;
      // position: sticky;
      width: 100%;
      margin-bottom: 2%;
      box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.75);
      -webkit-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.75);
  }
  .downloadBtn{
      background-color: white;
      border: none;
      float: right;
      margin: 1% 2% 1% 2%;
      border-radius: 4px;
      box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.75);
      -webkit-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: 5px 4px 5px 0px rgba(0,0,0,0.75);
  }
.feedback_number {
    border-radius: 50%;
    background-color: #ffffff;
    width: 27px;
    height: 27px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #a09d9b;
    margin-left: 1px;
    margin: auto;
    margin-top: 7% !important;
}

.feedback_number span {
    font-size: 9pt;
}

.feedback {
    display: flex;
    padding: 12px;
    justify-content: space-between;
}

.feedback_boarder {
    // display: flex;
  //   border-right: 1px dotted #a09d9b;
    border-right: 1px dotted #A9A9A9;
    align-items: center;
    // margin-left: 0;
    // height: 90px;
    height: 63px;      
}

.feedback_noboarder {
    border: none;
}

.rating {
    text-align: center;
    margin-right: 10px;
    margin-left: 10px;
    height: 100%;
}

.rating_text {
    margin-bottom: 0.7rem;
    display: block;
  //   word-wrap: break-word;
    width: 100%;
    font-size: 12px;
    color: #5f6262 !important;
    // height: 53%;
    // height: 51px;
    height: 27px;
    // margin-bottom: 12%;
}

#pdf-container {
    padding-top: 5%;
    .page {
        font-family: 'Calibri';
        // width: 45%;
        width: 640pt;
        margin: 0 auto;
        background-color: #fff;
        .pagecontainer {
            padding: 15px;
        }
    }
    .highcharts-credits {
        display: none;
    }
    .font-bold {
        font-weight: bold !important;
    }
    .label {
        font-size: 14px !important;
        margin-left: 7px;
    }
    p {
        color: #5F6262;
        font-size: 15px;
    }
    .html2pdf__page-break{
      margin-top: 2%;
      background-color: #bdbdbd;
      width: 640pt;
      height: 10px;
      margin-bottom: 2%;
    }
}

.chartList {
    font-family: Arial, Helvetica, sans-serif;
    width: 811px;
    margin: 0 auto;
    background-color: #fff;
    .pieThreeWrap {
        width: 490px;
        height: 490px;
        position: relative;
        margin: 0 auto;
        .recharts-wrapper {
            padding-left: 0 !important;
        }
        .percent-pi {
            background: #f1f1f1;
            width: 215px;
            height: 215px;
            left: 51% !important;
            top: 51% !important;
            position: absolute;
            transform: translate(-50%, -50%);
            margin: 0 !important;
            border-radius: 50%;
            .brdr {
                position: absolute;
                width: 268px;
                height: 268px;
                background: rgba(0, 0, 0, 0.6);
                border: 5px solid #00000000;
                border-radius: 50%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0.6;
            }
            .value {
                position: absolute;
                width: 214px;
                height: 214px;
                background: #fff;
                border-radius: 50%;
                top: 50%;
                left: 50%;
              //   font-size: 50pt;
                font-size: 59pt;
                font-weight: bold;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

#coverpage {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    .imgbackground {
        background-color: #129181;
      //   width: 496px;
      //   height: 374px;
        left: -10px;
        top: 85px;
        z-index: 0;
        position: absolute;
        opacity: 0.9;
        width: 55%;
    }
    .imgdescription {
        color: #ffffff;
        margin-left: 20%;
        padding-top: 0px;
      //   font-size: 167px !important;
        font-size: 100pt !important;
        font-weight: 900 !important;
        line-height: 1;
        margin-bottom: 0;
      //   margin-bottom: 0;
    }
    .underline {
        width: 414px;
        height: 6px;
        background-color: #ffffff;
    }
    .text_img {
        color: #ffffff;
        margin-left: 20%;
      //   padding-top: 4%;
      //   padding-top: 10px;
      padding: 5% 0;
        font-size: 19pt;
        margin-bottom: 0;
        font-weight: 900;
        // letter-spacing: 1px;
        text-transform: uppercase;
        .date {
            text-transform: capitalize;
        }
    }
    .lines {
        margin-top: 10px;
        background-color: #119182;
        width: 850px;
        height: 10px;
        margin-left: -6px;
    }
    .logo {
        padding: 60px 0px 0 170px;
    }
}

#background360 {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    margin-right: 4%;
  //  margin-left: 10%;
  margin-left: 6%;
    margin-top: 0%;
    margin-bottom: 0%;
  //   padding-top: 15%;
  //   padding-bottom: 8%;
    .feedback_number {
        color: #5F6262;
    }
    .rating {
        height: 0;
    }
    .rating_text {
        height: 38px;
        padding: 0 10px;
    }
    .text p {
        color: #5F6262 !important;
        text-align: justify;
        // font-size: 14px !important;
    }
    .text h1 {
        color: #5F6262 !important;
    }
    .respodents {
        display: flex;
        // padding-top: 50px;
        padding-top: 15px;
        margin-left: 45px;
        // margin-bottom: 45px;
        margin-bottom: 15px;
        flex-flow: wrap;
        span {
            font-size: 14px;
            padding-top: 2px;
        }
    }
    .circle {
        width: 16px;
        height: 16px;
        margin-top: 5px;
        border-radius: 50%;
        margin-left: 10px;
    }
    .raters {
        font-size: 13pt;
        color: #212529e3;
        font-weight: bold;
    }
    .feedback_number {
        margin-top: 20px !important;
    }
}

#keyfocusarea360 {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    margin-right: 0%;
  /*  margin-left: 10%;*/
    margin-left: 6%;
    margin-top: 0%;
    margin-bottom: 0%;
  /*   padding-top: 15%;
    padding-bottom: 7%;*/
    .pagecontainer {
        p {
            font-size: 14px !important;
            color: #5F6262 !important;
            padding: 0 10px !important;
        }
    }
    .piecircleborderleft {
        position: absolute;
        .circlePie {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            position: relative;
        }
        .v_border {
            position: absolute;
            border-left: 2px dotted #a09d9b;
            height: 109px;
            position: relative;
        }
        .h_border {
            position: absolute;
            border-bottom: 2px dotted #a09d9b;
            width: 62px;
            position: relative;
        }
    }
    .middle-line-indecator {
        width: 2px !important;
        height: 25px !important;
        margin-top: -6% !important;
    }
    .middle-line-indecator-upper-side {
        width: 2px !important;
        // height: 20px !important;
        margin-top: 1% !important;
    }
    .middle-line-indecator-upper-long-side {
        height: 200px !important;
        margin-top: 2% !important;
    }
}

#positive-index {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
   
    margin-right: 0%;
    margin-left: 6%;
    margin-top: 0%;
    margin-bottom: 0%;
  //   padding-top: 15%;
  //   padding-bottom: 7%;
    .label {
        font-size: 11pt !important;
    }
    .percent-pi {
        position: absolute;
        top: 45%;
        left: 36% !important;
        font-size: 4rem;
        font-weight: 400;
    }
    .positivity {
        margin-top: 0;
    }
    .positivitycard {
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 87%;
        border-radius: 50%;
        // margin-bottom: 45px;
        // margin-bottom: 20px;
    }
    .positivitycard-chart {
        margin-bottom: 0;
        width: 46%;
        height: 69%;
        background-color: #FFFFFF;
    }
    .positivitycard-chart-odd {
        margin-bottom: 0;
        width: 20%;
        height: 46%;
        background-color: #FFFFFF;
    }
    .positivity_discription {
        line-height: 2;
        align-items: center;
    }
    .positivity_discription span {
        font-weight: lighter;
        font-size: 12pt;
    }
    .cardBody h5 {
        font-size: 12pt;
        font-weight: bold;
        text-align: center;
        font-size: 1.3rem;
        // padding-left: 30%;
        // letter-spacing: 3px;
    }
    .doughnut-container {
        position: relative;
      //   min-height: 160px;
        background-color: transparent;
        text-align: center;
    }
    .doughnut-container img{
      // top: 50%;
      // left: 50%;
    //   transform: translate(-50%, -50%);
      // transform: translate(-52%, -235%);
    }
    .doughnut-container span {
        position: absolute;
        top: 50%;
        left: 50%;
      //   transform: translate(-50%, -50%);
      //   transform: translate(-52%, -235%);
    }
    .doughnut-container span {
      font-weight: 800;
      /* font-size: 11pt; */
      position: absolute;
      left: 3%;
      right: 0;
      top: 48%;
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
      overflow: auto;

      // position: absolute;
      // z-index: 10;
      // top: 45% !important;
      // left: 42%;
      // font-weight: 800;
      // font-size: 11pt;
    }
    .circle_positivity {
        width: 23px;
        height: 23px;
        border-radius: 50%;
    }
}

.overall-data-title {
    font-size: 12pt !important;
   
}

#overview360 {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    margin-right: 0%;
    margin-left: 6%;
    margin-top: 0%;
    margin-bottom: 0%;
  //   padding-top: 15%;
  //   padding-bottom: 7%;
    .rating_text {
        height: 35px;
        padding: 0 10px;
    }
    .ratingscale {
        width: 600px;
        height: 31px;
        background-color: #e7e6e6;
        margin: 0 auto;
        // margin-left: 15%;
        // margin-top: -40px;
    }
    .ratingscale span {
        font-size: 18px;
       
    }
}

#overalldata, #overalldata-1, #overalldata-2 {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    margin-right: 5%;
    margin-left: 6%;
    margin-top: 0%;
    margin-bottom: 0%;

    .custom-width {
        width: 25%;
        margin-left: -2% !important;
        align-items: center !important;
    }

    .overallkeyrespodents {
        justify-content: space-between;
        margin-left: 32%;
        margin-right: -8%;
        padding-bottom: 7px;
        margin-bottom: 5px;
    }
    .overallkeyrespodents span {
        margin-left: 23px;
        margin-top: -5px;
        color: #5f6262;
        // margin-right: 50px;
    }
    .circleoverallkey {
        width: 12px;
        height: 12px;
        background-color: #008174;
        border-radius: 50px;
    }
    .overall-text {
       
    }

    .linebaroveralldata span {
        width: max-content;
        // transform: rotate(270deg);
        // -webkit-transform: rotate(270deg);
        // backface-visibility: hidden;
        // -webkit-backface-visibility: hidden;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        // min-width: 183px;
        min-width: 40px;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        z-index: 9 !important;
        opacity: 1 !important;
        word-break: break-word;
        // letter-spacing: 1px;
        // word-break: break-word;
        // // word-spacing: 10px;
        // word-wrap: break-word;
    }
    .linebaroveralldata span.small {
        // font-size: 16px!important;
        padding: 2px 5px!important;
    }
    .overallkey {
        width: 100%;
      //   height: 45px;
      height: auto;
    }
    .overallkey p {
        margin-bottom: 0px;
        margin-left: 13px;
        font-weight: bold;
        width: 100px;
        // letter-spacing: 1px;
    }
    .overlay-graph-container .ant-progress-text {
        display: block;
        margin: 0.5rem auto;
    }
    .overlay-graph-detailedresult {
        width: 85px;
    }
    .overallkey-container {
        width: 100%;
        background-color: #ffffff;
        /*height: 40px;*/
        /* height: 37px; */
        border-bottom: 1px dotted #A9A9A9;
      //   margin-top: 2px;
      span{
          margin-left: 10px;
      }
    }
    .overallkey-container p {
        margin-bottom: 0px;
        margin-left: 10px;
        font-size: 11pt !important;
        font-weight: 800 !important;
        word-wrap: break-word;
        line-height: 18px;
    }
    .border-none {
        border: none !important;
    }
    .overlay-graph {
        min-width: 107px;
        text-align: center;
    }
}

#detailed-result-0, #detailed-result-1-0, #detailed-result-2-0 {
    margin-top: 0px;
}

#detailed-result,
div[id^=detailed-result], div[id^=detailed-result-1], div[id^=detailed-result-2] {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
      // margin-top: -20px !important;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    .border {
        border-bottom: 1px dotted !important;
        margin-bottom: 20px;
    }
    .custom-width {
        width: 100px;
    }
    .overallkeyrespodents1,
    .overallkeyrespodents {
        justify-content: space-between;
        margin-left: 30%;
        margin-right: -3%;
        padding-bottom: 7px;
        margin-bottom: 5px;
    }
    .overallkeyrespodents span {
        margin-left: 23px;
        margin-top: -5px;
        color: #5f6262;
        // margin-right: 50px;
    }
    .circleoverallkey {
        width: 12px;
        height: 12px;
        background-color: #008174;
        border-radius: 50px;
    }
    .circleoverallkey1 {
        width: 14px;
        height: 15px;
        border-radius: 50%;
        margin-left: 18px;
        margin-right: 3px;
        align-items: center;
        margin-top: 5px;
    }
    .headerboarder {
        width: 100%;
      //   height: 30px;
        background-color: #008174;
        margin-top: 1%;

      //   changes 
      padding: 0.5% 0;
    }
    .headerboarder span {
        color: #ffffff;
        font-size: 12pt;
        font-weight: bold;
        margin-left: 22px;
        // letter-spacing: 4px;
      //   word-spacing: 5px;
       
    }
    .detailed-container {
        margin-top: 0;
        margin-bottom: 0;
       
        font-weight: 400;
        .pe-2{
          padding-right: 5px !important;
        }
    }
    .detailed-container span {
        font-weight: bold;
        line-height: 1px;
       
    }
    .borderdotted {
        border-bottom: 1px dotted #A9A9A9;
        width: 100%;
        height: 14px;
    }
    .overlay-graph-detailedresult {
        width: 85px;
        text-align: center;
    }
}

div[id^=detailed-result-1], div[id^=detailed-result-2]{
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
  margin-left: 6%;
  margin-right: 1%;
}

#high-score-page,
#low-score-page {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    margin-right: 0%;
    margin-left: 6%;
    margin-top: 0%;
    margin-bottom: 0%;
  //   padding-top: 15%;
  //   padding-bottom: 15%;
    .ratingscale_highest {
        width: 97%;
        height: 25px;
        // padding: 0.5% 0;
        background-color: #e7e6e6;
        margin-left: 40px;
        margin: 6px 26px;
    }
    .ratingscale_highest span {
        font-size: 12pt;
        font-weight: bold;
       
        // letter-spacing: 2px;
    }
    .score_container {
        width: 17%;
        font-size: 12pt;
        font-weight: bold;
       
        span {
            // letter-spacing: 1px;
        }
    }
    .linebar_desc_scorecontainer {
        width: 100%;
    }
    .f_w_500 {
        font-weight: bold;
    }
    .f_s {
        font-size: 12pt;
    }
    .f_s_20 {
        font-size: 10pt;
    }
    .underboarder {
      //   border-bottom: 1px dotted #101010;
        border-bottom: 1px dotted #A9A9A9;
        //   margin-top: 1%;
        //   margin-bottom: 1%;
        width: 92.5%;
        margin-left: 40px;
    }
    .score-width {
        margin-left: 40px;
    }
    .main-squre.mb-3.highlowscore{
      width: 40rem;
      margin-left: 80px;
    }
      
}

#goaloriention, #goaloriention-2 {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    margin-right: 4%;
    margin-left: 8%;
    margin-top: 0%;
    margin-bottom: 0%;
  //   padding-top: 15%;
  //   padding-bottom: 7%;
    width: 715px;
    .pia-title {
      //   font-size: 12px !important;
       
      // //   padding-left: 105px;
      //   padding-left: 37%;
      //   margin-bottom: 27px;
      //   width: 218px;
      //   padding-top: 22px;
      //   text-align: left !important;

        font-size: 12px !important;
        // padding-left: 50%;
        margin-bottom: 27px;
        width: 218px;
        padding-top: 22px;
        text-align: left !important;
    }
    .main-squre {
      //   padding: 15px 25px;
       padding: 14px 1px;
       width: 40rem;
       margin-left: 16px;
    }
    .horizantal-border {
      //   border-left: 2px dotted #cacaca;
        border-left: 1px dotted #A9A9A9;
    }
    .custom-circle {
        border: 1px solid #a09d9b;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        padding-top: 2px;
        font-size: 12px !important;
    }
    .custom-text {
      //   display: flex;
        font-size: 12px;
       
        color: #5F6262;
      //   align-items: flex-start;
      //   justify-content: space-evenly;
      //   flex-direction: column-reverse;
    }
    .feedback_boarder {
      //   border-right: 1px dotted #a09d9b;
        border-right: 1px dotted #A9A9A9;
        align-items: center;
        height: auto;
    }
    .rating {
        height: 0px;
    }
    .rating_text {
        display: inline-block;
        float: right;
        height: 0px;
        width: 64px;
        margin-left: 25px;
    }
    .feedback_number {
        display: inline-block;
        float: left;
    }
    .goaltiteldesc {
        font-size: 15px !important;
        color: #5F6262;
    }
    .progress_oriantation {
        display: flex;
        justify-content: center;
        padding: 0;
      //   border-bottom: 2px dotted rgb(202, 202, 202);
        border-bottom:  1px dotted #A9A9A9;
        width: 100%;
      //   width: min-content;
    }
    .prograss_box {
      //   border-right: 2px dotted rgb(202, 202, 202);
        border-right: 1px dotted #A9A9A9;
        //   margin-left: 0;
        //   padding-right: 20px;
      //   margin-left: 13px;
      //   padding-right: 13px;
      //   margin-right: 14px;
      margin-left: 10px;
      padding-right: 10px;
      width: min-content;
        p {
          //   width: 218px;
            font-size: 15px;
           
        }
    }
    .prograss_box_no_border {
        border-right-color: none;
        margin-left: 10px;
        width: min-content;
    }
    .linebar {
        width: 100%;
        // height: 180px;
      //   height: auto;
        color: white;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // min-height: 36px;
      //   padding-left: 4px;
        // margin: 13px 0;
        margin: 13px 0 20px 0;
        padding: 0.5% 0 0.5% 4px
    }
    .linebar span {
        width: max-content;
        font-size: 12pt;
        font-weight: 700;
        // line-height: 21px;
        text-transform: uppercase;
        min-width: 40px;
        text-align: center;
        color: #FFFFFF;
        position: relative;
        z-index: 9 !important;
        opacity: 1 !important;
        word-break: break-word;
       
        // letter-spacing: 2px;
    }
    .linebar_desc {
        // width: calc(100% - 100px) !important;
        width: -webkit-fill-available;
      //   padding-left: 25px;
      margin-left: 16px;
    }
    .ant-progress-circle .ant-progress-inner {
        // margin-left: 36px;
        width: 90px !important;
        height: 90px !important;
        font-size: 24px;
    }
    .bottomline {
        height: 5px;
      //   margin-top: 24%;
        // margin-top: 15%;  
        // margin-top: 30%;  
    }
    .goalChart {
        height: 0px !important;
        width: 150px !important;
        span {
            position: absolute;
            top: 35px;
            font-size: 17pt;
            left: 30px;
            z-index: 10;
        }
        canvas {
            height: 150px !important;
            width: 150px !important;
        }
    }
    .goal-chart-img {
        z-index: 2;
        top: 10;
        position: absolute;
        background: #FFF;
        border: none;
        left: -5px;
        width: 100px;
        // display: none;
    }
}

#additionalfeedback {
  h1,
  h2,
  h3 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 21pt;
  }

  p, span, div {
    font-family: Arial, Helvetica, sans-serif;
  }
    margin-right: 0%;
    margin-left: 6%;
  //   margin-top: -7%;
    // margin-top: -35%;
    // padding-top: 15%;
    // padding-bottom: 7%;
    .space {
        margin-top: 1%;
        font-size: 14px !important;
        color: #5F6262 !important;
    }
    .space b {
        font-size: 12pt;
        color: #212529e3;
    }
    p{
      font-size: 10.5pt;
    }
}

// style for to get equal space between rows 
.flex-container{
  display: flex;
flex-wrap: nowrap;
//   background-color: DodgerBlue;
flex-direction: column;
height: 1000px;
justify-content: space-evenly;
}
// style for to get equal space between rows 

.flex-container >.partitionClass{
  width: auto;
  height: auto;
  // margin: 10px;
  text-align: center;
  display: flex;
  // border-bottom:1px dotted #A9A9A9;
}

.col-4.headerPart {
text-align: center;
}
// .col-4.headerPart.horinontal {
//   border-left: 1px solid #c1c1bf;
//   border-right: 1px solid #c1c1bf;
// }
