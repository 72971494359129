// @font-face {
//   font-family: 'Calibri Regular';
//   src: url('../../../assets/font/Calibri\ Regular/Calibri Regular.ttf');
//   src: local('Calibri Regular'),
//     url('../../../assets/font/Calibri\ Regular/Calibri Regular.ttf')
//       format('truetype');
// }
.preview-form{
  p {
    font-family: 'Calibri Regular';
  }
  .form-container {
    width: 50%;
    margin: 0 auto;
  
    .company-logo {
      width: 100px;
      height: 100px;
      // border-radius: 50%;
    }
  }
  .w-15 {
    width: 10%;
  }
  
  .ant-btn {
    &:active,
    &:focus,
    &:hover {
      color: #fff;
      border-color: #fff;
      background: #008c7e;
    }
  }
  
  .custom-buttons {
    .form-check {
      margin: 10px;
      position: relative;
      padding: 0;
    }
  
    .form-label {
      b {
        line-height: 2.5em;
      }
    }
  
    .form-check {
      input[type='radio'],
      input[type='checkbox'] {
        opacity: 0;
        position: absolute;
        width: 100%;
        border-radius: 1px;
        height: 100%;
        margin: 0;
        cursor: pointer;
      }
  
      label:hover {
        background-color: #dfd;
      }
  
      label {
        display: inline-block;
        background-color: #f1f1f1;
        padding: 10px 20px;
        font-size: 16px;
        border-radius: 4px;
        margin: 0;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      input[type='radio']:checked + label {
        background-color: #008c7e;
        border-color: #008c7e;
        color: #fff;
      }
      input[type='checkbox']:checked + label {
        background-color: #008c7e;
        border-color: #008c7e;
        color: #fff;
      }
    }
  
    .h-50px {
      height: 50px;
    }
  }
  
  @media (max-width: 320px) {
    /* smartphones, iPhone, portrait 480x320 phones */
    .user-survey-form {
      .form-container {
        width: 95%;
        margin: 0 auto;
      }
    }
    .ant-drawer-content-wrapper{
      width: 320px !important;
    }
  }
  @media (min-width: 321px) and (max-width: 481px) {
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .user-survey-form {
      .form-container {
        width: 95%;
        margin: 0 auto;
      }
    }
    .ant-drawer-content-wrapper{
      width: 350px !important;
    }
  }
  @media (max-width: 641px) {
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
    .user-survey-form {
      .form-container {
        width: 95%;
        margin: 0 auto;
      }
    }
 
  }
  @media (max-width: 961px) {
    /* tablet, landscape iPad, lo-res laptops ands desktops */
  }
  @media (min-width: 1025px) {
    /* big landscape tablets, laptops, and desktops */
  }
  @media (min-width: 1281px) {
    /* hi-res laptops and desktops */
  }
  
}
