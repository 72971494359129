.dashborad-charts {
  width: 100%;
    .stats__container{
        padding: 1rem !important;
        padding-top: 0rem !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        .statshead{
            display:flex;
            flex-direction: row;
            width: 100% !important;
            
            #active-tab{
              border-bottom: 0.3rem solid #008174 !important;
            }
            #deactive-tab{
              border-bottom: none;
            }
            .statshead-item{
                display:flex;
                flex-direction: column;
                padding: 1rem;
                padding-left: 0rem !important;
                margin-left: 1rem;
                padding-top: 0.7rem;
                padding-bottom: 1.5rem;
                cursor: pointer;
                border: 0px !important;
                outline: 0 !important;
                border-bottom: 0.3rem solid transparent !important;
                border-radius: 0 !important;
                // &:focus{
                //     border-bottom: 0.3rem solid #008174 !important;
                // }
                // &:active{ 
                //   border-bottom: 0.3rem solid #008174 !important;
                // }
                .stats-radio-title{
                    // // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 15px/38px var(--unnamed-font-family-calibri);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    text-align: left;
                   font: normal normal bold 15px/38px Calibri Regular;
                    letter-spacing: 0px;
                    color: #212529;
                    opacity: 1;
                    // line-height:0.5rem;
                }
                
                .stats-radio-desc{
                    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 13px/14px var(--unnamed-font-family-calibri);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    text-align: left;
                   font: normal normal normal 13px/14px Calibri Regular;
                    letter-spacing: 0px;
                    color: #212529;
                    opacity: 1;
                    line-height:0.3rem;
                }
            }
        }
        .stats-line{
            margin: 0 !important;
            width: 96% !important;
        }
    }

  .stats__container {
    width: 100%;
    text-align: left;
    border: 1px solid #e5e5e5;
  }
  .statshead-item p {
    font-size: 10px;
  }
  .dot1 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fedd84;
  }
  
  .dot2 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #febe13;
  }
  
  .foot p {
    color: var(--unnamed-color-000000);
    text-align: left;
    //font: normal normal bold 12px/14px Calibri;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .share {
    text-align: left;
 //   font: normal normal bold 14px/17px Calibri;
    color: #001737;
    opacity: 1;
  }
  
  .mid-text {
    font-weight: 900;
    font-size: 2rem;
  }
  
  .pie {
    position: relative;
  }
  
  .percent {
    position: absolute;
    top: 45%;
    left: 48% !important;
    font-size: 2rem;
    font-weight: 900;
  }
  .btn:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .radio-margin{
    margin-left: 2.2rem !important;
}
.stats-footer-text1{
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/14px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal bold 12px/14px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-left: 0.8rem;
}
.stats-footer-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    padding-bottom: 0rem !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px)  { 
  .dashborad-charts{
    .stats__container{
      .statshead{
        .statshead-item{
          padding-bottom: 0% !important;
        }
      }
    }
  }
  .btn{
    border-color:black;
  }
  .stats__container{
    .statshead{
      text-align: left;

      .statshead .statshead-item{
        padding: 0 !important;
        padding-bottom: 0 !important;
        // margin: 0 !important;
      }
    }
    // .stats-radio-title{
    //   // width: 10%;
    // }
    .stats-radio-desc{
      display: none !important;
    }
    .stats-footer-wrapper{
      .w-70{
        width: 100% !important;
      }
      .foot{
        width: 100%;
      }
    }
  }
}


  @media only screen and (min-width: 375px) and (max-width: 569px)  { 

  .stats__container {
    .statshead {
      .statshead-item{
        margin : auto !important ;
      }
    }
  }
}