.drawer-stats {
    padding: 0 4em 0 0;
    width: 45%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .drawer-stats__container {
    width: 100%;
    text-align: left;
    // border: 1px solid #e5e5e5;
  }
  .drawer-statshead-item p {
    font-size: 10px;
  }
  .drawer-dot1 {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #A6CEE3;
  }
  
  .drawer-dot2 {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background-color: #1F78B4;
  }
  
  .drawer-foot p {
    color: var(--unnamed-color-000000);
    text-align: left;
    //font: normal normal bold 12px/14px Calibri;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .drawer-share {
    text-align: left;
   // font: normal normal bold 14px/17px Calibri;
    color: #001737;
    opacity: 1;
  }
  
  .drawer-mid-text {
    font-weight: 900;
    font-size: 2rem;
  }
  
  .drawer-pie {
    position: relative;
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    // margin-top: 2.4rem;
    // margin-left: 2.5rem !important;
  }
  
  .drawer-percent {
    position: absolute;
    top: 44%;
    left: 38% !important;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 29px/33px Arial;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    //font: normal normal normal 29px/33px Arial;
    letter-spacing: 0px;
    color: #1F78B4;
    opacity: 1;
  }
  .drawer-pie-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    width: 100% !important;
    // margin-top: 1rem;
    // margin-bottom: 2rem;
  }
  .drawer-statsbody{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .drawer-dropdown-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    margin-right: 1.5rem;
    .dropdown-toggle::after{
      content: " " !important;
      display: none !important;
    }
    
  }
  .drawer-label-text{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/18px var(--unnamed-font-family-calibri);
letter-spacing: var(--unnamed-character-spacing-0);
color: var(--unnamed-color-000000);
text-align: left;
//font: normal normal bold 12px/18px Calibri;
letter-spacing: 0px;
color: #000000;
opacity: 1;
  }
.drawer-pie-label-wrapper{
    display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: space-between;
}