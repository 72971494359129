$primary-color : #008174;
.cursor-pointer{
  cursor: pointer;
}
.ant-picker-input {
  width: 100% !important;
}
.ant-select-arrow{
  color: black;
}
.company-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    .company_header-title-text{
      font-size:1.5rem;
      font-weight:600;
      font-family: Calibri Regular;
      color:black;
    }
    .company-header-subtitle{
      display : flex;
      a{
        text-decoration: none;
      }
      .link-arrow{
        margin: 0rem 0.6rem;
        width: 7px;
      }
      .dashboard-link{
        font-size: 0.9rem;
        font-weight: 500;
        font-family: Calibri Regular;
      }
      .company-link{
        font-size: 0.9rem;
        font-weight: 500;
        font-family: Calibri Regular;
      }
      
    }
    .company-header-section-1 {
      display: flex;
      flex-direction: column;
      .company-header-section-link-container{
        display: flex;
        flex-direction: row;
        a{
          text-decoration: none;
        }
        .link-arrow{
          margin: 0rem 0.6rem;
          width: 7px;
        }
        .dashboard-link{
          font-size: 0.9rem;
          font-weight: 500;
          font-family: Calibri Regular;
        }
        .company-link{
          font-size: 0.9rem;
          font-weight: 500;
          font-family: Calibri Regular;
        }
      }
      
     
    }
    .company-header-section-2 {
      display: flex;
      flex-direction: row;
      padding-top: 25px;
      .create-new-btn{
        background: #008174;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 600;
        font-family: Calibri Regular;
      }
      select {
        width: 150px;
        margin: 0px 5px;
        height: 34px;
        color: #fff;
        background-color: #008174;
      }
    }
  }
  .create-btn-wrapper{
    display: flex;
    justify-content:center;
    align-items:center;
    .create-btn{
      font-family: Calibri Regular;
      font-size: 0.8rem;
      background-color:#008174 !important;
      color: #ffffff !important;
    }
  }
  
.company-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    .company-main-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        .create-new-btn2{
          background: #008174;
          font-weight: 600;
          color: #ffffff;
          padding: 0.5rem;
          width: 35%;
          height: 3.5rem;
          padding-top: 0.8rem;
        }
        .custom-btn {
            margin: 0px auto;
        }
        .activate-dot{
          background-color: #02ee02;
          border-radius: 50%;
          padding: 0px 7px;
          margin-right: 8px;
        }
        .deactivate-dot{
          background-color: #808080;
          border-radius: 50%;
          padding: 0px 7px;
          margin-right: 8px;
        }
    }
    .create-new-company {
      position: relative;
      bottom: 6px;
    }
}


.createbtn{
  padding: 0 5px;
  height: 50px;
}
.vertical_dotted_line
{
    border-left: 1px dashed black;
    // height: 100px;
} 
.company-container{
  padding: 0 4em;
}
.company-grid{
  width: 100%;
  margin-top: 1.2rem;
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: space-around;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 12px;
}
.card .company-card{
  flex-basis: 32% !important;
  margin-right: 0% !important ;  
  a{
   text-decoration: none; 
  }
  .dropdown-menu{
    left: -115% !important;
  }
}
.company-body{
  display: flex;
  flex-direction: row;
  width: 100%;
  // height: 10%;
  justify-content: space-between;
  // margin: 0 auto !important;
  margin-top: 16px;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  border-top: 1px solid rgba(197, 194, 194, 0.5);
  border-bottom: 1px solid rgba(197, 194, 194, 0.5);
  .subheader-col1{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width:7%;
      padding-right: 10px;
      .col1-icon{
        margin: 0.5rem;
        background-color: rgba(197, 194, 194, 0.1);
        padding: 0.5rem;
        border-radius: 3px;
        &:focus{
          background-color: rgba(#008174,0.1);
        }
      }
  }
  .subheader-col2{
      display: flex;
      flex-direction: row;
      width: 56%;
      align-items: center;
      // height: 50px;
      padding-left: 10px;
      border-right: 2px dashed rgb(192, 188, 188);
      border-left: 2px dashed rgb(192, 188, 188);
      .col-header{
          width: 22%;
      }
      .search-col{
          width: 33%;
      }
      
  }
  .subheader-col3{
      display: flex;
      flex-direction: row;
      margin-left: 15px ;
      align-items: center;
      width: 40%;
      .filter-button{
          border-radius: 5px;
          padding: 5px;
          margin: 2px;
          width: 20%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          background: transparent !important;
          color: #000 !important;
          border: 1px solid rgb(192, 188, 188);
          img{
              width: 25px;
              margin: 5px;
          }
          span{
              font-size: 0.8rem;
              font-family: Calibri Regular;
              font-weight: 500;

          }
      }
      .arrow-button{
        background: transparent !important;
        padding: 0 !important;
        border: none !important;
        height: 1.3rem;
      }
     .reset-button{
          border-radius: 5px;
          padding: 5px;
          margin: 9px;
          background: rgba(197, 194, 194, 0.1) !important;
          border: 0px !important;
          img{
              width: 20px;
              margin: 5px;
              opacity: 0.8;
          }
     }
     .menu-button{
          border-radius: 5px;
          padding: 5px;
          background: rgba(197, 194, 194, 0.1) !important;
          border: 0px !important;
          img{
              margin: 9px;
              width: 18px;
              margin: 5px;
              transform: rotate(90deg);
              opacity: 0.4 !important;
          }
     }
      .doubleNavigation{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border: 1px solid rgb(192, 188, 188);
          border-radius:5px;
          margin: 8px 8px 8px 10px;
          width: 300px;
          .dash-arrow{
              width: 9px;
              
          }
          .double-arrow{
              width: 17px;
          }
          .arrow-1{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border-right: 1px solid rgb(192, 188, 188);
              padding: 10px;
          }
          .arrow-3{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              border-left: 1px solid rgb(192, 188, 188);
              padding: 10px;
          }
          .Navigation-text{
              // width: 90%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 0px 5px 0px 5px;
              span{
                  font-size: 0.7rem;
              }

          }
         
      }
      
  }
  .goToButton{
      font-size: 0.8rem;
      font-family: Calibri Regular;
      font-weight: 500;
      color: rgb(163, 158, 158) !important;
      height: 40px;
      width: 80px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      background: transparent !important;
      border: 1px solid rgb(192, 188, 188);
  }
  .goButton{
      background-color: #008174 !important;
      margin: 5px;
      .go-arrow{
         width: 15px;
      }
  }
}
.dropdown-container{
  position: relative;
    padding: 0rem 0.5rem 0rem 0.5rem;
    .button{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 0.8rem;
      outline: none;
      border: 1px solid rgb(214, 214, 214);
      border-radius: 5px;  
      padding: 1rem 0.7rem;
      color: black;
      transition: 0.1s ease-out;
      height: 38px;
    }
    span{
      position: absolute;
      font-size: 0.9rem;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      background-color: white;
      color: gray;
      padding: 0 0.3rem;
      margin: 0 0.5rem;
      transition: .1s ease-out;
      transform-origin: left top;
      pointer-events: none;
    }
    .button:focus {
      border-color: #6200EE;  
    }
    .button:focus + span {
      color: #6200EE;
      top: 0;
      transform: translateY(-50%) scale(.9);
    }
    .button:not(:placeholder-shown) + span {
      top: 0;
      transform: translateY(-50%) scale(.9);
    }
}
.input-container{
    position: relative;
    padding: 0rem 0.5rem 0rem 0.5rem;
    span {
      position: absolute;
      font-size: 0.9rem;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      background-color: white;
      color: gray;
      padding: 0 0.3rem;
      margin: 0 0.5rem;
      transition: .1s ease-out;
      transform-origin: left top;
      pointer-events: none;
    }
    input {
      font-size: 0.8rem;
      outline: none;
      border: 1px solid rgb(214, 214, 214);
      border-radius: 5px;  
      padding: 1rem 0.7rem;
      color: gray;
      transition: 0.1s ease-out;
      height: 38px;
      width: 100% !important;
      
    }
    input:focus {
      border-color: #6200EE;  
    }
    input:focus + span {
      color: #6200EE;
      top: 0;
      transform: translateY(-50%) scale(.9);
    }
    input:not(:placeholder-shown) + span {
      top: 0;
      transform: translateY(-50%) scale(.9);
    }
}
.custom-menu{
  width: 93%;
  a{
    font-family: Calibri Regular;
  }
}
.company-section-container{
  width: 93% !important;
  position: relative;
  .modal-wrapper{
    position: absolute;
    top : 0% !important;
    left : 50%;
  }
}
.ant-drawer-footer{
 padding: 0px !important;
}

.company-filter-bar{

  .font-smaller{
    font-size: smaller;
    position: absolute;
    background: white;
  }
  .desk_advane_filter_btn{
    border: 1px solid var(--unnamed-color-008174);
    // background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #008174 !important;
    border-radius: 4px !important;
    opacity: 1;
    padding:9px 9px;
  }
  .adv_icon{
    padding-right: 2px !important;
  }
  .grid_btn{
    border: none !important;
    background-color: #F6F9FB!important;
  }
  .grid_btn:focus{
    background-color: #DBF1EE !important;
  }
  #list_btn{
    border: none!important;
    background-color: #F6F9FB!important;
  }
  #list_btn:focus{
    background-color: #DBF1EE !important;
  }
   .desk_advane_filter_btn{
    border: 1px solid var(--unnamed-color-008174);
    // background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #008174 !important;
    border-radius: 4px !important;
    opacity: 1;
    padding:9px 9px;
  }
  .page-label .desk-advance-filter{
    height: 20px !important;
    width: 20px;
    margin: 0 3px !important;
    text-align: center;
  }
  .border-right-dotted{
      border-right: 2px dashed #A5B0C0;
  } 

  .floating-label-search{
    top: -25%;
    z-index: 1;
    width: 17%;
    left: 3%;
    padding-left: 1%;
  }

  .floating-label-company{
      top: -25%;
      z-index: 1;
      width: 28%;
      left: 7%;
      padding-left: 1%;
  }

  .floating-label-mode{
      top: -25%;
      z-index: 1;
      width: 18%;
      left: 7%;
      padding-left: 1%;
  }

  .floating-label-date{
      top: -25%;
      z-index: 1;
      width: 23%;
      left: 7%;
      padding-left: 1%;
  }

  .margin-left-2per{
      margin-left: 2%;
  }

  ul{
      display: block;
  }


  .ant-btn-icon-only{
    height: 40px !important;
    width: 40px !important;
    color: #A5B0C0;
    border-radius: 4px;
    opacity: 1;
  }
  .page-label,.ant-select-selector,.ant-picker{
    height: 40px !important;
    border-color: #CBE6E3 !important;
    border-radius: 4px !important;
  }
  .adv_filter_img{
    height: 20px;
    width: 20px;
    margin-right: 6px;
  }
  .advane_filter_btn{
    border: 1px solid var(--unnamed-color-008174);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #008174;
    border-radius: 4px;
    opacity: 1;  
    padding: 5px 10px;
  }

  .ant-btn:hover, .ant-btn:focus {
    color: $primary-color;
    border-color: $primary-color;
    background: #DBF1EE;
  }
  .ant-input,.ant-select{
    line-height: 2;
  }
  .ant-select-selector{
    padding: 6px 11px !important;
  }
  .goto-field{
    width: 22%;
  }
  input[type='text'], input[type='email'], input[type='password'] {
      border: 1px solid #D2D2D2 !important;
      outline: none;
      border-radius: 4px;
  }
  
}
.filter_button{
  display: none !important;
}
.drawer-head-icon{
  background: #EDECEC !important;
  padding: 5px !important;
}
.mobile-hr{
  display: none !important;
}

//for mobile device
@media only screen and (min-width: 320px) and (max-width: 767px)  { 
  
.desk-advance-filter, .desk-more-btn, .desk-reset-filter, .desk-date, .desk-mode, .desk-company{
  display: none !important;
}
  .mobile-hr{
    display: block !important;
  }
  .company-container{
    padding: 0 1em !important ;
  }
  .company-grid{
    flex-basis: 100% !important;
  }
  .company-card{
    flex-basis: 100% !important;
    margin-right: 0 !important ;
  }
  .border-right-dotted{
    border: none !important;
  }
  
  .company-filter-bar{
    padding: 0 !important;
    justify-content: center !important;
    margin-right: 2px !important;
    .border-right-dotted {
      padding-right: 0 !important;
      padding-left: 0% !important;
      justify-content: space-between;
    }
    .ant-select-selector, .ant-picker{
      border-color: #D2D2D2 !important;
      border-radius: 4px !important;
    }
    .ant-drawer-header{
      padding: 17px !important;
    }
    .filter_button{
      display: block !important;
      padding-left: 0 !important;
    }
    #filter_btn{
      height: 37px;
      padding: 5px 12px;
      border: none;
      border-radius: 4px;
      color: #fff;
      background-color: #008174;
    }
    .search_box{
      padding-left: 0 !important;
      margin-left: 7px !important;
      border-radius: 5px;
    }
    .ant-drawer .ant-drawer-content .ant-drawer-content-wrapper{
      width: 100%;
      height: 41%;
      margin-top: 18%;
      justify-content: space-between;
    }
    .ant-drawer-body{
      padding: 14px 20px;
      margin: 0 5px ;
      justify-content: space-evenly;
    }
    .grid_btn{
      margin-right: 6px;
      // background-color: #F6F9FB  !important;
    }
    #list_btn{
      // background-color: #DBF1EE !important;
      border:none !important;
      .ant-btn{
        border: none !important;
      }
    }
    .date-input {
      border: 1px solid #D2D2D2;
      border-radius: 2px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    #dash-border{
      margin: 17px 0;
      padding: 0 8px;
      border-bottom: 2px dashed #696867;
      width: 100%;
    }
    .apply_btn{
      background-color: #008174 !important;
      color: #fff !important;
      padding: 5px 17px;
      border-radius: 4px;
      height: 40px !important;
      width: 96px !important;
    }
    .ant-select-arrow{
      color: black !important;
    }
    .floating-label-mode{
      top: -22%;
      z-index: 1;
      width: 21%;
      left: 7%;
      padding-left: 2%;
    }
    .floating-label-date{
      left:7%;
      width: 26% !important;
    }
    .floating-label-company{
      width: 36%;
      top: -23%;
      z-index: 1;
      left: 7%;
      padding-left: 3%;
    }
    .floating-label-search{
      // top: -25%;
      // z-index: 1;
      // width: 24%;
      // left: 2%;
      // padding-left: 2%;
      display: none;
    }  
  }
}
@media (min-width: 768px) and (max-width: 1023px)  { 
  .company-container{
    padding: 0 1em !important;
  }
  .company-grid{
    .card{
       .company-card{
        width: 50% !important ;
      }
    }
  }

}

@media only screen and (min-width: 1365px) and (max-width: 1919px){
  .company-filter-bar{
    .floating-label-search{
      top: -25%;
      z-index: 1;
      width: 22%;
      left: 4%;
      padding-left: 1%;
    }
  
    .floating-label-company{
        top: -25%;
        z-index: 1;
        width:36%;
        left: 10%;
        padding-left: 1%;
    }
  
    .floating-label-mode{
        top: -25%;
        z-index: 1;
        width: 23%;
        left: 9%;
        padding-left: 1%;
    }
  
    .floating-label-date{
        top: -25%;
        z-index: 1;
        width: 31%;
        left: 12%;
        padding-left: 1%;
    }
  }
}