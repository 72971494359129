.company_list_view{

  .ant-table table{
    width: 100%;
    // overflow-x: auto;
    border-collapse: separate !important;
      border-spacing: 1px 15px !important;
    }
    ul{
      display:none
    }
    .ant-table-thead tr{
      height: 50px !important;
    }
    .ant-table-tbody tr{
      height: 60px !important;
      // margin:10px !important;
      border: 0px !important;
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      border-radius: 5px !important;
    }
    .ant-table-thead > tr > th {
      color: #A1ACBD;
    }
  
    .ant-table-tbody td{
      border: 0px !important;
      
    }
    .tab-item{
        color:#2178FF;
    }
    .tabledesign{
      
    }
    .listview-image{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid black !important;
      padding: 2px;
    }
    .active-dot{
      width: 8px !important;
      height: 8px !important;
      background-color: rgb(6, 224, 6) !important;
      border-radius: 50%;
  
    }
    .card-deactive-greydot{
      width: 8px !important;
      height: 8px !important;
      background-color: #808080!important;
      border-radius: 50%;
    }
    .survey-chart-wrapper{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid rgb(143, 143, 143);
      .label-wrapper{
        position: absolute;
        margin-top:11px;
        margin-left: 12px;
        span{
          font-size:0.7rem !important;
          font-family: Calibri Regular;
          font-weight: bold !important;
        }
      }
      .chart-wrapper{
        width: 44px;
        position: absolute;
        margin-top:-7px;
        margin-left:-3px;
      }
    }
}
.active-dot{
  width: 8px !important;
  height: 8px !important;
  background-color: rgb(6, 224, 6) !important;
  border-radius: 50%;

}
.card-deactive-greydot{
  width: 8px !important;
  height: 8px !important;
  background-color: #808080!important;
  border-radius: 50%;
}