.raterslist-wrapper{
    display: flex;
    flex-direction: row;
    // justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding-bottom: 8px;
    padding-top: 8px !important;
    width: 100%;
    // .m-col-2{
    //     width:12% !important;
    // }
    // .m-col-1{
    //     width:16% !important;
    // }
    .m-text-head-name{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/18px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
        //font: normal normal bold 14px/18px Calibri;
        letter-spacing: 0px;
        color: #000000;
    }
    .m-text-head{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 12px/14px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
        //font: normal normal normal 12px/14px Calibri;
        letter-spacing: 0px;
        color: #000000;
    }
}

