.theme-checkbox{
    input[type='checkbox']{
        width: 1em;
        height: 1em;
        margin-top: .25em;
        vertical-align: top;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        border: 1px solid rgba(0,0,0,.25);
        -webkit-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        border-radius: .25em;
        // margin-left: -1.5em;
        margin-right: 5px;

        &:checked{
            background-image:url('../../../assets/images/check-white.svg');
            background-color: #008174;
            border-color: #008c7e;
        }
    }
}