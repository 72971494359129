.tab-drawer-survey{
    .ant-tabs-nav{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

// css for user tab in project page

.usertab_filter_button{
    display: none;
}

@media only screen and (min-width: 320px) and (max-width: 767px)  { 
.desk-advance-filter, .desk-more-btn, .desk-reset-filter, .desk-date, .desk-mode, .desk-company{
    display: none !important;
    }
    .usertab_filter_button{
        display: block !important;
        padding-left: 0 !important;
    }
    .project-user-tab{
        padding: 0 !important ;
    
        .border-right-dotted{
            border: none !important;
        }
        #filter_btn{
            background-color: #696867 !important;
            color: #fff !important;
            padding: 5px 9px;
        }
        .ant-drawer .ant-drawer-content .ant-drawer-content-wrapper{
            width: 100%;
            height: 41%;
            margin-top: 18%;
            justify-content: space-between;
        }
        .ant-drawer-body{
            padding: 14px 20px;
            margin: 0 5px ;
            justify-content: space-evenly;
        }
        .date-input {
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        #dash-border{
            margin: 17px 0;
            padding: 0 8px;
            border-bottom: 2px dashed #696867;
            width: 100%;
        }
        .apply_btn{
            background-color: #696867 !important;
            color: #fff !important;
            padding: 5px 17px;
            border-radius: 2px;
        }
        .floating-label-mode{
            top: -22%;
            z-index: 1;
            width: 21%;
            left: 7%;
            padding-left: 2%;
        }
        .floating-label-date{
            left:7%;
        }
        .floating-label-company{
            width: 36%;
            top: -23%;
            z-index: 1;
            left: 7%;
            padding-left: 3%;
        }
        .floating-label-search{
            top: -25%;
            z-index: 1;
            width: 24%;
            left: 2%;
            padding-left: 2%;
        }  
    }
}
