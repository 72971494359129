
.login-container {
  max-width: 100%;
  // height: 100vh;
  display: flex;
  // div:nth-of-type(1) {flex-grow: 1;}
  // div:nth-of-type(2) {flex-grow: 1;}

  height: auto;
  min-height: 100vh;
  width: 100% !important;
  justify-content: center !important;

  .logoImg{
    width: 446px;
    height: 59px;
  }
  .heading-h4{
    font-size: 28px;
  }

  #forget_password{
    font-size: 16px;
    a {
      text-decoration: underline !important;
      float: right;
    }
  }

  .col-1 {
    background-color: #EDECEC;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    

    .col-1-section {
      display: flex;
      flex-direction: column;
      // max-width: 70%;
      // height: 300px;
      .col-1-section-1  {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 100%;
      }
      .col-1-section-2  {
        display: flex;
        flex-direction: column;
        max-width: 100%;
        // .col-1-section-2-btn {
        //   display: flex;
        //   align-content: space-between;
        // }
      }
    }
  }
  .navbar-toggler{
    border:none !important;
  }
  .column-2{
    // max-width: 50% !important ;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    .col-2 {
      display: flex;
      // flex-direction: column;
      justify-content:center;
      align-items: center;
      background-color: #FFFFFF;
      padding-right: 15px;
      padding-left: 15px;
      // height: 70% !important;
      width: 100% !important;

      .col-2-section {
        // height: auto;
        // max-width: 400px;

        .col-2-section-1 {
          padding: 10px 0;
        }
        .col-2-section-2 {
          padding:10px 0;
        }
        form {
          padding: 10px 0;
          .forgot-password {
            display: flex;
            justify-content: space-between;
            // max-width: 300px;
            width: 100%;
            margin-bottom: 1.5rem;

            & > a {
              font-weight: 600;
            }
          }
        }
      }
      // #footer_data{
      //   height: 30% !important;
      //   // border-top: 1px solid #008174;
      //   // position: absolute;
      //   margin-top: 3rem ;
      //   display: flex-row !important;
      //   // justify-content: space-between !important;
      //   text-align: center !important;
      //   font-size: 1rem;

      //   #footer_menus{
      //     margin-top: 1rem !important;
      //     display: flex !important;
      //     text-decoration: none !important;
      //     justify-content: space-between !important;
      //   }
      // }
    } 
  }

  #footer_data{
    position: absolute;
    bottom: 0;
    // border-top: 1px solid #C1C1C1;
    // margin-top: 22.7rem !important;
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;
    padding: 8px 4px;
    margin:8px 8px ;
    width: 98%;
    #footer_description{
      // min-width: 100% !important;
    }
    #footer_menus{
      display: flex;
      justify-content: space-between;
      min-width: 50% !important;
    }
  }
}

@media (max-width: 800px) {
  .login-container  {
    flex-direction: column;
    padding-bottom: 0px !important;
  }
}
.login-container{
  .btn-custome{
    padding: 0.5rem 1rem !important;
    font-size: 1.25rem !important;
    border-radius: 0.3rem !important;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #008174;
    color: #fff;
    height: auto; 
    box-shadow: 2px 7px 10px 1px rgba(0,0,0,0.18);
    -webkit-box-shadow: 2px 7px 10px 1px rgba(0,0,0,0.18);
    -moz-box-shadow: 2px 7px 10px 1px rgba(0,0,0,0.18);
  }
  .ant-input-affix-wrapper{
    padding: 0;
  }
  .ant-input{
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    height: 64px;
    width: 100%;
    background-color: #EDECEC !important;
    border-radius: 5px;
    border-color: #EDECEC;
    padding-left: 65px !important;
    border-style: solid;
  }
  
  .ant-checkbox-inner {
    width: 1.5em;
    height: 1.5em;
    background-color: #ddd;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: #1890ff !important;
  }
  .ant-checkbox {
    top: 0.3em;
  }
}

//--- Responsive Design Media queries--//
// ===============================================
//for mobile device
@media (min-width: 320px) and (max-width: 767px)  { 
  .login-container .column-2 {
      max-width: 100% !important;
  }
  .login-container  {
    padding-bottom: 0px !important;
    height: 100vh;
  }
  .col-1 {
    display: none !important;
  }
  .col-2 {
    flex-direction: column;
    height: 100vh;
    max-width: 100% !important;
    justify-content:center !important;
    align-items: center !important;
  }
  form {
    #rememberMe{
      justify-content: start !important;
      text-align: left !important;
    }
    .forgot-password {
      text-align: center !important;
      justify-content: center !important;
    }
    #forget_password {
      margin-top: 2rem !important;
      display: flex !important;
      justify-content: center !important;
      font-size: 1.3rem;
      text-decoration: underline;
      text-align: center !important;
      & > a {
        font-weight: 700;
      }
    }
  }
  #footer_data{
    text-align: center;
  }
  // #footer_data{
  //   font-size: 0.7rem  !important;
  //   margin-top: 5rem !important;
  //   text-align: center !important;
  //   padding:0px 9px !important ;
  //   #footer_description{
  //     border-bottom: 1px solid #008174;
  //     padding:6px 0px ;
  //   }
  //   #footer_menus{
  //     display: flex !important;
  //     padding:6px 0px ;
  //     justify-content: space-between;
  //   }
  // }
  .login-container{
    #footer_data{
      position: inherit;
      display: block;
      #footer_description{
        padding:6px 0px ;
        border-bottom: 1px solid #008174;
      }
    }
    #footer_menus{
      padding:6px 0px;
    }
  }

}

@media only screen and (min-width: 375px) and (max-width: 811px)  { 
  #footer_data{
    margin-top: 15% !important;
    text-align: center;
    padding:0px 9px !important ;
    #footer_description{
      padding:6px 0px ;
      font-size: 13px !important; 
      border-bottom: 1px solid #008174;
    }
    #footer_menus{
      font-size: 12px !important; 
      padding:6px 5px ;
      display: flex !important;
      justify-content: space-between;
    }
  }
}

// set footer for small mobile phones 
@media only screen and (min-width: 411px) and (max-width: 736px)  { 
  .col-2 {
    #footer_data{
    text-align: center;

      border-top: none;
      position: absolute !important;
      // bottom: 2% !important;
      left: 7.5% !important;
      justify-content: center !important;
    }
  }
}
// For ipad 
@media only screen and (min-width: 768px) and (max-width: 1023px)  { 
  .login-container  {
    padding-bottom: 0px !important;
    justify-content: center !important;
  }
  .login-container .column-2{
    max-width: 100% !important;
  }
  .col-1 {
    display: none !important;
  }
  .col-2 {
    flex-direction: column;
    max-width: 100% !important;
    height: auto !important;
    // #footer_data{
    //   // display: inline-flexbox;
    //   position: absolute !important;
    //   // bottom: 2% !important;
    //   width: 100% !important;
    //   text-align: center !important;
    //   font-size: 1rem !important;
    //   left: 0 !important;
    //   margin-top: 7rem !important;

    //   copywrite Line style
    //   #footer_description{
    //     text-align: center !important;
    //     padding: 0% !important;
    //     font-size: 1.1rem !important;
    //     font-weight: bold !important;  
    //     padding: 2% 0% !important;
    //     border-bottom: 3px solid #EEEEEE;  
    //     letter-spacing:  1px !important;
    //   }

    //   #footer_menus{
    //     text-decoration: none !important;
    //     color: #008174 !important;
    //     font-size: 1.1rem !important;
    //     display: flex !important;
    //     padding: 1% 3% !important;
    //     justify-content: space-between !important;
    //     margin-bottom: 0% !important;
    //     margin-top: 2% !important;
    //   }
    // }
  }
  form {
    #rememberMe{
      justify-content: start !important;
      text-align: left !important;
    }
    .forgot-password {
      text-align: right !important;
      justify-content: end !important;
    }
    #forget_password {
      display: flex-end !important;
      // justify-content: center !important;
      font-size: 1.3rem !important;
      text-decoration: underline;
      text-align: center !important;
      & > a {
        font-weight: 700;
      }
    }
  }
  #footer_data{
    // border-top: 1px solid #008174;
    // margin-top: 23rem !important;
    margin-top: 10rem !important;
    display: flex !important;
    justify-content: space-between;
    font-size: 0.8rem;
    padding:18px 10px ;
    #footer_description{
      min-width: 50% !important;
      // border-bottom: 0 !important;
    }
    #footer_menus{
      min-width: 50% !important;
      display: flex;
      justify-content: space-between;
      min-width: 50% !important;
    }
  }
}

// ipad pro 
@media (min-width: 1024px) and (max-width: 1365px){ 
  // .login-container .column-2 .col-2{
  //   margin-top: 5rem !important;
  // }
  form {
    #rememberMe{
      justify-content: start !important;
      text-align: left !important;
    }
    .forgot-password {
      text-align: right !important;
      justify-content: center !important;
    }
    #forget_password {
      display: flex !important;
      justify-content: center !important;
      font-size: 1.3rem !important;
      text-decoration: underline;
      text-align: center !important;
      & > a {
        font-weight: 700;
      }
    }
  }
  #footer_data{
    // border-top: 1px solid #008174;
    margin-top: 22.7rem !important;
    display: flex !important;
    justify-content: space-between;
    font-size: 0.7rem;
    padding: 8px 4px;
    margin:8px 8px ;
    #footer_description{
      min-width: 50% !important;
    }
    #footer_menus{
      display: flex;
      justify-content: space-between;
      min-width: 50% !important;
    }
  }
} 
@media (min-width: 1024px) and (max-width: 1365px) { 
  // .login-container .column-2 .col-2{
  //   margin-top: 24rem !important;
  // }
  form {
    #rememberMe{
      justify-content: start !important;
      text-align: left !important;
    }
    .forgot-password {
      text-align: right !important;
      justify-content: end !important;
    }
    #forget_password {
      display: flex-end !important;
      // justify-content: center !important;
      font-size: 1.3rem !important;
      text-decoration: underline;
      text-align: center !important;
      & > a {
        font-weight: 700;
      }
    }
  }
  #footer_data{
    // border-top: 1px solid #008174;
    margin-top: 18.7rem !important;
    display: flex !important;
    justify-content: space-between;
    font-size: 0.8rem;
    margin:8px 8px ;
    #footer_description{
      min-width: 50% !important;
    }
    #footer_menus{
      display: flex;
      justify-content: space-between;
      min-width: 50% !important;
    }
  }
}

// for large screen 
@media (min-width: 1920px){ 
  // .col-2{
  //   margin-top: 8rem !important;
  // }
  #footer_data{
    #footer_description{
      margin-bottom: 0% !important;
    }
  }
}

