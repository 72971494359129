
input[type='text'], input[type='email'], input[type='password']{
  // width: 300px;
  // height: 40px;
  // background: var(--unnamed-color-edecec) 0% 0% no-repeat padding-box;
  // background: #EDECEC 0% 0% no-repeat padding-box;
  // border-radius: 4px;
  border: 1px solid #838383;
  outline: none;
  // opacity: 1;
  // z-index: 1;
  // padding-left: 35px;
  // padding-top: 10px;
  // font-size: 14px;
}
::placeholder {
  // font-size: 12px;
} 

input:focus {
  // border: 2px solid #008174;
}

.input-icon {
  position: relative;
  margin-right: -30px;
  top: 5.5px;
  z-index: 1;
}

.input-field {
  position: absolute;
  margin-left: 30px;
  font-size: 14px;
  z-index: 1;
}