$primary-color : #008174;
.cursor-pointer{
  cursor: pointer;
}
.drawer-head-icon{
  background: #EDECEC !important;
  padding: 5px !important;
}
.remainder-datepicker{
  z-index: none;
}
.projects-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    width: 95%;
    margin: 0 auto;
    .project-header-title{
        display: flex;
        flex-direction: column;
        .project-title{
            font-family: Calibri Regular;
            font-size:1.5rem;
            font-weight:600;
            color: #000;
        }
        .project-header-links{
            a:link{
                text-decoration: none !important;
            }
            .project-link{
                font-family: Calibri Regular;
                font-size:0.9rem;
            }
            .project-rightArrow{
                padding: 0rem 0.5rem 0rem 0.5rem;
                width:23px;
            }
        }
    }
    .project-header-button{
        display: flex;
        align-items: center;
        .project-button{
            font-family: Calibri Regular;
            font-size: 0.8rem;
            background-color:#008174 !important;
            color: #ffffff !important;
        }
    }
}
.projects-filterbar{
  width:94%;
  padding: 15px;
  margin: 0 auto;
}
.template-hr{
  width:94%;
  margin: 0 auto;
}
.project-table{
    width:94%;
    padding: 15px;
    margin: 0 auto;
    .custom-ant-table{
      .ant-table table{
        border-spacing: 1px 6px;
      }
    }
    .tb{
        border: 1px solid rgb(212, 212, 212) !important;
        border-top: 0px !important;
        border-radius: 5px !important;
    }
    .tab{
        border-left: 0px solid !important;
        border-right: 0px solid !important;
        border-top: 1px solid rgb(204, 204, 204);
        th{
            color:rgba(0,0,0,0.4);
            vertical-align:middle;
        }
    }
    .dot{
        width:8px;
        height:8px;
        border-radius:50%;
        background-color: rgb(88, 241, 41);
    }
    td{

        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .menu-td{
        display : flex;
        justify-content: flex-end;
        .menu-icon{
            transform:rotate(90deg);
            width:15px;
            height:15px;
        }
    }
}
.project-subheader{
    display: flex;
    flex-direction: row;
    width: 93%;
    // height: 10%;
    justify-content: space-between;
    margin: 0 auto !important;
    padding-top: 10px;
    padding-bottom: 10px;
    .project-subheader-section1{
        display: flex;
        flex-direction: row;
        width: 59%;
        align-items: center;
        // height: 50px;
        border-right: 2px dashed rgb(192, 188, 188);
        .col-header{
            width: 20%;
        }
        .search-col{
            width: 59%;
        }
        
    }
    .project-subheader-section2{
        display: flex;
        flex-direction: row;
        margin-left: 15px ;
        align-items: center;
        width: 40%;
        .filter-button{
            border-radius: 5px;
            padding: 5px;
            margin: 2px;
            width: 30%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            img{
                width: 25px;
                margin: 5px;
            }
            span{
                font-size: 0.8rem;
                font-family: Calibri Regular;
                font-weight: 500;

            }
        }
       .reset-button{
            border-radius: 5px;
            padding: 5px;
            margin: 9px;
            background: rgba(197, 194, 194, 0.1) !important;
            border: 0px !important;
            img{
                width: 20px;
                margin: 5px;
                opacity: 0.8;
            }
       }
       .menu-button{
            border-radius: 5px;
            padding: 5px;
            background: rgba(197, 194, 194, 0.1) !important;
            border: 0px !important;
            img{
                margin: 9px;
                width: 18px;
                margin: 5px;
                transform: rotate(90deg);
                opacity: 0.4 !important;
            }
       }
        .doubleNavigation{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border: 1px solid rgb(192, 188, 188);
            border-radius:5px;
            margin: 8px 8px 8px 10px;
            width: 300px;
            .dash-arrow{
                width: 9px;
                
            }
            .double-arrow{
                width: 17px;
            }
            .arrow-1{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-right: 1px solid rgb(192, 188, 188);
                padding: 10px;
            }
            .arrow-3{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                border-left: 1px solid rgb(192, 188, 188);
                padding: 10px;
            }
            .Navigation-text{
                // width: 90%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 0px 5px 0px 5px;
                span{
                    font-size: 0.7rem;
                }

            }
           
        }
        
    }
    .goToButton{
        font-size: 0.8rem;
        font-family: Calibri Regular;
        font-weight: 500;
        color: rgb(163, 158, 158) !important;
        height: 40px;
        width: 80px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }
    .goButton{
        background-color: #008174 !important;
        margin: 5px;
        .go-arrow{
           width: 15px;
        }
    }
}

.input-container{
      position: relative;
      padding: 0rem 0.5rem 0rem 0.5rem;
      span {
        position: absolute;
        font-size: 0.9rem;
        left: 13px;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        color: gray;
        padding: 0 0.3rem;
        margin: 0 0.5rem;
        transition: .1s ease-out;
        transform-origin: left top;
        pointer-events: none;
      }
      input {
        font-size: 0.8rem;
        outline: none;
        border: 1px solid rgb(214, 214, 214);
        border-radius: 5px;  
        padding: 1rem 0.7rem;
        color: gray;
        transition: 0.1s ease-out;
        height: 38px;
        width: 100% !important;
        
      }
      input:focus {
        border-color: #6200EE;  
      }
      input:focus + span {
        color: #6200EE;
        top: 0;
        transform: translateY(-50%) scale(.9);
      }
      input:not(:placeholder-shown) + span {
        top: 0;
        transform: translateY(-50%) scale(.9);
      }
  }
.ant-drawer-body{
    padding: 0 !important;
}
.ant-drawer-header{
    padding: 30px;
    .ant-drawer-title{
        font-family: Calibri Regular;
        font-weight: 600;
        font-size: 1.2rem;
    }
}

.project-list-container{
    padding: 15px;
    width: 95%;
    margin: 0 auto;
    .ant-table-tbody > tr > td{
      padding: 4px 16px;
    }
    .projectMoreImg{
      height: 18px;
    }
    .advane_filter_btn{
      // border: 1px solid var(--unnamed-color-008174);
      background: #FFFFFF 0% 0% no-repeat padding-box !important; 
      border: 1px solid #008174 !important;
      border-radius: 4px !important;
      opacity: 1 !important;  
      padding: 9px 3px !important;
    }
    .user-filter-bar{
      .ant-input {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 1rem;
        height: 12px !important;
        width: 100%;
        background-color: transparent !important;
        border-radius: 4px;
        padding-left: 15px !important;
        min-height: 2.5rem !important;
      }
      #desk_grid_btn{
        border: none !important;
        background-color: #F6F9FB!important;
      }
      #desk_grid_btn:focus{
        background-color: #DBF1EE !important;
      }
      #desk_list_btn{
        border: none!important;
        background-color: #F6F9FB!important;
      }
      #desk_list_btn:focus{
        background-color: #DBF1EE !important;
      }
      .ant-select-arrow{
        color: black !important;
      }
      .btn{
        padding: .375rem .75rem !important;
        border: 1px solid #CCCCCC;
      }
  
      .ant-btn{
        height: auto;
      }

      .ant-picker-input{
        width: 100% !important;
      }
        
        .font-smaller{
          font-size: smaller;
          position: absolute;
          background: white;
        }
    
        .border-right-dotted{
            border-right: 2px dashed #A5B0C0;
        } 
    
        .floating-label-search{
            top: -25%;
            z-index: 1;
            width: 18%;
            left: 4%;
            padding-left: 2%;
        }
    
        .floating-label-company{
          top: -25%;
          z-index: 1;
          width: 27%;
          left: 7%;
          padding-left: 1%;
        }
    
        .floating-label-mode{
          top: -25%;
          z-index: 1;
          width: 18%;
          left: 6%;
          padding-left: 1%;
        }
    
        .floating-label-date{
          top: -25%;
          z-index: 1;
          width: 23%;
          left: 7%;
          padding-left: 1%;
        }
    
        .margin-left-2per{
            margin-left: 2%;
        }
    
        ul{
            display: block;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector{
          border:none ;
        }
        .ant-picker{
          border: none !important;
        }
        .ant-select, .ant-picker{
          border:1px solid #D2D2D2 !important;
          border-radius: 4px !important;
        }
        .ant-btn-icon-only{
          height: 40px !important;
          width: 40px !important;
          color: #A5B0C0;
        }
        .page-label,.ant-select-selector,.ant-picker{
          height: 40px !important;
          border-radius: 5px;
        }
    
        .ant-btn:hover, .ant-btn:focus {
          color: $primary-color;
          border-color: $primary-color;
          background: #DBF1EE;
        }
        .ant-input,.ant-select{
          line-height: 2;
        }
        .ant-select-selector{
          padding: 6px 11px !important;
          border-radius: 5px;
        }
        .goto-field{
          width: 22%;
        }
        input[type='text'], input[type='email'], input[type='password'] {
            border: 1px solid #D2D2D2 !important;
            outline: none;
        }
      }

      .user_card{
        border-radius: 7px;
          .live-status-circle{
            position: absolute;
            // margin-top: -15%;
            // margin-left: 5%;
            left: 4%;
            height: 10px;
            width: 10px;
            border-radius: 50px;
            background-color: chartreuse;
          }
          .live-deactiveuser-circle{
            position: absolute;
            // margin-top: -18%;
            // margin-left: 5%;
            left: 4%;
            height: 10px;
            width: 10px;
            border-radius: 50px;
            background-color: #808080;
          }
          .usercard-dropdown{
            .dropdown-toggle::after {
              display: none;
            }
            button{
              padding: 0 !important; 
              border: none !important;
              background-color: transparent;
              color: inherit;
            }
          }

          .project-logo{
            height: 83px;
            width: 83px;
            border-radius: 50px;
            padding: 4px;
            border: 1px solid rgba(0,0,0,0.3);
            
          }
          .ant-checkbox-inner {
            width: 20px !important;
            height: 20px !important;
            background: #EDECEC;
            border: none !important;
        }
      }
}
.project_filter_button{
  display: none !important;
}

@media only screen and (min-width: 320px) and (max-width: 767px)  { 
  .more-outlined{
    .projectMoreImg{
      height: 20px;
      width: 20px;
    }
  }
  .ant-table-tbody > tr > td{
    padding: 0px 15px;
  }  
  // .live-deactiveuser-circle, .live-status-circle{
  //   margin-top: -20% !important;
  //   margin-left: 2% !important;
  // }
  .desk-advance-filter, .desk-more-btn, .desk-reset-filter, .desk-date, .desk-mode, .desk-company{
    display: none !important;
  }
  .ant-drawer{
    margin-top: 80px;
  }
  .ant-drawer-header {
    padding: 17px !important;
  }
  // .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    // border: none !important;
  // }
  .ant-picker, .ant-select{
    // border: 1px solid #D2D2D2;
    opacity: 1;
    border-radius: 4px !important;
  }
  // pop over style
  .ant-popover{
    .ant-popover-inner-content{
      padding: 11px 14px; 
    }
    .ant-popover-placement-bottom, .ant-popover-arrow{
      left: 5% !important;
      top:2% !important; 
    }
    .ant-popover-message{
      font-size: 18px;
    }
    .ant-popover-buttons{
      display: none !important;
    } 
    .anticon anticon-exclamation-circle{
      display: none !important;
    }
    .ant-popover-message > .anticon{
      display: none;
    }
    .ant-popover-message-title{
      padding-left: 0px !important;
    }    
  }
  .projects-header{
    width: 100%;
  }
  .project_filter_button{
    display: block !important;
    padding-left: 0 !important;
  }
  .project-list-container{
    .ant-table{
      overflow-x: auto;
    }
    padding: 0 !important ;  
    .border-right-dotted{
      border: none !important;
    }
    .advane_filter_btn{
      // border: 1px solid var(--unnamed-color-008174);
      background: #FFFFFF 0% 0% no-repeat padding-box !important; 
      border: 1px solid #008174 !important;
      border-radius: 4px !important;
      opacity: 1 !important;  
      padding: 5px 10px !important;
    }
    .adv_icon{
      padding-right: 3px !important;
      width: 20px;
    }
    #filter_btn{
      background-color: #696867 !important;
      color: #fff !important;
      padding: 5px 9px;
      border-radius: 4px;
    }
    .ant-drawer .ant-drawer-content .ant-drawer-content-wrapper{
      width: 100%;
      height: 41%;
      margin-top: 18%;
      justify-content: space-between;
    }
    .ant-drawer-body{
      padding: 14px 20px;
      margin: 0 5px ;
      justify-content: space-evenly;
    }
    .date-input {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    #dash-border{
      margin: 17px 0;
      padding: 0 8px;
      border-bottom: 2px dashed #696867;
      width: 100%;
    }
    .ant-select-arrow{
      color: black !important;
    }
    .apply_btn{
      background-color: #696867 !important;
      color: #fff !important;
      padding: 5px 17px;
      border-radius: 4px !important;
      height: 40px !important;
      width: 96px !important;
    }
    .floating-label-mode{
      top: -22%;
      z-index: 1;
      width: 20% !important;
      left: 8% !important;
      padding-left: 1% !important;
    }
    .floating-label-date{
      left:8% !important;
      width: 25% !important;
    }
    .user-filter-bar .floating-label-company{
      width: 36%;
      top: -23%;
      z-index: 1;
      left: 7%;
      padding-left: 3%;
    }
    .user-filter-bar .floating-label-search{
      // top: -25%;
      // z-index: 1;
      // width: 24%;
      // left: 6%;
      // padding-left: 2%;
      display: none;
    } 
  }
}

// .project-grid-view{
//   .card-link{
//     color: #000;
//     text-decoration: none;
//   }
//   .w-20{
//     width: 20%;
//   }
// }

@media only screen and (min-width: 768px) and (max-width: 1023px){
  // .live-deactiveuser-circle{
  //   margin-top: -20% !important;
  //   margin-left: 1% !important;
  // }
  .project-list-container{
    .user-filter-bar{
      .floating-label-date{
        width: 32% !important;
      }

      .floating-label-company{
        left: 12% !important;
        width: 46% !important;
      }
      .floating-label-mode{
        width: 33%;
        left: 12%;
      }
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1365px){
  // .live-deactiveuser-circle{
  //   margin-top: -24% !important;
  //   margin-left: 1% !important;
  // }
}
@media only screen and (min-width: 1365px) and (max-width: 1919px){
  .project-list-container{
    .user-filter-bar {
      .floating-label-search{
        top: -25%;
        z-index: 1;
        width: 23%;
        left: 6%;
        padding-left: 1%;
      }
    
      .floating-label-company{
          top: -25%;
          z-index: 1;
          width:36%;
          left: 10%;
          padding-left: 1%;
      }
    
      .floating-label-mode{
          top: -25%;
          z-index: 1;
          width: 23%;
          left: 9%;
          padding-left: 1%;
      }
    
      .floating-label-date{
          top: -25%;
          z-index: 1;
          width: 31%;
          left: 10%;
          padding-left: 1%;
      }
    }
  }
}

