.company-detail-chart{
  .emp-stats {
    padding: 0 4em 0 0;
    width: 45%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .emp-stats__container {
    width: 100%;
    text-align: left;
    border: 1px solid #e5e5e5;
  }
  .emp-statshead-item p {
    font-size: 10px;
  }
  .emp-dot1 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fedd84;
  }
  
  .emp-dot2 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #febe13;
  }
  
  .emp-foot p {
    color: var(--unnamed-color-000000);
    text-align: left;
   // font: normal normal bold 12px/14px Calibri;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .emp-share {
    text-align: left;
   // font: normal normal bold 14px/17px Calibri;
    color: #001737;
    opacity: 1;
  }
  
  .emp-mid-text {
    font-weight: 900;
    font-size: 2rem;
  }
  
  .emp-pie {
    position: relative;
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    // margin-top: 2.4rem;
    // margin-left: 2.5rem !important;
  }
  
  .emp-percent {
    // position: absolute;
    // top: 46%;
    // left: 42%;
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 35px/40px Arial;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal normal 35px/40px Calibri Regular;
    letter-spacing: 0px;
    color: #1F78B4;
    opacity: 1;
  }
  .emp-pie-wrapper{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content:center;
    width: 100% !important;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .emp-statsbody{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .emp-dropdown-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    margin-right: 1.5rem;
    .dropdown-toggle::after{
      content: " " !important;
      display: none !important;
    }
    
  }
  .emp-label-text{
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal bold 14px/22px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

}
