.drawer-header-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .drawer-item-left{
        display: flex;
        flex-direction: row;
        .user-img{
            width: 3rem;
            border-radius: 50%;
        }
        .drawer-title-details{
            display: flex;
            flex-direction: column;
            margin-left: 1rem;

            .drawer-user-name{
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/18px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
                //font: normal normal bold 18px/18px Calibri;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
            .first-detail{
                margin-left: 0rem !important;
            }
            .user-id{
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 12px/14px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
               // font: normal normal normal 12px/14px Calibri;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-left:0.5rem;
                display:flex;
                flex-direction: row;
                align-items: center;
                margin-top: 0.4rem;
            }
            .drawer-user-detail-wrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
    .drawer-item-right{
        .share-btn{
            background: transparent !important;
            border: 1px solid black !important;
            padding: 0.5rem !important;
            padding-top: 0.2rem !important;
            padding-bottom: 0.2rem !important;
            border-radius: 3px !important;
            .share-img{
                width: 0.8rem;
            }
            
        }
        .menu{
                width: 1rem;
                transform: rotate(90deg);
                margin-left: 0.5rem;
                margin-right: 0.8rem;
            }
    }
    

}
.ant-drawer-close{
    top: 20% !important;
    order: 1;
}