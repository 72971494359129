.framework-container-popups{
  .user-img{
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .w-9{
    width: 9%;
  }
  .w-15{
    width: 15%;
  }
  
  .ant-drawer-close{
    display: none;
  }
  
  .position-relative{
    position: relative;
  }
  .h-10vh{
    height: 10vh;
  }
  
  
  .profile-pic {
    width: 75px;
    height: 75px;
    border-radius: 50%;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  label.filebutton {
    overflow:hidden;
    position:absolute;
    background-color:#FFFFFF;
    border: 1px solid;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    margin-left: -4%;
    color: #F5F5F5;
    margin-top: 8%;
    .anticon-camera{
      color: #00FF00;
    }
  }
  
  label span input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    top: -2px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding:0;
  }
  .bg-EDECEC{
    background-color: #EDECEC;
  }
  .profile-pic-rule{
    border-radius: 15px;
    font-size: 12px;
  }
  
  .popup-sidebar-form-input {
    font-size: 1rem;
    height: 1.8rem !important;
    width: 100%;
    background-color: #FFF !important;
    border-radius: 4px;
    padding: 4px 11px !important;
  }

  textarea.popup-sidebar-form-input{
    height: auto !important;
  }

  .raters-button{
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.color-selector {
  display: inline-block;
  margin: 0 auto;
  border-radius: 3px;
  position: relative;
  // padding: 2px;
  font-family: verdana;
  background: white;
  border: 1px solid #838383;
  height: 1.8rem;
  .circle {
    display: inline-block;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 15%;
    border: 1px solid #d9d9d9;
    // margin-right: 10px;
  }
  
  .hidden {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 0;
    width: 21px;
    height: 1.8rem !important;
  }
  
  span {
    display: inline-block;
    vertical-align: middle;
  }

  .color-picker{
    right: 7px;
    top: 1px;
  }

  input[type='text']{
    border: none !important;
  }
}

.bg-theme-save-framwork-popup{
    border:none;
    font-size: 0.8rem;
    background-color:#008174 !important;
    color: #ffffff !important;
  }
.focus-area-form-popup{
    .popup-sidebar-form-input {
        font-size: 1rem;
        height: 1.8rem !important;
        width: 100%;
        background-color: #FFF !important;
        border-radius: 4px;
        padding: 4px 11px !important;
      }

      textarea.popup-sidebar-form-input{
        height: auto !important;
      }
      .profile-pic {
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }
      img {
        max-width: 100%;
        height: auto;
      }
      label.filebutton {
        overflow:hidden;
        position:absolute;
        background-color:#FFFFFF;
        border: 1px solid;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        text-align: center;
        // margin-left: -4%;
        color: #F5F5F5;
        margin-top: 8%;
        bottom: 0;
        right: 0;
        .anticon-camera{
          color: #00FF00;
        }
      }
      
      label span input {
        z-index: 999;
        line-height: 0;
        font-size: 50px;
        position: absolute;
        top: -2px;
        left: -700px;
        opacity: 0;
        filter: alpha(opacity = 0);
        -ms-filter: "alpha(opacity=0)";
        cursor: pointer;
        _cursor: hand;
        margin: 0;
        padding:0;
      }
      .bg-EDECEC{
        background-color: #EDECEC;
      }
      .profile-pic-rule{
        border-radius: 15px;
        font-size: 12px;
      }

      .construct-tab{
        background-color: #F5F9F8;
        height: 60vh;

        .popup-sidebar-form-input {
          font-size: 1rem;
          height: 1.8rem !important;
          width: 100%;
          background-color: #F8F8F8 !important;
          border-radius: 0px;
          border: none;
          padding: 4px 11px !important;
        }

        .anticon-plus{
          vertical-align: 0;
        }

        .btn{
          padding: 1%;
          padding-left: 3%;
          padding-right: 3%;
        }

        .construct-type{
          background: #589681;
          color: #FFFFFF;
          padding: 2px;
          padding-right: 1%;
          border-radius: 0px;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
          text-transform: uppercase;
          width: 13%;
          text-align: center;
          margin-left: 2%;
          font-weight: bold;
        }
      }
      .mt-50{
        margin-top: 50%;
      }

      .construct-tab{
        .color-selector-construct {
          display: inline-block;
          margin: 2% auto;
          border-radius: 3px;
          position: relative;
          // padding: 4px 11px;
          font-family: verdana;
          background: #F8F8F8;
          border: none;
          height: 1.8rem;

          .color-picker{
            right: 7px;
            top: 2px;
          }
          .circle {
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            border-radius: 15%;
            border: 1px solid #d9d9d9;
            // margin-right: 10px;
          }
          .hidden {
            position: absolute;
            left: 0;
            opacity: 0;
            top: 0;
            width: 21px;
            height: 1.8rem !important;
          }
        }
      }
}
  