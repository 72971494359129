// .projectlist_table{
.ant-table table {
  width: 100%;
  margin-top: -16px;
  border-collapse: separate !important;
  border-spacing: 1px 15px !important;
  h5.tab-item {
    color: #000000;
  }
}
ul {
  display: none;
}
.ant-table-thead tr {
  height: 50px !important;
  // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
  //   14px/17px var(--unnamed-font-family-calibri);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 14px/17px Calibri Regular;
  letter-spacing: 0px;
  // color: #A1ACBD;
  color: white;
  font-weight: bold;
  margin-bottom: -15px;
}
//change table header bg color
.ant-table-small .ant-table-thead > tr > th {
  background-color: white;
  color: #a1acbd;
}
.ant-table-tbody tr {
  height: 60px !important;
  // margin:10px !important;
  border: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 5px !important;
}

.ant-table-tbody td {
  border: 0px !important;
  // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
  letter-spacing: var(--unnamed-character-spacing-0);
  // color: var(--unnamed-color-000000);
  text-align: left;
 // font: normal normal normal 14px/17px Calibri;
  letter-spacing: 0px;
  color: #000000;
}
.tab-item {
  // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
  //   14px/17px var(--unnamed-font-family-calibri);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal bold 14px/17px Calibri Regular;
  letter-spacing: 0px;
  color: #2178ff;
}
// copy icon color change
.copy-icon .ant-typography-copy {
  color: black !important;
}

.ant-table-thead .ant-table-cell {
  font-weight: 800;
}
.copy-button {
  background: transparent !important;
  margin-left: 0.5rem;
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
  .copy-icon {
    width: 0.8rem;
  }
}

// responsive in ipad
@media (min-width: 768px) and (max-width: 1024px) {

  .ant-table table {
    display: block;
    // overflow-x: auto;
    white-space: nowrap;
  }
}

@media (min-width: 375px) and (max-width: 812px) {
  .ant-table-expanded-row{
    .ant-table-content {
      min-height: auto !important; 
    }
  }
  .ant-table table {
    // display: block;
    // overflow-x: scroll;
    white-space: nowrap;
  }
    // .tabledesign{

      .ant-table-content {
        min-height: 50vh !important; 
      }
    // }
}
.ant-table-cell .ant-table-row-expand-icon-cell {
  float: right !important;
}
// responsive table

.listview-image {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 1px solid black !important;
  padding: 2px;
}
.project-active-dot {
  width: 10px !important;
  height: 10px !important;
  background-color: rgb(6, 224, 6) !important;
  border-radius: 50% !important;
}
.project-deactive-dot {
  width: 10px !important;
  height: 10px !important;
  background-color: #808080 !important;
  border-radius: 50% !important;
}
.activate-dot {
  background-color: #02ee02;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-right: 8px;
  display: inline-block;
  margin-bottom: -2px;
}
.deactivate-dot {
  background-color: #808080;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-right: 8px;
  display: inline-block;
  margin-bottom: -2px;
}
.card1-chart-wrapper {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  // border: 1px solid rgb(143, 143, 143);
  .label-wrapper {
    position: absolute;
    margin-top: 13px;
    margin-left: 12px;
    span {
      font-size: 0.6rem !important;
      font-family: Calibri Regular;
      font-weight: bold !important;
    }
  }
  .chart-wrapper {
    width: 3.3rem;
    position: absolute;
    margin-top: -8px;
    margin-left: -3px;
  }
}
// }
.company-project-list-more-button {
  button {
    padding: 0 !important;
    border: none !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .distribution-container {
    .ant-table table {
      border-spacing: 11px 15px !important;
    }
  }
}
