.company-detail-chart{
  .rater-stats {
    padding: 0 4em 0 0;
    width: 45%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .rater-stats__container {
    width: 100%;
    text-align: left;
    border: 1px solid #e5e5e5;
  }
  .rater-statshead-item p {
    font-size: 10px;
  }
  .rater-dot1 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #eeeee4;
  }
  
  .rater-dot2 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #febe13;
  }
  
  .rater-foot p {
    color: var(--unnamed-color-000000);
    text-align: left;
    //font: normal normal bold 12px/14px Calibri;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .rater-share {
    text-align: left;
    //font: normal normal bold 14px/17px Calibri;
    color: #001737;
    opacity: 1;
  }
  
  .rater-mid-text {
    font-weight: 900;
    font-size: 2rem;
  }
  
  .rater-pie {
    position: relative;
    display: flex;
    flex-direction:row;
    justify-content: flex-end;
    // margin-left: 1rem !important;
  }
  
  .rater-percent {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 35px/40px Arial;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal normal 35px/40px Calibri Regular;
    letter-spacing: 0px;
    color: #1F78B4;
    
    opacity: 1;
  }
  .rater-pie-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .rater-statsbody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .rater-dropdown-wrapper{
    display: flex;
    flex-direction: row;
    // justify-content: flex-end !important;
    justify-content: space-between !important;
    align-items: center;
    width: 100% !important;
    // margin-right: 0 auto !important;
    // margin-right: 3.5rem;
    .dropdown-toggle::after{
      content: " " !important;
      display: none !important;
    }
    
  }
  .rater-label-text{
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal bold 14px/22px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .rater-project-text3{
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal 14px/22px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
  }
  .rater-arrow_down{
    width: 0.6rem !important;
    margin-left: 0.7rem;
  }
  .rater-allproject-dropdown{
    // border: 1px solid #000 !important;
    background: transparent !important;
    // margin-right: 2.5rem !important;
  }
  .pie-label-wrapper{
    display: flex;
    flex-direction: row;
    width: 55%;
    justify-content: space-between;
  }
  
}
