$primary-color: #008174;
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}
.w-9 {
  width: 9%;
}
.w-15 {
  width: 15%;
}
.w-10 {
  width: 10%;
}
.w-12 {
  width: 12%;
}
.editIcon {
  height: 14px;
  width: 21px;
}
.list-active-circle {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: chartreuse;
}
.list-deactive-circle {
  height: 10px;
  width: 10px;
  border-radius: 50px;
  background-color: #808080;
}
.user-active-circle {
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: chartreuse;
}
.user-deactive-circle {
  height: 8px;
  width: 8px;
  border-radius: 50px;
  background-color: #808080;
}
.w-fit-content {
  width: fit-content;
}

.display-type {
  display: flex;
}
.survey-desktop-header {
  width: 18%;
  position: fixed;
  bottom: 19%;
}

.surveu-form-container {
  width: 81%;
  margin-left: 20%;
}

.ql-container {
  height: 225px;
  .ql-editor {
    height: 220px;
    background-color: #fff;
  }
}
.bg-transparent {
  background-color: transparent !important;
  border: 1px solid #898989;
  color: #000000 !important;
}
.vertical-line {
  border-right: 1px solid #e6eaed;
  margin-top: 40px;
}
.remainder-datepicker {
  z-index: none;
}
.project-detail-header {
  .header-name {
    font-weight: 700;
  }
  .report-header-subtitle {
    a {
      font-size: 14px;
      text-decoration: none;
    }
  }
}
.drawer-head-icon {
  background: #edecec !important;
  padding: 5px !important;
}
.anticon-copy {
  color: black;
}
.editProjectDrawer {
  .ant-cascader {
    width: 100%;
  }
  .ant-drawer-body {
    background-color: #f0f3f5;
  }
}
.project-container {
  .cascaderStyle {
    .ant-cascader {
      display: block;
    }
  }
  background: transparent !important;
  border: none !important;
  .ant-tabs-nav-list {
    .ant-tabs-ink-bar {
      background: #008874 !important;
      height: 4px;
    }
    .ant-tabs-tab {
      color: #a09d9b;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000 !important;
  }

  .ant-table-cell {
    background: transparent;
    padding: 5px 16px;
    &::before {
      display: none;
    }
    a {
      text-decoration: none;
    }
  }

  .ant-table {
    height: 100%;
  }

  .ant-table-thead {
    th {
      border: none !important;
    }
  }

  .ant-table.ant-table-bordered > .ant-table-container {
    border: none;
  }

  .btn {
    // padding: .375rem .75rem !important;
    border: 1px solid #cccccc;
  }

  .ant-btn {
    height: auto;
  }

  .goto-btn {
    color: white;
    border-radius: 7px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff !important;
  }
  .ant-checkbox {
    top: 0.3em;
  }

  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
    background: #edecec !important;
    border: none !important;
  }
  .ant-input {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    height: 12px !important;
    width: 100%;
    background-color: transparent !important;
    border-radius: 8px;
    padding-left: 15px !important;
    min-height: 2.5rem !important;
  }
 

  .comman-button {
    height: 40px;
    border: none;
    border-radius: 0.25rem;
    button {
      height: inherit;
    }
  }

  .btn-theme {
    background-color: #008174 !important;
    color: #fff;
    box-shadow: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #838383;
    border-radius: 6px;
    color: #000000;
  }
  .ant-select-arrow {
    color: #000000;
  }
  .ant-picker-input {
    width: 100% !important;
  }

  .dropmenu-bg-primary {
    background-color: #008174;
    button {
      height: inherit;
      color: white !important;
    }
  }

  .company-logo {
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
  .anticon {
    vertical-align: 0;
  }

  .user-img {
    height: 35px;
    width: 35px;
    border-radius: 50px;
  }
  .user-name {
    margin-top: 0.4em;
    font: normal normal bold 18px/18px Calibri Regular;
  }
  #dropdown-basic {
    width: 100%;
    border: none !important;
    color: #ffffff !important;
  }

  .border-right-dyn {
    border-right: 1px solid #808080;
  }

  .border-right-dyns::after {
    content: '';
    position: absolute;
    right: 0;
    top: 10px;
    height: 30px;
    width: 20%;
    border-right: 1px solid #726e97;
  }
  .text-right-custom {
    text-align: right;
    text-align: -webkit-right;
  }

  .headerarea {
    .more-outlined {
      .dropdown-toggle::after {
        content: none;
      }
    }
  }

  .bg-gray-color {
    background-color: #f0f3f5;
    .ant-switch-checked {
      background-color: #63aba4;
    }
    .ant-switch-handle::before {
      background-color: #008174;
    }
  }

  .project-form {
    .ant-modal-content {
      background-color: #202a31 !important;
    }
    .ant-switch-checked {
      background-color: #63aba4;
    }
    .ant-switch-handle::before {
      background-color: #008174;
    }
    .form-control {
      opacity: 1;
      background: #f9f9f9;
    }
    .inputBorder {
      border-color: #707070 !important;
    }
    .quill {
      background-color: #ffffff !important;
    }
    .date-picker-and-design {
      border: 1px solid #838383;
      border-radius: 5px;
      padding: 0.375rem 0.75rem;

      .anticon-calendar {
        color: #000000;
      }
    }
    // .ant-switch {
    //   height: 8px;
    //   .ant-switch-handle {
    //     top: -5px;
    //   }
    // }
  }

  .user-list-container {
    .user-filter-bar {
      .project-container
        .ant-select:not(.ant-select-customize-input)
        .ant-select-selector,
      .ant-picker {
        border: none !important;
      }
      .ant-picker {
        border: 1px solid #d2d2d2 !important;
      }
      #desk_grid_btn {
        border: none !important;
        background-color: #f6f9fb !important;
      }
      #desk_grid_btn:focus {
        background-color: #dbf1ee !important;
      }
      #desk_list_btn {
        border: none !important;
        background-color: #f6f9fb !important;
      }
      #desk_list_btn:focus {
        background-color: #dbf1ee !important;
      }
      .desk_advane_filter_btn {
        border: 1px solid var(--unnamed-color-008174);
        // background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #008174 !important;
        border-radius: 4px !important;
        opacity: 1;
        padding: 9px 9px;
      }
      .font-smaller {
        font-size: smaller;
        position: absolute;
        background: white;
      }

      .border-right-dotted {
        border-right: 2px dashed #a5b0c0;
      }

      .floating-label-search {
        top: -25%;
        z-index: 1;
        // width: 15%;
        left: 2%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }

      .floating-label-company {
        top: -25%;
        z-index: 1;
        // width: 25%;
        left: 7%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }

      .floating-label-mode {
        top: -25%;
        z-index: 1;
        // width: 16%;
        left: 7%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }

      .floating-label-date {
        top: -25%;
        z-index: 1;
        // width: 20%;
        left: 7%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }

      .margin-left-2per {
        margin-left: 2%;
      }

      ul {
        display: block;
      }

      .ant-btn-icon-only {
        height: 40px !important;
        width: 40px !important;
        color: #a5b0c0;
      }
      .page-label,
      .ant-select-selector,
      .ant-picker {
        height: 40px !important;
      }

      .ant-btn:hover,
      .ant-btn:focus {
        color: $primary-color;
        border-color: $primary-color;
        background: #dbf1ee;
      }
      .ant-input,
      .ant-select {
        line-height: 2;
      }
      .ant-select-selector {
        padding: 6px 11px !important;
      }
      .goto-field {
        width: 22%;
      }
      input[type='text'],
      input[type='email'],
      input[type='password'] {
        border: 1px solid #d2d2d2 !important;
        outline: none;
      }
    }

    .user_card {
      border-radius: 7px;
      .gravatar {
        height: 25px;
        width: 25px;
        border-radius: 50px;
      }
      .live-status-circle {
        margin-top: 2%;
        height: 15px;
        width: 15px;
        border-radius: 50px;
        background-color: chartreuse;
      }
      .deactive-status-circle {
        margin-top: 2%;
        height: 15px;
        width: 15px;
        border-radius: 50px;
        background-color: #808080;
      }
      .usercard-dropdown {
        .dropdown-toggle::after {
          display: none;
        }
        button {
          padding: 0 !important;
          border: none !important;
          background-color: transparent;
          color: inherit;
        }
        .btn {
          border: none;
        }
      }
    }
  }

  .framework-container {
    .focus-area-icon {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      margin-left: 10%;
    }
    .vertical-middle {
      display: flex;
      align-items: center;
    }
    .focus-area-data {
      width: 100%;
      width: -moz-available; /* WebKit-based browsers will ignore this. */
      width: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
      width: stretch;
    }
    .focus-area-data-shadow {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    }
    .rater-section {
      .dropdown-toggle::after {
        display: none;
      }
    }

    .bg-white-back button {
      background-color: #ffffff !important;
      padding: 2px 5px;
    }

    .ellipse-menu .ellipse-img {
      width: 1.5rem !important;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 0.5px solid #707070 !important;
      border-radius: 3px;
      opacity: 1;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
    .raters-table {
      display: table;
      td {
        vertical-align: middle;
      }
    }

    .plusiconforaddslice {
      cursor: pointer;
    }
  }

  .rater-user-img {
    height: 25px;
    width: 25px;
    border-radius: 50px;
  }
  .rater-model-panel .ant-collapse-content-box {
    height: 300px;
    overflow: auto;
  }

  .rater-model-panel {
    margin-bottom: 24px;
    overflow: hidden;
    background: transparent;
    border: 1px solid rgb(187, 187, 187) !important;
    border-radius: 7px !important;
  }

  .data-analysis-container {
    .filter_btn {
      display: none;
    }
    .data-analysis-filter-container {
      .floating-label-search {
        position: absolute;
        top: -25%;
        z-index: 1;
        // width: 15%;
        left: 4%;
        // padding-left: 1%;
        background-color: #ffffff;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }

      .floating-label-company {
        position: absolute;
        background-color: #ffffff;
        top: -25%;
        z-index: 1;
        // width: 23%;
        left: 5%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }

      .floating-label-mode {
        position: absolute;
        background-color: #ffffff;
        top: -20%;
        z-index: 1;
        // width: 20%;
        left: 8%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }

      .floating-label-date {
        position: absolute;
        background-color: #ffffff;
        top: -20%;
        z-index: 1;
        // width: 25%;
        left: 8%;
        // padding-left: 1%;

        width: auto;
        padding-left: 6px;
        padding-right: 6px;
      }
      .ant-pagination {
        display: block;
      }
      .ant-btn-icon-only {
        height: 40px !important;
        width: 40px !important;
        color: #a5b0c0;
      }
      .page-label,
      .ant-select-selector,
      .ant-picker {
        height: 40px !important;
      }

      .ant-btn:hover,
      .ant-btn:focus {
        color: $primary-color;
        border-color: $primary-color;
        background: #dbf1ee;
      }
      .ant-input,
      .ant-select {
        line-height: 2;
      }
      .ant-select-selector {
        padding: 6px 11px !important;
        border: 1px solid #d2d2d2 !important;
      }
      .goto-field {
        width: 22%;
      }
      input[type='text'],
      input[type='email'],
      input[type='password'] {
        border: 1px solid #d9d9d9;
        outline: none;
        height: 40px;
      }
      .border-right-dotted {
        border-right: 2px dashed #a5b0c0;
      }
    }

    .response-count {
      color: rgba(18, 18, 214, 0.637);
      cursor: pointer;
    }
    .anticon-more {
      font-size: 2rem;
      color: #a1acbd;
    }
    th {
      font-weight: bold !important;
      color: #b5becb !important;
    }
  }

  .distribution-container {
    .form-group {
      margin-top: 0rem;
    }
    .floating-label-search {
      position: absolute;
      top: -25%;
      z-index: 1;
      // width: 14%;
      left: 4%;
      // padding-left: 1%;
      background-color: #ffffff;

      width: auto;
      padding-left: 6px;
      padding-right: 6px;
    }

    .floating-label-mode {
      position: absolute;
      background-color: #ffffff;
      top: -25%;
      z-index: 1;
      // width: 22%;
      left: 8%;
      // padding-left: 1%;

      width: auto;
      padding-left: 6px;
      padding-right: 6px;
    }

    .floating-label-date {
      position: absolute;
      background-color: #ffffff;
      top: -25%;
      z-index: 1;
      // width: 28%;
      left: 8%;
      // padding-left: 1%;

      width: auto;
      padding-left: 6px;
      padding-right: 6px;
    }
    .ant-btn-icon-only {
      height: 33px !important;
      width: 40px !important;
      color: #a5b0c0;
    }
    .goto-field {
      width: 22%;
    }
    .more-outlined {
      .dropdown-toggle::after {
        display: none;
      }
      .anticon-more {
        font-size: 2rem;
        color: #a5afc0;
      }
      button {
        padding: 0;
        border: none !important;
      }
    }
    .distribution-filter-container {
      input[type='text'],
      .ant-select-selector {
        border: 1px solid #d9d9d9;
        outline: none;
        height: 40px;
      }
      .ant-select-selector {
        padding-top: 2%;
      }
      .ant-picker,
      button {
        height: 40px !important;
      }
    }
  }

  .page-break-hr {
    border: none;
    border-top: 3px dotted #000000;
    color: #fff;
    background-color: #fff;
    height: 1px;
    width: 50%;
  }
  .page-break-text {
    width: 7%;
    margin-left: 1%;
    margin-top: 5px;
  }
  .page-break-remove {
    margin-left: 1%;
    margin-top: 5px;
    color: #ff0000;
    height: 26px;
    width: 26px;
    border: 1px solid #b9b9b9;
    border-radius: 67.25rem;
    text-align: center;
    padding-top: 1px;
  }
}

@media (max-width: 480px) {
  .project-container {
    padding: 0;
    padding-top: 25px;
  }

  .project-form {
    margin-top: 5%;
  }
  .headerarea {
    text-align: center;
  }
  .button-collections {
    justify-content: center !important;
  }
  .report-header-subtitle {
    justify-content: center !important;
  }
  .border-right-dyns::after,
  .border-right-dyn {
    border-right: none !important;
    margin-left: 0 !important;
  }
  .ant-col.ms-3 {
    margin-left: 0 !important;
  }
}

@media (max-width: 961px) {
  .project-form {
    margin-top: 5%;
  }
}

.servey-container {
  .move-portion {
    width: 2px;
    height: 18px;
    margin: 1px;
    background: #b8b8b8 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    align-self: center;
  }

  .focusareadropdown {
    .ant-select-selector {
      height: 45px;
    }
  }

  .constructdropdown {
    .ant-select-selector {
      height: 45px;
    }
    .ant-select-selection-item {
      padding-top: 4px;
      line-height: 20px;
    }
  }

  .select_config{
    .ant-select-selector{
      height: 44px !important;
    }
  }

  .choices-counter,
  .scale-counter {
    button {
      height: 25px;
      padding: 0px 7px;
    }
    .ant-input {
      width: 15% !important;
      margin: 0 2%;
    }
  }
  .ant-btn {
    border-color: #d9d9d9 !important;
  }
  .w-3 {
    width: 3%;
  }
  .block-containers {
    height: 550px;
    overflow-y: scroll;
    .block {
      background-color: #fbfbfb;
      border: 1px solid #f5f5f5;
      border-radius: 15px;
      .block-title {
        background: #949494;
        padding: 7px;
        padding-right: 4%;
        border-radius: 7px;
        text-transform: capitalize;
      }
      .comman-button {
        button::after {
          display: none;
        }
        button {
          padding: 0;
          border: none !important;
        }
        button:focus {
          box-shadow: none;
        }
      }
      .block-button {
        align-items: baseline;
        justify-content: end;
      }
      .dropdown-menu {
        min-width: 8rem;
        font-size: 0.9rem;
      }
      .accordion-toggle {
        border: none;
        background: transparent;
        cursor: pointer;
      }

      .input-elements {
        .questions-container {
          .question {
            border: 1px solid #e5e5e5;
            background-color: #ffffff;
            border-radius: 5px;
            position: relative;
            .action-icon {
              font-size: 15px;
              position: absolute;
              right: 13px;
              top: 0;
              height: 100%;
              .anticon-close-circle {
                color: #ff0000;
                background-color: #ffffff;
              }
              .action-dropdown {
                height: auto;
                border: 1px solid #b9b9b9;
                border-radius: 67.25rem;
                background-color: #ffffff;
                position: absolute;
                top: 0;
                .btn-primary {
                  border: none !important;
                }
                .btn {
                  padding: 1px 5px !important;
                }
                .dropdown-toggle::after {
                  display: none;
                }
              }
              .action-remove {
                height: 26px;
                width: 26px;
                border: 1px solid #b9b9b9;
                border-radius: 67.25rem;
                background-color: #ffffff;
                text-align: center;
                color: #ff0000;
                position: absolute;
                bottom: 0;
              }
            }
            .matrix-table {
              input[type='text'] {
                text-align: center;
              }
              .form-check .form-check-input {
                float: none;
              }
            }
            .question-bg-gray {
              background-color: #f8f8f8;
              .non-editable-label {
                background-color: #f8f8f8;
              }
            }
            .form-check-input {
              margin-top: 0.8em;
            }

            input {
              border-color: #d9d9d9 !important;
            }
          }
          .question-label {
            background: #589681;
            color: #ffffff;
            padding: 7px;
            padding-right: 2%;
            border-radius: 0px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            text-transform: uppercase;
            width: 13%;
            .question-prefix {
              padding: 1px 10px;
              background: #ffffff;
              margin-right: 12%;
              border-color: #dbdbdb;
              box-shadow: 0 0 0 2px #cbcbcb61;
              border-radius: 2px;
            }
          }

          .key-cofus-area,
          .constrcuct {
            // background: #FCB737;
            color: #ffffff;
            padding: 7px 1%;
            border-radius: 0px;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            text-transform: capitalize;
            width: auto;
            text-align: center;
          }
          .editable-label {
            padding: 4px 11px;
            line-height: 1.5715;
            background-color: #f8f8f8;
            background-image: none;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
          }
          .non-editable-label {
            padding: 4px 11px;
            background-color: #ffffff;
            background-image: none;
            color: #000000;
            border: none;
            border-radius: 2px;
            cursor: pointer;
          }
          .ant-input-disabled {
            color: #000000;
            background-color: #f8f8f8;
            box-shadow: none;
            cursor: pointer;
            opacity: 1;
          }
          .non-editable-label::placeholder {
            color: #000000;
            opacity: 1; /* Firefox */
          }

          .non-editable-label:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #000000;
          }

          .non-editable-label::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #000000;
          }
        }
        .add-question-button {
          // width: 15%;
          // padding: 7px;
          // background-color: #ffffff;
          text-align: end;
        }
        .move-portion {
          width: 2px;
          height: 18px;
          margin: 1px;
          background: #f1f1f1 0% 0% no-repeat padding-box;
          border-radius: 2px;
          opacity: 1;
          align-self: center;
        }
        .width-fill-full {
          width: 100%;
          width: -moz-available; /* WebKit-based browsers will ignore this. */
          width: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
          width: stretch;
        }
        .mw-99 {
          max-width: 99%;
        }
      }

      .html-editor {
        height: 315px;
        .rdw-editor-wrapper {
          height: 200px;
        }
      }
    }

    .selected-block {
      border: 1px solid #63aba4;
    }
  }

  .ant-drawer-mask {
    background-color: transparent;
  }
  .servey-preview {
    border-radius: 15px;
  }
  .ant-drawer-wrapper-body {
    padding: 2%;
  }
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 100%;
  // padding: 48px;
  // overflow: hidden;
  // text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.float-right {
  float: right;
}

.reminder-form {
  .ant-input {
    height: auto !important;
    padding-left: 5px !important;
    background-color: #fff !important;
  }
  .ant-picker-input {
    width: 100% !important;
  }
  .last-shared-reminder {
    background: #e6ebee;
    padding: 5px 16px;
  }
}
.project-grid-view {
  .card-chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 42%;
    /* position: absolute; */
    background-color: white;
    // border: 1px solid black;
    border-radius: 50%;
    margin-top: -31px;
    margin-left: 55px;
    span {
      font-size: 0.5rem;
      position: absolute;
      margin-top: 5%;
      font-weight: 600;
      margin-left: 2%;
    }
    .card-chart {
      width: 55px;
      margin-bottom: 10px;
      position: absolute;
    }
  }
  .card-link {
    color: #000;
    text-decoration: none;
  }
  .w-20 {
    width: 20%;
  }
  .card {
    border: 1px solid #ccc;
    width: 32.1%;
    height: 175px;
    margin-right: 15px;
    margin-bottom: 15px;
    .cardBody {
      margin-left: 25px;
    }
    .card-text {
      margin-left: 35px !important;
      max-width: 50%;
      span {
        font-size: 1.4rem;
        font-family: Calibri Regular;
        font-weight: 600;
        color: black;
        line-height: 100%;
        width: 80% !important;
      }
    }

    .card-logo {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.3);
      padding: 4px;
    }
    .hrcircle {
      position: absolute;
      // margin-bottom: 15px;
      margin-top: -18px;
      margin-right: 18px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      background-color: #8659f2;
      color: white;
    }
  }
}

.card-dot-wrapper {
  position: relative;
  .card-greendot {
    position: absolute;
    background: rgb(2, 238, 2);
    font-size: 0.4rem;
    border-radius: 50%;
    width: 10px;
    margin-top: 13px;
    margin-left: 13px;
  }
}

.card-det-icon {
  width: 1.2rem;
}
.card-det-text {
  font-size: 1rem !important;
  font-family: Calibri Regular !important;
  font-weight: 400 !important;
}
.card-dot-wrapper {
  position: relative;
  .card-greendot {
    position: absolute;
    background: rgb(2, 238, 2);
    font-size: 0.4rem;
    border-radius: 50%;
    width: 10px;
    margin-top: 13px;
    margin-left: 13px;
  }
}
.card-chart-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37%;
  height: 37%;
  position: absolute;
  background-color: white;
  // border: 1px solid black;
  border-radius: 50%;
  margin-top: 82px;
  margin-left: 75px;
  span {
    font-size: 0.5rem;
    position: absolute;
    margin-top: 1px;
    font-weight: 600;
  }
}
.card-chart {
  width: 120%;
  margin-bottom: 10px;
  position: absolute;
}
.card-upper-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.company-card-ellipse {
  .dropdown-menu {
    min-width: 6rem !important;
  }
  .dropdown-item {
    // font: var(--unnamed-font-style-normal) normal
    //   var(--unnamed-font-weight-normal) 14px/17px
    //   var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
    font: normal normal normal 14px/17px Calibri Regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .dropdown-menu {
    transform: translate(3px, 26px) !important;
  }
  .ellipse-menu {
    padding: 0px !important;
    border: none !important;
    background: transparent !important;
    &:after {
      border: none !important;
    }
  }
  .card-menu {
    transform: rotate(90deg);
    width: 1.5rem !important;
    opacity: 1;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }
}
#info_button {
  display: none;
}
.mobile-more-outlined {
  display: none;
}
.filter_button {
  display: none;
}
.ProjectModal {
  .ant-drawer-content-wrapper {
    .ant-drawer-body {
      background-color: #f0f3f5;
    }
    .date-picker-and-design {
      border-color: #838383;
    }
    .ant-switch {
      // height: 8px;
    }
    .ant-switch-handle {
      // top: -5px;
    }
    .ant-switch-checked {
      background-color: #63aba4;
    }
    .ant-switch[aria-checked="true"] {
      .ant-switch-handle::before {
        background-color: #008174;
      }
    }
    .ant-switch[aria-checked="false"] {
      .ant-switch-handle::before {
        background-color: #838383;
      }
    }
    // .ant-switch-handle::before {
    //   background-color: #008174;
    // }
    .save-btn {
      background-color: #008174 !important;
      border: none;
      color: #fff;
      border-radius: 3px;
    }
  }
}
// distribution modal when click on send reamainder
.reminder-form {
  .ant-drawer-content-wrapper {
    .project-form {
      background-color: #f0f3f5;
      .ant-cascader {
        .ant-select-selector {
          border-color: #707070;
          border-radius: 4px;
        }
      }
      .form-control {
        opacity: 1;
        background: #f9f9f9;
      }
      .inputBorder {
        border-color: #707070 !important;
      }
      .quill {
        background-color: #ffffff !important;
      }
      .comman-button {
        border: none;
        border-radius: 4px;
      }
    }
  }
}

//for mobile device
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .ProjectModal {
    .ant-drawer-content-wrapper {
      height: 91vh;
    }
  }
  .ql-container {
    .ql-editor {
      height: 189px;
    }
  }
  .project-grid-view {
    .card-chart-wrapper {
      width: 30% !important;
      height: 39% !important;
      .card-chart {
        // width: 100% !important;
        margin-bottom: 10px !important;
      }
      span {
        margin-top: 0% !important;
        margin-left: 6% !important;
      }
    }
  }
  // .live-status-circle {
  //   margin-top: -22% !important;
  //   margin-left: 2% !important;
  // }
  .user-count-img {
    width: 35% !important;
  }
  .filter_btn {
    display: block !important;
  }
}
// responsive for small devices
@media (min-width: 414px) and (max-width: 735px) {
  .ProjectModal {
    .ant-drawer-content-wrapper {
      .ant-drawer-body {
        height: 96vh;
      }
    }
  }
  // .project-grid-view {
  //   .live-status-circle {
  //     margin-top: -21% !important;
  //     margin-left: 2% !important;
  //   }
  // }
  .filter_btn {
    display: block !important;
  }
  .vertical-line {
    display: none;
  }
}

// responsive for ipad devices
@media (min-width: 768px) and (max-width: 1023px) {
  .project-container {
    .custom-divider {
      display: none;
    }
    .vertical-line {
      display: none;
    }
    .border-right-dyn {
      border-right: none;
    }
    .desktop-data {
      display: none;
    }
    // .mobile-dropdowns{
    //   display: block !important;
    // }
    // .header-add-btns, .header-add-btn{
    //   display: block !important;
    // }
    .desk-buttons {
      display: block;
    }
    #info_button {
      display: block;
      padding: 0 9px !important;
      border-color: black !important;
      color: rgb(112, 35, 35) !important;
      // justify-content: end !important;
      margin-left: 10px;
      border-radius: 7px;
    }
    .project-detail-header {
      margin-top: 6rem;
      #project_name_row {
        display: flex;
      }
    }
  }
  // pop confirm style for ipad
  .ant-popover,
  .ant-popconfirm,
  .ant-popover-placement-bottom {
    left: 27% !important;
    top: 151px;
    transform-origin: 50% -3px !important;
    width: 52vw !important;
  }
  .ant-popover-buttons {
    display: none !important;
  }
  .ant-popover-message > .anticon {
    display: none !important;
  }
  .ant-popover-message-title {
    padding-left: 0;
  }
  .mobile_company_logo_name {
    padding-left: 3%;
  }
  //pop confirm for ipad is over
  .project-grid-view {
    // .live-status-circle {
    //   margin-top: -21% !important;
    //   margin-left: 2% !important;
    // }
    .card-chart-wrapper {
      margin-top: -32% !important;
      width: 31% !important;
      span {
        margin-top: 2% !important;
      }
      .card-chart {
        width: 126% !important;
      }
    }
  }
  .vertical-line {
    display: none;
  }
}
// ipad pro media query
@media (min-width: 1024px) and (max-width: 1365px) {
  .project-container {
    .vertical-line {
      display: none;
    }
  }
  .project-grid-view {
    // .live-status-circle {
    //   margin-top: -24% !important;
    //   margin-left: 1% !important;
    // }
    .user-count-img {
      margin-left: 1% !important;
      width: 31% !important;
    }
  }
}
// ipad pro media query
@media (min-width: 1920px) {
  .project-grid-view {
    .live-status-circle {
      // margin-top: -19% !important;
      // margin-left: 3% !important;
    }
    .user-count-img {
      width: 30% !important;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .project-container {
    .custom-divider {
      display: none;
    }
    .vertical-line {
      display: none;
    }
    .distribution-container {
      .floating-label-company,
      .floating-label-date,
      .floating-label-mode .floating-label-search {
        display: none !important;
      }
    }
    .date-picker-and-design {
      border-color: #838383;
    }
  }
  .project-detail-header {
    margin-left: 5px !important;
    .report-header-subtitle {
      justify-content: start !important;
    }
    // hide all data of desktop header
    .desktop-data,
    .desk-buttons {
      display: none;
    }
    #project_name_row {
      display: flex !important;
      justify-content: flex-start
    }
    .project-name {
      width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    #info_button {
      display: block !important;
      padding: 0 9px !important;
      border-color: black !important;
      color: rgb(112, 35, 35) !important;
      // justify-content: end !important;
      margin-left: 10px;
      border-radius: 7px;
    }
    .ant-popover,
    .ant-popconfirm,
    .ant-popover-placement-bottom {
      left: 4.5px !important;
      top: 151px !important;
      transform-origin: 50% -3px !important;
      width: 98vw !important;
    }
    .ant-popover-buttons {
      display: none !important;
    }
    .ant-popover-message > .anticon {
      display: none !important;
    }
    .mobile-more-outlined {
      display: block !important;
      float: right;
      // padding: 0px 1px !important;
      color: #fff !important;
      // background-color: #008174 ;
    }
    .more-outlined {
      display: none;
    }
    .ant-popover-inner {
      font-size: 3rem !important;
      .company-logo {
        width: 50px;
        height: 50px;
      }
      .anticon-exclamation-circle {
        display: none;
      }
    }
  }
  .floating-label-date {
    // width: 25% !important;
  }

  .display-type {
    display: block !important;
  }

  .surveu-form-container {
    width: 100%;
    margin-left: 0%;
  }

  .qheader {
    width: 40% !important;
  }
}

@media only screen and (min-width: 1365px) and (max-width: 1919px) { 
  .project-container {
    .vertical-line {
      display: none;
     }
    .user-list-container {
      .user-filter-bar {
        .floating-label-search {
          top: -25%;
          z-index: 1;
          width: 19%;
          left: 6%;
          padding-left: 1%;
        }

        .floating-label-company {
          top: -25%;
          z-index: 1;
          width: 31%;
          left: 10%;
          padding-left: 1%;
        }

        .floating-label-mode {
          top: -25%;
          z-index: 1;
          width: 20%;
          left: 9%;
          padding-left: 1%;
        }

        .floating-label-date {
          top: -25%;
          z-index: 1;
          width: 26%;
          left: 9%;
          padding-left: 1%;
        }
      }
    }
  }
}

.border-hilight {
  border: 2px solid #008174 !important;
}

.remove-hilight {
  border: none;
}
