.m-col-2{
    width:12% !important;
}
.m-col-1{
    width:16% !important;
}
.m-text-head{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
   // font: normal normal normal 14px/17px Calibri;
    letter-spacing: 0px;
    color: #001737;
    opacity: 1;
}
.m-text-status-active{
    background: #C8F1D7 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/14px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
   //font: normal normal bold 12px/14px Calibri;
    letter-spacing: 0px;
    color: #32AC5F;
    opacity: 1;
    padding: 0.2rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
.m-text-status-closed{
    background: #FFCFCF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/14px var(--unnamed-font-family-calibri);
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
   // font: normal normal bold 12px/14px Calibri;
    letter-spacing: 0px;
    color: #AC3232;
    opacity: 1;
    padding: 0.2rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
.list-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding-bottom: 8px;
    padding-top: 8px !important;
}
.wrapper-grey{
    background-color: rgba(#EDECEC,0.25);
}
.wrapper-white{
    background-color:white;
}
.status{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 8% !important;
}
.ellipse{
//   border: 1px solid black !important;
//   display: flex !important;
//   justify-content: center !important;
//   align-items: center !important;   
//   padding: 0.2rem;
//   line-height: 0.5rem;
// margin-left: 1rem;
    .dropdown-menu{
        min-width: 8rem !important;
    }
    .dropdown-item{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
        //font: normal normal normal 14px/17px Calibri;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .dropdown-menu{
        transform: translate(3px,26px) !important;
    }
    .ellipse-menu{
        padding: 0px !important;
        border: none !important;
        &:after{
            border: none !important;
        }
    }
  .ellipse-img{
    width: 1.5rem !important;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.5px solid #707070 !important;
        border-radius: 3px;
        opacity: 1;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
  }
}
