.card{
    border: 1px solid #ccc;
    // width: 395px;
    height: 175px;
    // margin-right: 15px;
    margin-bottom: 15px;  
    .cardBody{
      margin-left: 25px;
    }
    .card-text{
      margin-left:35px !important;
      max-width: 50%;
      span{
        font-size:1.4rem;
        font-family: Calibri Regular;
        font-weight: 600;
        color: black;
        line-height:100%;
        width: 80% !important;
      }  
    }

  .card-logo{
    border-radius: 50%;
    border: 1px solid rgba(0,0,0,0.3);
    padding: 4px;
  }
  .hrcircle {
    position: absolute;
    // margin-bottom: 15px;
    margin-top: -18px;
    margin-right: 18px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: #8659f2;
    color:white;
    
  }

}
.card-det-icon{
  width: 1.2rem;
}
.card-det-text{
  font-size:1rem !important;
  font-family: Calibri Regular !important;
  font-weight:400 !important;
}
.card-dot-wrapper{
  position: relative;
  .card-greendot{
    position: absolute;
    background:rgb(2, 238, 2);
    font-size: 0.4rem;
    border-radius: 50%;
    width:10px;
    margin-top: 13px;
    margin-left: 13px;
  }
  .card-greydot{
    position: absolute;
    background:#808080;
    font-size: 0.4rem;
    border-radius: 50%;
    width:10px;
    margin-top: 13px;
    margin-left: 13px;
  }
}
.card-chart-wrapper{
  display: flex;
  justify-content:center;
  align-items:center;
  width: 37%;
  height: 37%;
  position: absolute;
  background-color:white;
  // border: 1px solid black;
  border-radius: 50%;
  margin-top: 82px;
  margin-left: 75px;
  span{
    font-size:0.5rem;
    position: absolute;
    margin-top:1px;
    font-weight: 600;
  }
}
.card-chart{
  width: 120%;
  margin-bottom: 10px;
  position: absolute;
}
.card-upper-wrapper{
  display: flex;
  flex-direction:row;
  justify-content: flex-end;

}
.company-card-ellipse{
      .dropdown-menu{
          min-width: 6rem !important;
      }
      .dropdown-item{
          font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
          letter-spacing: var(--unnamed-character-spacing-0);
          color: var(--unnamed-color-000000);
          text-align: left;
          // font: normal normal normal 14px/17px Calibri;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          padding-top: 0.5rem !important;
          padding-bottom: 0.5rem !important;
      }
      .dropdown-menu{
          transform: translate(3px,26px) !important;
      }
      .ellipse-menu{
          padding: 0px !important;
          border: none !important;
          background: transparent !important;
          &:after{
              border: none !important;
          }
      }
    .card-menu{
      
      transform: rotate(90deg);
      width: 1.5rem !important;
          opacity: 1;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
    }
    .activate-dot{
      background-color: #02ee02;
      border-radius: 50%;
      height: 14px;
      width: 14px;
      margin-right: 8px;
      display: inline-block;
      margin-bottom: -3px;
    }
    .deactivate-dot{
      background-color: #808080;
      border-radius: 50%;
      height: 14px;
      width: 14px;
      margin-right: 8px;
      display: inline-block;
      margin-bottom: -3px;
    }
  }

  //for mobile device
@media only screen and (min-width: 320px) and (max-width: 767px)  { 
  .card{
    height: 152px !important;
    .card-logo{
        width: 100px !important;
        height: 100px !important;
    }
    .card-dot-wrapper .card-greendot {
      margin-top: 5px !important;
    }
    .card-chart-wrapper{
      margin-top: 62px !important;
      margin-left: 72px !important;
      span{
        font-size:0.5rem;
        position: absolute;
        // margin-top:1px;
        font-weight: 600;
      }
      .card-chart{
        width: 125% !important;
        margin-bottom: 10px !important;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px)  { 
  .card{
    width: 47.3% !important;
  }
}
@media (min-width: 1024px) and (max-width: 1365px)  { 
  .card{
    width: 48.3% !important;
  }
}
@media (min-width: 1366px)  { 
  .card{
    width: 32.3% !important;
  }
}
@media (min-width: 1920px)  { 
  .card{
    width: 24.3% !important;
  }
}