.dashborad-charts{
  .rater-stats {
    padding: 0 4em 0 0;
    width: 45%;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .rater-stats__container {
    width: 100%;
    text-align: left;
    border: 1px solid #e5e5e5;
  }
  .rater-statshead-item p {
    font-size: 10px;
  }
  .rater-dot1 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #FED68B;
  }
  
  .rater-dot2 {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #FCB737;
  }
  
  .rater-foot p {
    color: var(--unnamed-color-000000);
    text-align: left;
  //  font: normal normal bold 12px/14px Calibri;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .rater-share {
    text-align: left;
   // font: normal normal bold 14px/17px Calibri;
    color: #001737;
    opacity: 1;
  }
  
  .rater-mid-text {
    font-weight: 900;
    font-size: 2rem;
  }
  
  .rater-pie {
    position: relative;
    display: flex;
    flex-direction:row;
    justify-content: center;
    // padding-right: 2rem !important;
  }
  
  .rater-percent {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    // top: 45%;
    // left: 42% !important;
    // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 35px/40px Arial;
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: center;
    font: normal normal normal 35px/40px Calibri Regular;
    letter-spacing: 0px;
    color: #1F78B4;
    opacity: 1;
  }
  .rater-pie-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .rater-statsbody{
    // display: flex;
    // flex-direction: row !important ;
    // justify-content: center;
    width: 100%;
    // align-items: center !important;
  }
  .rater-dropdown-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    padding-left: 1.2rem;
    .ant-select{
      .ant-select-selector{
        border: 1px solid #696867;
        border-radius: 3px;
        color:black
      }
      .ant-select-selection-placeholder {
        color: black;
      }
    }
  }
  .rater-dropdown-wrapper-left{
    display: flex;
    flex-direction: row;
    // justify-content: center !important;
    // padding-right: 4.2rem;
    .projectlistDropdown, .raterlistDropdown{
      width: 25%;
    }
    .ant-select{
      .ant-select-selector{
        border: 1px solid #696867;
        border-radius: 3px;
        color:black
      }
      .ant-select-selection-placeholder {
        color: black;
      }
    }

  }
  .rater-label-text{
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-000000);
    text-align: left;
   //font: normal normal bold 14px/22px Calibri;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .dropdown-toggle::after{
    content: " " !important;
    display: none !important;
}
.rater-arrow_down{
    width: 0.6rem !important;
    margin-left: 0.7rem;
}
.rater-allproject-dropdown{
    padding: 0.3rem !important;
    padding-left: 0.8rem !important;  
    padding-right: 0.8rem !important;
  
    .rater-project-text3{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/17px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: left;
       // font: normal normal normal 14px/17px Calibri;
        letter-spacing: 0px;
        color: #001737;
        opacity: 1;
    }
}

  @media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .rater-pie-wrapper{
      .rater-dropdown-wrapper{
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }
      .rater-dropdown-wrapper-left{
        .rater-chart-dropdown, .rater-project-dropdown{
          width: 50% !important;
        }
      }
      .rater-statsbody{
        justify-content: center;
        
      }
      .chart-data{
        width: 100% !important;
        margin-left: 0% !important;
      }
    }
  }


  @media only screen and (min-width: 1024px) and (max-width: 1365px)  { 
    .rater-pie-wrapper{
      .rater-statsbody{
        .rater-pie{
          justify-content: center !important;

          // .rater-percent{
          //   top: 45% !important;
          //   left: 45% !important;
          // }
        }
      }
    }
  }

  @media only screen and (min-width: 1367px) and (max-width: 2048px)  { 
    .rater-pie-wrapper{
      .rater-statsbody{
        .rater-pie{
          justify-content: center !important;

          // .rater-percent{
          //   top: 45% !important;
          //   left: 44% !important;
          // }
        }
      }
    }
  }
}

