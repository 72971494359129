.settings-container{
    position: relative;
    margin-bottom: 1rem;
    padding: 1em 4em;
    .ant-tabs-nav{
        width: 12%;
        background: #f3f3f3;
        font-weight: 500;
    }
    .ant-tabs-tab:hover{
        background-color: #009384;
        color: #fff;
    }
    .ant-tabs-tab-active {
        background-color: #009384 !important;
        border: none !important;
        .ant-tabs-tab-btn{
            color: #fff !important;
        }
    }
    .ant-tabs-ink-bar {
        background-color: #009384 !important;
    }

    .change-password-tab{
        .form-head-label{
            font-size: 1rem;
            font-weight: 400;
        }
        .ant-input{
            border-radius: 4px;
            height: 31px;
            border-color: #008174;
        }
        .ant-input-password{
            border-radius: 4px;
            border-color: #008174;
        }
        .ant-input-affix-wrapper input{
            height: 31px;
            background-color: #fff;
            border-radius: 4px;
            padding: 0px 11px;
        }
        .changePasswordBtn{
            background-color: #008174;
            color: #fff;
            border:none;
            border-radius: 4px;
        }
    }
}


@media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .settings-container{
        position: relative;
        margin-bottom: 1rem;
        padding: 1em 1em;
        .ant-tabs-tabpane{
            padding-left: 0 !important;
        }
        .ant-tabs-nav{
            width: 100%;
        }
        .ant-tabs-content-holder{
            width: 100%;
        }
        .change-password-tab{
            margin-top: 40%;
        }
        .ant-tabs{
            flex-direction: column !important;
        }
    }

}

@media only screen and (min-width: 768px) and (max-width: 1023px)  { 
    .settings-container{
        position: relative;
        margin-bottom: 1rem;
        padding: 1em 1em;
        .ant-tabs-nav{
            width: 25%;
        }
        .ant-tabs-content-holder{
            width: 75%;
        }
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1365px)  { 
    .settings-container{
        position: relative;
        margin-bottom: 1rem;
        padding: 1em 1em;
        .ant-tabs-nav{
            width: 25%;
        }
        .ant-tabs-content-holder{
            width: 75%;
        }
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1365px)  { 
    .settings-container{
        position: relative;
        margin-bottom: 1rem;
        padding: 1em 1em;
        .ant-tabs-nav{
            width: 25%;
        }
       
    }
}