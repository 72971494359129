.company-details-wrapper{
    display: flex;
    flex-direction: row;
    
}
@media only screen and (min-width: 320px) and (max-width: 767px)  { 

    .company-details-wrapper{
        display: flex;
        flex-direction: column;
        margin-left: 0 !important;
        padding : 0.5rem 0 !important;
    }
}

// for ipad 
@media (min-width: 768px) and (max-width: 1023px)  { 
    .company-details-wrapper{
        display: flex !important;
        flex-direction: column !important;
    }
}

// for ipad pro
@media (min-width: 1024px) and (max-width: 1365px)  { 
    .company-details-wrapper{
        display: flex !important;
        flex-direction: column !important;
    }
}

