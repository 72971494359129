// .fileErr{
//     .ant-col {
//         .ant-form-item-explain{
//           .ant-form-item-explain-error{
//             margin-left: 99%;
//             width: 130px;
//           }
//         }
//     }
//   }
.company-detail-wrapper{
    display: flex;
    flex-direction: column;
    height: 100%;
    // justify-content: space-between;
    // width: 450px;
    border: 1px solid #e5e5e5;
    padding:1rem;
    padding-top: 0.5rem !important;
    margin-left: 1rem;
    .company-header{
        padding: 1rem;
        margin-top: 0;
        padding-top: 0.5rem;
        .company-desc{
            display:flex;
            flex-direction: column;
            .text1{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 15px/38px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                text-align: left;
               font: normal normal bold 15px/38px Calibri Regular;
                letter-spacing: 0px;
                color: #212529;
            }
            .text2{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 13px/14px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                text-align: left;
               font: normal normal normal 13px/14px Calibri Regular;
                letter-spacing: 0px;
                line-height:0.3rem;
                color: #212529;
            }

        }
        .edit-btn{
            border: 1px solid var(--unnamed-color-696867);
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #696867;
            border-radius: 3px;
            opacity: 1;
            height: 35px;
            .edit-img{
                width: 0.8rem;
                margin: 0.2rem;
            }
            .btn-text{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                text-align: left;
                font: bold 14px/17px Calibri Regular;
                letter-spacing: 0px;
                color: #001737;
            }
        }
    }
       
    .form-container{
      padding: 1rem;
        //    padding-top: 5px;
      margin-top: 1rem;
    }
    .add-company-row1{
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 0.5rem;
        .profile-label{
            span{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/30.81px Roboto;
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
               font: normal normal bold 13px/31px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
                text-transform: capitalize;
                opacity: 1;   
            }
        }
        .profile-desc{
            background: var(--unnamed-color-edecec) 0% 0% no-repeat padding-box;
            background: #EDECEC 0% 0% no-repeat padding-box;
            border-radius: 6px;
            padding:0.7rem;
            display:flex;
            flex-direction: row;
            justify-content: center;
            max-width: 50%;
            line-height: 1rem;
            margin-left: 2rem;
            span{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/15px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
                font: normal normal normal 14px/15px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
            }
        }
        .profile-photo{
            position: relative;
            .profile-circle{
                width: 6rem;
                height: 6rem;
                // border : 1px solid black;
                border-radius : 50%;
                padding : 0.2rem;
                  img{
                    border-radius: 50% ;
                    width: 100%;
                    height: 100%;
                  }
            }
            .input-form{
                background: white;
                border-radius: 50%;
                position: absolute;
                width: 1.8rem !important;
                height: 1.8rem !important;
                border: 1px solid #707070;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-left: 52px;
                margin-top: -25px;
                .cam-circle{
                    width: 14px;
                    
                    img{
                        // margin-top: 41%;
                        width: 100%;
                    }
                }
                .profile-input{
                    display: none;
                }
            }
        }
    }
  .form-row{
      display: flex;
      flex-direction: row;    
  }
  .form-group{
      margin-top: 0.7rem;
  }
  .form-label{
    //   font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 14px/17px var(--unnamed-font-family-calibri);
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-000000);
      text-align: left;
     font: normal normal bold 14px/17px Calibri Regular;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      margin-bottom: 0.3rem !important;
      
  }
  .form-control{
      font-family: Calibri Regular;
      font-size: 0.8rem;
      color: #000000;
      background-color: #F9F9F9;
      opacity: 1;
      height: 1.8rem;
  }
  .form-select{
      font-family: Calibri Regular;
      font-size: 1rem;
      color: #000000;
      background-color: #F9F9F9;
      opacity: 1;
  }
  .asterik{
      font-family: Calibri Regular;
      font-weight: 600;
      font-size: 0.8rem;
      color: red;  
  }
  
  .button-group{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 1.9rem;
    .cancelButton{
        border: 1px solid var(--unnamed-color-696867) !important;
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #696867 !important;
        border-radius: 4px  !important;
        opacity: 1 !important;
        &:hover{
            border: 1px solid black;
        }
        
    }
    .saveButton{
        background: var(--unnamed-color-008174) 0% 0% no-repeat padding-box !important;
        background: #008174 0% 0% no-repeat padding-box !important;
        border-radius: 4px !important;
        opacity: 1 !important;
        &:hover{
            color: #fff;
            background: #008174;
        }
    }
  }
}
.company-update-form{
    height: 88vh;
    .form-container{
          //    padding-top: 5px;
        margin-top: 1rem;
    }
    .add-company-row1{
        display: flex;
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 0.5rem;
        .profile-label{
            span{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 13px/30.81px Roboto;
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
                font: normal normal bold 13px/31px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
                text-transform: capitalize;
                opacity: 1;   
            }
        }
        .profile-desc{
            background: var(--unnamed-color-edecec) 0% 0% no-repeat padding-box;
            background: #EDECEC 0% 0% no-repeat padding-box;
            border-radius: 6px;
            padding:0.7rem;
            display:flex;
            flex-direction: row;
            justify-content: center;
            max-width: 50%;
            line-height: 1rem;
            margin-left: 2rem;
            span{
                // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 14px/15px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
               font: normal normal normal 14px/15px Calibri Regular;
                letter-spacing: 0px;
                color: #000000;
            }
        }
        .profile-photo{
            position: relative;
            .profile-circle{
                width: 6rem;
                height: 6rem;
                // border : 1px solid black;
                border-radius : 50%;
                padding : 0.2rem;
                  img{
                    border-radius: 50% ;
                    width: 100%;
                    height: 100%;
                  }
            }
            .input-form{
                background: white;
                border-radius: 50%;
                position: absolute;
                width: 1.8rem !important;
                height: 1.8rem !important;
                border: 1px solid #707070;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                // margin-left: 52px;
                // margin-top: -25px;
                bottom: 0;
                right: 0;
                .cam-circle{
                    width: 14px;
                    // margin-top: 41%;
                    
                    img{
                        width: 100%;
                    }
                }
                .profile-input{
                    display: none;
                }
            }
        }
    }
}
// for mobile
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .ant-drawer-content-wrapper{
        height: 90vh;
        .add-company-row1{
            .profile-photo{
                .input-form{
                    .cam-circle img{
                        margin-top: 52%;
                    }
                }
            }
        }
    }
    .company-detail-wrapper{
        display: flex;
        flex-direction: column;
        // margin: 0 !important;
        padding : 0.5rem 1em !important;
        margin : 0.5rem 0 !important;
        .company-header{
            padding: 0;
            .company-desc{
                .text2{
                    line-height: 1rem;
                }
            }
        }
        .form-container{
            padding : 0 !important;
        }
        .add-company-row1 .profile-desc{
            max-width: 100% !important;
        }
    }
    .company-details-wrapper{
        .pie-label-wrapper{
            width: 88% !important;
        }
    }
}

// for ipad
@media (min-width: 768px) and (max-width: 1023px)  { 
    .company-detail-wrapper{
        margin-left: 0 !important;
        margin-top: 1rem !important;
        .company-header {
            padding: 0.5rem 0 !important;
            .edit-btn{
                padding: 5px 10px !important;
            }
        }
    }
    .company-details-wrapper{
        .pie-label-wrapper{
            width: 88% !important;
        }
    }
}

// for ipad pro
@media (min-width: 1024px) and (max-width: 1365px)  { 
    .company-detail-wrapper{
        margin-left: 0 !important;
        margin-top: 1rem !important;
        .company-header {
            padding: 0.5rem 0 !important;
        }
    }

    .company-details-wrapper{
        .pie-label-wrapper{
            width: 88% !important;
        }
    }
}