// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700;900&display=swap');

@font-face {
  font-family: 'Calibri Regular';
  src: local('Calibri Regular'),
    url('assets/font/Calibri\ Regular/Calibri\ Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibri Bold';
  src: local('Calibri Bold'),
    url('assets/font/Calibri\ Bold/Calibri\ Bold.TTF') format('truetype');
}

@font-face {
  font-family: 'Calibri Italic';
  src: local('Calibri Italic'),
    url('assets/font/Calibri\ Italic/Calibri\ Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibri Bold Italic';
  src: local('Calibri Bold Italic'),
    url('assets/font/Calibri\ Bold\ Italic/Calibri\ Bold\ Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Calibri Light';
  src: local('Calibri Light'),
    url('assets/font/Calibri\ Light/Calibri\ Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Calibri Light Italic';
  src: local('Calibri Light Italic'),
    url('assets/font/Calibri\ Light\ Italic/Calibri\ Light\ Italic.ttf')
      format('truetype');
}

// style to remove blue background color from drop down item
.dropdown-item:focus {
  background-color: #008174;
  color: #fff !important;
}
.dropdown-item:active {
  background-color: #008174 !important;
  color: #fff !important;
}
.dropdown-toggle::after {
  display: none;
}
body {
  // font-family: 'Roboto', sans-serif;
  font-family: 'Calibri Regular';
  font-size: 16px;
  color: #696867;
  background-color: white;
}
#root {
  overflow-x: hidden;
}
.dropdown-menu {
  top: 45px;
  right: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.min-85vh {
  min-height: 85vh;
  margin-top: 80px;
}

.h-100vh {
  height: 100vh;
}

.ant-drawer {
  z-index: 1031;
}

.ant-modal-wrap {
  z-index: 1034;
}

.ant-picker-dropdown {
  // z-index: 1032;
  z-index: 1034;
}

.ant-notification {
  z-index: 1033;
}

// .nav-link-item{
//     text-decoration: none;
// }

.btn-primary {
  color: inherit !important;
  background-color: inherit !important;
  border-color: inherit !important;
}

th.ant-table-cell {
  &:before {
    display: none;
  }
}
//disable down arrow in head row of table
.ant-table-selection-extra {
  .ant-dropdown-trigger {
    display: none !important;
  }
}

.ant-input-affix-wrapper {
  position: relative;

  .ant-input-prefix {
    position: absolute;
    top: 50%;
    transform: translate(20px, -50%);
    left: 0;
    z-index: 1;
  }
  input {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1rem;
    height: 64px;
    width: 100%;
    background-color: #edecec;
    border-radius: 5px;
    border-color: #edecec;
    padding-left: 65px;
    border-style: solid;
  }
  &.ant-input-password {
    input {
      padding-right: 40px;
    }
    .ant-input-suffix {
      position: absolute;
      top: 50%;
      transform: translate(0px, -50%);
      right: 20px;
    }
    &.ant-input-password {
      input {
        padding-right: 40px;
      }
      .ant-input-suffix {
        position: absolute;
        top: 50%;
        transform: translate(0px, -50%);
        right: 20px;
      }
    }
  }
  .flex {
    display: flex;
  }
  .ai-center {
    align-items: center;
  }
  .jc-center {
    justify-content: center;
  }
}
// Spinner Css
.spinner-code {
  vertical-align: middle;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999999;
}
iframe {
  display: none !important;
}
.html2canvas-container {
  display: block !important;
}

// @media only screen and (min-width: 320px) and (max-width: 767px) {
// }
@media only screen and (min-width: 320px) and (max-width: 1450px) {
  // .ant-drawer-content-wrapper {
  //   height: 90vh !important;
  // }
  .filebutton {
    right: 0;
    bottom: 0;
  }
  .ant-table {
    height: 100%;
    overflow-x: scroll;
  }
}

#toolbar-container .ql-font span[data-label='Sans Serif']::before {
  font-family: 'Sans Serif';
}

#toolbar-container .ql-font span[data-label='Inconsolata']::before {
  font-family: 'Inconsolata';
}

#toolbar-container .ql-font span[data-label='Roboto']::before {
  font-family: 'Roboto';
}

#toolbar-container .ql-font span[data-label='Mirza']::before {
  font-family: 'Mirza';
}

#toolbar-container .ql-font span[data-label='Arial']::before {
  font-family: 'Arial';
}
/* Set content font-families */

.ql-font-inconsolata {
  font-family: 'Inconsolata';
}

.ql-font-roboto {
  font-family: 'Roboto';
}

.ql-font-mirza {
  font-family: 'Mirza';
}

.ql-font-arial {
  font-family: 'Arial';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: 'Sans Serif';
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  max-height: 150px;
  overflow: auto;
}

.ant-spin-blur {
  opacity: 0 !important;
  background-color: rgb(191, 242, 255);
}

.ant-spin-dot-item {
  background-color: #008174 !important;
  transform: scale(1) !important;
}

.ant-spin-text {
  font-size: 1.4rem;
  color: rgb(0, 129, 116);
  margin-top: 1rem;
  font-family: 'Calibri Regular';
}
// .ant-spin-lg .ant-spin-dot i{
//   width: 25px;
//   height: 25px;
// }

.generate-pdf-content-wrapper .ant-modal-mask {
  background-color: red !important;
}
.company-logo {
  object-fit: contain;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50px;
}
