.analytics-wrapper{
    width:94%;
    display: flex;
    flex-direction: row;
    justify-content:center;
    margin: 0 auto !important;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
    .outer1{
        box-shadow: rgba(75, 58, 58, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        display: flex;
        flex-direction: row;
        height: 9rem;
        border-radius: 3px;
        margin-right: 20px;
        // width: 23%;
        width: 100%;
        .inner1{
            display: flex;
            flex-direction: column;
            align-items:flex-end;
            justify-content:center;
            padding-right: 30px;
            .text1{
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-008174);
                text-align: left;
                //  font: normal normal bold 17px/29px Calibri;
                letter-spacing: 0px;
                color: #008174;
                opacity: 1;            
            }
            .in1{
                display: flex;
                flex-direction: row;
                padding-top: 5px;
                // justify-content: end;
                .text2{
                    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-18)/18px var(--unnamed-font-family-calibri);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    text-align: left;
                    // font: normal normal bold 18px/18px Calibri;
                    letter-spacing: 0px;
                    color: #73CB1C;
                }
                .text3{
                    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/18px var(--unnamed-font-family-calibri);
                    letter-spacing: var(--unnamed-character-spacing-0);
                    text-align: left;
                    // font: normal normal normal 18px/18px Calibri;
                    letter-spacing: 0px;
                    color: #495463;

                }
            }
        }
        .inner2{
            display: flex;
            flex-direction:row;
            align-items:center;
            .text4{
                font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 46px/56px var(--unnamed-font-family-calibri);
                letter-spacing: var(--unnamed-character-spacing-0);
                color: var(--unnamed-color-000000);
                text-align: left;
               // font: normal normal bold 46px/56px Calibri;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
            }
        }
        
    }
    .outer2{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 9rem;
        .inner_left{
            display: flex;
            justify-content: center;
            width: 30%;
            .inner3{
                width: 100%;
                padding-left: 22%;
            }
        }
        .inner_right{
            display: flex;
            width: 70%;
            justify-content: space-around;
        }
    }
    .outer3{
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        // margin-left: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 24%;

        .inner4{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 30px;
            
        }
        .inner_right{
            width: 55%;
            .bso{
                border-right: none;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            .text9, .text10, .text11 , .text12{
                text-align: center;
            }
        }
    }
}
.inner3{
    display: flex;
    flex-direction: column;
    // align-items:start;
    // justify-content:center;
    padding-right: 10px;
    padding-left: 30px;
    align-items:center;
    justify-content:center;
    width: 100%;
    // border-right: 1px solid rgba(0, 0, 0, 0.1);
    .text5{
        // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 46px/56px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-000000);
        text-align: left;
        font: normal normal bold 46px/56px Calibri Regular;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }
    .text6{
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-008174);
        text-align: left;
        //  font: normal normal bold 17px/29px Calibri;
        letter-spacing: 0px;
        color: #008174;
    }
    .in2{
        display: flex;
        flex-direction: column;
        padding-top: 2px;
        // justify-content: end;
        .text7{
            letter-spacing: var(--unnamed-character-spacing-0);
            text-align: left;
            // font: normal normal bold 18px/18px Calibri;
            letter-spacing: 0px;
            color: #73CB1C;
        }
        .text8{
            letter-spacing: var(--unnamed-character-spacing-0);
            text-align: left;
            //font: normal normal normal 18px/18px Calibri;
            letter-spacing: 0px;
            color: #495463;

        }
    }
}
.in3{
    display: flex;
    flex-direction:column;
    .text9{
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-008174);
        text-align: left;
        //font: normal normal bold 20px/22px Calibri;
        letter-spacing: 0px;
        color: #008174;
        opacity: 1;
    }
    .text10{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/24px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-696867);
        text-align: left;
        //font: normal normal bold 12px/24px Calibri;
        letter-spacing: 0px;
        color: #696867;
        opacity: 1;
    }

}
.in4{
    display: flex;
    flex-direction:column;
    // padding-top: 10px;
    .text11{
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-008174);
        text-align: left;
        //font: normal normal bold 20px/22px Calibri;
        letter-spacing: 0px;
        opacity: 1;
        color: #001737;
    }
    .text12{
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 12px/24px var(--unnamed-font-family-calibri);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-696867);
        text-align: left;
        //font: normal normal bold 12px/24px Calibri;
        letter-spacing: 0px;
        color: #696867;
        opacity: 1;
    }
}
.mfo{
    margin-left: 21px;
    padding: 2px;
}
.ufo{
    padding-top: 0px !important;
    margin-left: 15px;
    padding-left: 10px;
}
.bso{
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.small_counter{
    flex-direction: row;
}
@media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .analytics-wrapper{
        flex-direction: column;
        .outer1{
            width: 100%;
            justify-content: center;
            height: 7rem;
        }

        .outer2{
            flex-direction: column;
            justify-content: center;
            margin-right: 0;
            margin-top: 1rem;
            // margin-bottom: 1rem;
            // height: 11rem;
            height: auto;
             .inner_left{
                 width: 100%;
                 padding-right: 3px;
                .inner3{
                    border-right: 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-left: 0 ;
                    padding-right: 0 ;
                    padding-top: 3%;
                    padding-bottom: 6%;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: row-reverse;
                     .in2{
                         flex-direction: column;
                         display: flex;
                         width:43%;
                         .joinText{
                             padding-top: 3%;
                             display: flex;
                         }
                     }
                }   
            }
            .inner_right{
                display: flex;
                width: 100%;
                padding-top: 17px;
                padding-bottom: 17px;
                .mfo{
                    margin-left: 0 !important;
                }
                .bso{
                    padding-right: 33px;
                }
                .text9, .text10, .text11 , .text12{
                    text-align: center;
                }
                // .ufo{
                //     padding-left: 18px;
                // }
            }
        }
        .outer3{
            flex-direction: column;
            justify-content: center;
            margin-right: 0;
            margin-top: 1rem;
            width: 100%;
            // margin-bottom: 1rem;
             .inner_left{
                 width: 100%;
                 padding-right: 3px;
                .inner3{
                    border-right: 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                    padding-left: 0 ;
                    padding-right: 0 ;
                    padding-top: 3%;
                    padding-bottom: 6%;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    flex-direction: row-reverse;
                     .in2{
                         flex-direction: column;
                         display: flex;
                         width: 38%;
                         .joinText{
                             padding-top: 3%;
                             display: flex;
                         }
                     }
                }   
            }
            .inner_right{
                display: flex;
                flex-direction: row;
                width: 100%;
                padding-top: 17px;
                padding-bottom: 24px;
                justify-content: space-around;
                .in3, .in4{
                    flex-basis: 50%;
                }
                .text9, .text10, .text11 , .text12{
                    text-align: center;
                }
                .bso{
                    border-bottom: none;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                }
                // .ufo, .mfo{
                //     padding: 10px;
                // } 
            }
        }
    }

    .small_counter{
        justify-content: space-between;
        width: 95%;
        .in3{
            margin: 0 !important;
            align-items: center;
        }
        .in4{
            margin: 0 !important;
            align-items: center;
        }
    }

    .dashBoardCounter{
        flex-direction: column;
        .outer1{
            margin-bottom: 20px;
        }
    }
}