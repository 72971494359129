.dashboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .dashboard-header-section-1 {
    display: flex;
    flex-direction: column;
  }
  .dashboard-header-section-2 {
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    select {
      width: 150px;
      margin: 0px 5px;
      height: 34px;
      color: #fff;
      background-color: #008174;
    }
  }
}

.dashboard-cards {
  max-width: 100%;
  .card-1 {
    top: 50px;
    left: 50px;
    width: 300px;
    height: 150px;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EDECEC;
    border-radius: 4px;
    opacity: 1;
  }
}

.dashboard-chart {
  max-width: 500px;
  height: 200px;
  position: relative;
}
.middle-dash-wrapper{
  display: flex;
  flex-direction: row; 
  width:94%;
  margin: 0 auto !important;
}


  //for mobile device
  @media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .middle-dash-wrapper{
      flex-direction: column;
      .project{
        padding: 0;
      }
      .list-middle-wrapper{
        overflow-x: scroll;
      }
    }
    .stats{
      .stats__container{
        margin-top: 1rem;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px)  { 
    .State_part{
      margin-top: 1rem;
    }

  }

  @media only screen and (min-width: 1300px) and (max-width: 1366px)  { 
    .dashborad-charts .emp-pie-wrapper .emp-percent{
      left: 48%;
    }

  }