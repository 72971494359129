.btn-theme {
  background-color: #008174;
  color: #fff;
  box-shadow: none;

  &:hover{
    background-color: #008c7e;
    color: #EDECEC;
    font-weight: 400;
  }
  &:focus{
    background-color: #008c7e;
  }
  &:active{
    background-color: #008c7e;
  }
}
