.dashborad-charts{
  .emp-pie-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    .emp-stats {
      padding: 0 4em 0 0;
      width: 45%;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
    }
    
    .emp-stats__container {
      width: 100%;
      text-align: left;
      border: 1px solid #e5e5e5;
    }
    .emp-statshead-item p {
      font-size: 10px;
    }
    .emp-dot1 {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: #fedd84;
    }
    
    .emp-dot2 {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: #febe13;
    }
    
    .emp-foot p {
      color: var(--unnamed-color-000000);
      text-align: left;
     // font: normal normal bold 12px/14px Calibri;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .emp-share {
      text-align: left;
      //font: normal normal bold 14px/17px Calibri;
      color: #001737;
      opacity: 1;
    }
    
    .emp-mid-text {
      font-weight: 900;
      font-size: 2rem;
    }
    
    .emp-pie {
      position: relative;
      display: flex;
      flex-direction:row;
      justify-content: center;
      // margin-right: 2.5rem !important;
    }
    
    .emp-percent {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      bottom: 0;
      max-width: 100%;
      max-height: 100%;
      overflow: auto;
      // top: 45%;
      // left: 62%;
      // font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 35px/40px Arial;
      letter-spacing: var(--unnamed-character-spacing-0);
      text-align: center;
      font: normal normal normal 35px/40px Calibri Regular;
      letter-spacing: 0px;
      color: #1F78B4;
      opacity: 1;
    }
    .emp-dropdown-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: flex-end !important;
      margin-right: 1.5rem;
      .ant-select-selector{
        border: 1px solid #696867;
        border-radius: 3px;
        color:black
      }
      .ant-select-selection-placeholder {
        color: black;
      }
      .dropdown-toggle::after{
        content: " " !important;
        display: none !important;
      }
      
    }
    .emp-label-text{
      letter-spacing: var(--unnamed-character-spacing-0);
      color: var(--unnamed-color-000000);
      text-align: left;
      //font: normal normal bold 14px/22px Calibri;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
  
  }

  .dropdown-basic{
    height: 40px;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px)  { 
    .dashborad-charts{
  
      .emp-pie-wrapper{
        .emp-dropdown-wrapper{
          margin-right: 0% !important;
          .emp-project-dropdown{
            width: 50% !important;
          }
        }
        .ms-5{
          margin-left: 0 !important;
        }
        .w-75{
          width: 100% !important;
        }
        .emp-statsbody{
          flex-direction: column-reverse !important;
          .emp-pie{
            margin-right: 0 !important;
            justify-content: center;
          }
        }
        // .emp-percent{
        //   left: 43% !important;
        // }
      }
    }
   
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1023px)  { 
    .emp-pie-wrapper{
      .emp-statsbody{
        flex-direction: column-reverse !important;
  
        .emp-pie{
          justify-content: center !important;
          margin-right: 0;
  
          // .emp-percent{
          //   left: 46% !important;
          // }
        }
      }
    }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1365px)  { 
    .emp-pie-wrapper{
      .emp-statsbody{
        flex-direction: column-reverse !important;
  
        .emp-pie{
          justify-content: center !important;
          margin-right: 0;
  
          // .emp-percent{
          //   left: 47% !important;
          // }
        }
      }
    }
  }
  @media only screen and (min-width: 1440px)  { 
    .emp-pie-wrapper{
      .emp-statsbody{
        flex-direction: column-reverse !important;
  
        .emp-pie{
          justify-content: center !important;
          margin-right: 0;
  
          // .emp-percent{
          //   left: 46%;
          // }
        }
      }
    }
  }
}

  
