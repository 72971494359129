.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px';
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px';
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14px';
  font-size: 14px !important;
}

.footer {
  padding: 0 4em;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  /* font: normal normal normal 14px/16px Calibri; */
  color: #838383;
  /* margin-top: 2.8rem; */
  /* position: absolute;
  bottom: 0; */
}

.footer__container {
  width: 100%;
  text-align: left;
  border-top: 1px solid #e5e5e5;
}

.footer-copy {
  flex-basis: 45%;
}
.footer-menus {
  flex-basis: 30%;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    text-align: center !important;
    justify-content: center !important;
    padding: 0 !important;
    font-size: 12px;
  }
  .footer-copy {
    justify-content: center !important;
    flex-basis: 100%;
  }
  .footer-menus {
    justify-content: space-between !important;
    flex-basis: 100%;
    margin-left: 10%;
    margin-right: 10%;
  }
  p .me-5 {
    margin-right: 0 !important;
  }
  .footer__container {
    text-align: center;
    margin-bottom: 1%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .footer {
    padding: 0 2em;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1365px) {
  .footer {
    padding: 0 2em;
  }
  .footer-menus {
    flex-basis: 35%;
  }
}
